import React from 'react';
import { Card, Upload, Icon } from 'antd';

const Dragger = Upload.Dragger;

const CourseContentUpload = (props) =>{
  
  const uploadProps = {
    accept: '.pdf',
    name: 'file',
    multiple: false,
    action: '//jsonplaceholder.typicode.com/posts/',
    fileList: props.fileList,
    beforeUpload: (file, fileList) => false,
    onChange : ({file, fileList}) =>{
      if(fileList.length===0){
        props.onChange([]);
      }else{
        props.onChange([file]);
      }     
    }
  };
  return (
    <Dragger {...uploadProps}>
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">The file uploaded here contains the course content that users will interact with prior to taking the quiz. 
                                      Upload PDF of a Powerpoint presentation you have created or purchased from the epar Store. Accepted file format is PDF</p>
    </Dragger>
  );
};

export default CourseContentUpload;