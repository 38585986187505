import React, { useState } from 'react';
import { Modal, Checkbox } from 'antd';

import { CONNECT_FEATURES } from '../constants';
import { setOrgFeatures } from '../actions/orgActions';
import { COMMON_DOC_TYPES } from '../../external-certification/Constants';


export function AddFeatureModal(props) {
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);

    const enabledFeatures = props.orgFeatures ? props.orgFeatures : [];
    const disabledFeatures = Object.keys(CONNECT_FEATURES).filter(key => !enabledFeatures.includes(key));
    disabledFeatures.sort();

    const handleOk = async () => {
        setSavingChanges(true);
        let isAudubon = false;
        if(selectedFeatures.indexOf(COMMON_DOC_TYPES.AUDUBON) > -1) {
            isAudubon = true;
        }
        let featuresToAdd = selectedFeatures.filter(feature=>getMissingDependencies(CONNECT_FEATURES[feature].dependencies).length===0);
        await setOrgFeatures(props.orgId, [...enabledFeatures, ...featuresToAdd], featuresToAdd);
        props.onSave(isAudubon);

    }

    const onCheckAllChange = e => {
        const checked = e.target.checked;
        setSelectedFeatures(checked ? disabledFeatures : []);
        setCheckAll(checked);
        setIndeterminate(false);
    };

    const getMissingDependencies = (dependencies) => {
        let missing = [];
        if (dependencies) {
            missing = dependencies.filter(item => (!selectedFeatures.includes(item) && !enabledFeatures.includes(item)));
        }
        return missing;
    }

    return (
        <Modal
            title={"Add New Feature"}
            visible={props.visible}
            okText={"Save"}
            cancelText={"Cancel"}
            onCancel={props.onCancel}
            onOk={handleOk}
            destroyOnClose={true}
            okButtonProps={{ disabled: savingChanges }}
            cancelButtonProps={{ disabled: savingChanges }}
        >

            <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                >
                    Check all
                    </Checkbox>
            </div>
            <br />
            {disabledFeatures.map(key => {
                const missingDependencies = getMissingDependencies(CONNECT_FEATURES[key].dependencies);
                return (
                    <div>
                        <Checkbox
                            onChange={(e) => {
                                const checked = e.target.checked;
                                if (checked) {
                                    selectedFeatures.push(key);
                                } else {
                                    var index = selectedFeatures.indexOf(key);
                                    if (index > -1) {
                                        selectedFeatures.splice(index, 1);
                                    }
                                }
                                setSelectedFeatures([...selectedFeatures]);
                                setIndeterminate(selectedFeatures.length !== 0 && selectedFeatures.length !== disabledFeatures.length)
                            }}
                            checked={selectedFeatures.includes(key) && missingDependencies.length===0}
                            disabled={missingDependencies.length!==0}
                        >
                            {CONNECT_FEATURES[key].displayName}
                            {missingDependencies.length!==0 ? `  (${missingDependencies.map(item=>`${CONNECT_FEATURES[item].displayName} `)} should be enabled)`:''}                        </Checkbox>
                        <br />
                    </div>
                );
            }
            )}

        </Modal>
    );
};