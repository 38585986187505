import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Modal } from 'antd';
import { withRouter, Redirect } from 'react-router-dom';

import { loadInspectionList, createNewTemplate, archiveTemplate } from '../actions/inspectionActions';
import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { InspectionListItem } from '../InspectionListItemBuilder';
import { setBreadcrumb } from '../../../actions/commonActions';
import InspectionTemplateForm from './InspectionTemplateForm';

const confirm = Modal.confirm;

class InspectionListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            addInspectionVisible: false,
            selectedTemplates: new Set(),
            searchSelectedTemplate: null
        };
        this.loadInspectionList();

        this.props.setBreadcrumb("Inspections", [
            { route: '', label: 'Inspections' }
        ]);
    }

    loadInspectionList = async () => {
        this.props.loadInspectionList();
    }

    getInspectionsForListView = () => {
        let inspectionList = this.filterInspections().map((inspection) => {
            let builder = new InspectionListItem.Builder(inspection, this.onTemplateSelection, this.state.selectedTemplates);
            return builder.build();
        });
        return inspectionList;
    }

    filterInspections = () => {
        return SearchUtils.filter(this.props.inspectionList ? this.props.inspectionList : [], "templateid", this.state.searchMatches);
    }

    filterSearchOptions() {
        const inspections = this.filterInspections();
        const optionsMap = { title: "Templates", children: [] };
        inspections.forEach((inspection) => {
            optionsMap.children.push({ title: inspection.title, id: inspection.templateid });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.inspectionIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (templateId) => {
        this.setState({ searchSelectedTemplate: templateId });
    }

    onSearchTemplateSelect = () => {
        if (this.state.searchSelectedTemplate) {
            return (<Redirect to={`/inspections/template/${this.state.searchSelectedTemplate}`} />);
        }
    }

    onTemplateSelection = (templateId, checked) => {
        const selectedTemplates = this.state.selectedTemplates
        checked ? selectedTemplates.add(templateId) : selectedTemplates.delete(templateId);
        this.setState({ selectedTemplates });
    }

    archiveTemplates = async () => {
        message.loading('Archiving templates', 0);
        const templateItr = this.state.selectedTemplates.values();
        for (let templateId of templateItr) {
            console.log(templateId); 
            await archiveTemplate(templateId);
        }
        message.destroy();
        message.success('Templates archived successfully');
        this.setState({ selectedTemplates: new Set() });
        this.loadInspectionList();
    }

    onArchive = () => {
        confirm({
            title: 'Do you want to archive selected templates?',
            onOk: () => {
                this.archiveTemplates();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    render() {
        return (
            <div>
                {this.onSearchTemplateSelect()}
                {this.state.addInspectionVisible &&
                    <InspectionTemplateForm
                        visible={this.state.addInspectionVisible}
                        mode="create"
                        callback={async (data) => {
                            this.setState({ addInspectionVisible: false });
                            if (data) {
                                message.loading('Creating template...', 0);
                                await createNewTemplate(data, this.props.userId);
                                message.destroy();
                                message.success('Template created successfully');
                                this.loadInspectionList();
                            }
                        }}
                    />
                }
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <Button
                            className='epar__tasks__action--button'
                            onClick={() => this.setState({ addInspectionVisible: true })}>
                            Add New Template
                        </Button>
                        <Button
                            className='epar__tasks__action--button'
                            onClick={this.onArchive}
                            disabled={this.state.selectedTemplates.size === 0}>
                            Archive
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getInspectionsForListView()} loading={this.props.inspectionList === undefined} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        inspectionList: state.getIn(['inspectionState', 'inspectionList']),
        inspectionIndex: state.getIn(["inspectionState", "inspectionIndex"]), 
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadInspectionList: bindActionCreators(loadInspectionList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionListView));
