import { getApi, ConnectAdminApi } from "../../../utils/api-utils";
import { DASHBOARD_ACTIONS } from "./actionTypes";

export function loadDashboardData(force = false) {
    return async function (dispatch, getState) {
        try {
            if (force || !getState().getIn(['dashboardState', 'orgUserStatusCounts'])) {
                const result = await getApi(ConnectAdminApi.USER_API, '/all_org_user_status');
                let userCountMap = new Map();
                result.activeUsers.map(row=>{
                    const orgId = row.org_id;
                    if(!userCountMap.has(orgId)){
                        userCountMap.set(orgId, {active:0, inactive:0});
                    }
                    userCountMap.get(orgId).active=row.active_users;
                    return null;
                })
                result.inactiveUsers.map(row=>{
                    const orgId = row.org_id;
                    if(!userCountMap.has(orgId)){
                        userCountMap.set(orgId, {active:0, inactive:0});
                    }
                    userCountMap.get(orgId).inactive=row.inactive_users;
                    return null;                  
                })
                await dispatch({
                    type: DASHBOARD_ACTIONS.ORG_USER_STATUS_COUNTS,
                    payload: userCountMap
                })
            }
        } catch (e) {
            console.log(e);
        }
    }
}