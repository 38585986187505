import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Checkbox } from 'antd';

export class EquipmentListItem {
    static get Builder() {
        class Builder {
            constructor(equipment, onEquipmentSelection, selectedEquipments) {
                this.equipment = equipment;
                this.selectedEquipments = selectedEquipments;
                this.onEquipmentSelection = onEquipmentSelection;
            }
            build() {
                const equipment = this.equipment;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(equipment.name, `/equipment/equipment_info/${equipment.equipment_id}`)
                    .setDescription(
                        <div>
                            <Row>{`Manufacturer: ${equipment.manufacturer_name}`}</Row>
                            <Row>{`Country: ${equipment.country_code === 'AUS' ? 'Australia' : equipment.country_code === 'USA' ? 'United States' : equipment.country_code}`}</Row>
                            <Row>{`Model Number: ${equipment.model_no}`}</Row>
                            <Row>{`Serial Range: ${equipment.serial_no}`}</Row>
                        </div>
                    )
                    .setLeftIcon(
                        <Checkbox
                            onChange={e => this.onEquipmentSelection(equipment.equipment_id, e.target.checked)}
                            checked={this.selectedEquipments.has(equipment.equipment_id)}
                        />);
                return builder.build();
            }
        }
        return Builder;
    }
}
