import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, Upload, Icon, Table, Card, Alert, message, Tabs, List } from 'antd';
import { withRouter } from 'react-router-dom';
import Papa from 'papaparse';

import { setBreadcrumb } from '../../../actions/commonActions';
import { getChemicalFileList, addNewChemicals } from '../actions/chemicalActions';
import moment from 'moment';

const TabPane = Tabs.TabPane;

const COLUMNS = {
    COUNTRY: 0,
    PRODUCT_NAME: 1,
    MANUFACTURER: 2,
    ACTIVE_INGREDIENT: 3,
    ACTIVE_GROUP: 4,
    FORMULATION: 5,
    FILENAME_EN: 6,
    FILENAME_EN_AU: 7,
    FILENAME_ES: 8,
    EXPIRE_DATE_EN: 9,
    EXPIRE_DATE_EN_AU: 10,
    EXPIRE_DATE_ES: 11,
}

class ChemicalBulkUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chemicals: [],
            chemicalFileList: [],
            errors: [],
            uploading: false
        };
        this.props.setBreadcrumb("Chemical Bulk Upload", [
            { route: '/chemicals', label: 'Chemicals' },
            { route: '', label: 'Chemical Bulk Upload' }
        ]);
    }

    componentDidMount = async () => {
        const chemicalFileList = await getChemicalFileList();
        this.setState({ chemicalFileList });
    }

    onSubmit = async () => {
        this.setState({ uploading: true });
        message.loading('Adding chemicals, please wait...', 0);
        const done = await addNewChemicals(this.state.chemicals);
        message.destroy();
        message.success(`Successfully added ${done} chemicals`);
        this.setState({ chemicals: [], errors: [], uploading: false });
    }


    chemicalsView = () => {
        const columns = [
            { title: 'Country', dataIndex: 'country', key: 'country' },
            { title: 'Product Identifier', dataIndex: 'productName', key: 'productName' },
            { title: 'Manufacturer', dataIndex: 'manufacturer', key: 'manufacturer' },
            { title: 'Active Ingredient', dataIndex: 'activeIngredient', key: 'activeIngredient' },
            { title: 'Active Group', dataIndex: 'activeGroup', key: 'activeGroup' },
            { title: 'Formulation', dataIndex: 'formulation', key: 'formulation' },
            { title: 'Locale', dataIndex: 'locale', key: 'locale' },
        ];
        const uploadProps = {
            accept: '.csv',
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            fileList: [],
            beforeUpload: (file, fileList) => false,
            onChange: async ({ file, fileList }) => {
                await this.setState({ errors: [] });
                if (fileList.length === 1) {
                    Papa.parse(file, {
                        skipEmptyLines: 'greedy',
                        complete: (results) => {
                            const data = results.data.slice(1);
                            let errors = [];
                            const chemicals = data.map((row, index) => {
                                let chemical = {
                                    country: row[COLUMNS.COUNTRY],
                                    productName: row[COLUMNS.PRODUCT_NAME],
                                    manufacturer: row[COLUMNS.MANUFACTURER],
                                    activeIngredient: row[COLUMNS.ACTIVE_INGREDIENT],
                                    activeGroup: row[COLUMNS.ACTIVE_GROUP],
                                    formulation: row[COLUMNS.FORMULATION],
                                };
                                let locale = [];
                                if (chemical.country !== 'USA' && chemical.country !== 'AUS') {
                                    errors.push(`Invalid country code in "${chemical.productName}", row "${index + 2}"`);
                                }
                                if (!chemical.productName) {
                                    errors.push(`Product name is not defined in row "${index + 1}"`);
                                }
                                if (!chemical.manufacturer) {
                                    errors.push(`Manufacturer name is not defined in "${chemical.productName}", row "${index + 2}"`);
                                }
                                const enFileName = row[COLUMNS.FILENAME_EN];
                                const enAuFileName = row[COLUMNS.FILENAME_EN_AU];
                                const esFileName = row[COLUMNS.FILENAME_ES];
                                if (enFileName) {
                                    if (this.state.chemicalFileList.has(enFileName)) {
                                        const enExpiryDate = row[COLUMNS.EXPIRE_DATE_EN];
                                        let err = false;
                                        if(!enExpiryDate){
                                            errors.push(`EN SDS expiry date not found for "${chemical.productName}", row "${index + 2}"`);
                                            err= true;
                                        }else if(!moment(enExpiryDate, 'YYYY-MM-DD', true).isValid()){
                                            errors.push(`EN SDS expiry date is not valid for "${chemical.productName}", row "${index + 2}"`);
                                            err= true;
                                        }
                                        if(!err){
                                            locale.push('EN');
                                            chemical.enFileName = enFileName;
                                            chemical.enFileExpiryDate = enExpiryDate;
                                        }
                                    } else {
                                        errors.push(`EN file not found in S3 for "${chemical.productName}", row "${index + 2}", file:${enFileName}`);
                                    }
                                }
                                if (enAuFileName) {
                                    if (this.state.chemicalFileList.has(enAuFileName)) {                                        
                                        const enAuExpiryDate = row[COLUMNS.EXPIRE_DATE_EN_AU];
                                        let err = false;
                                        if(!enAuExpiryDate){
                                            errors.push(`EN_AU SDS expiry date not found for "${chemical.productName}", row "${index + 2}"`);
                                            err= true;
                                        }else if(!moment(enAuExpiryDate, 'YYYY-MM-DD', true).isValid()){
                                            errors.push(`EN_AU SDS expiry date is not valid for "${chemical.productName}", row "${index + 2}"`);
                                            err= true;
                                        }
                                        if(!err){
                                            locale.push('EN_AU');
                                            chemical.enAuFileName = enAuFileName;
                                            chemical.enAuFileExpiryDate = enAuExpiryDate;
                                        }
                                    } else {
                                        errors.push(`EN_AU file not found in S3 for "${chemical.productName}", row "${index + 2}", file:${enAuFileName}`);
                                    }
                                }
                                if (esFileName) {
                                    if (this.state.chemicalFileList.has(esFileName)) {
                                        const esExpiryDate = row[COLUMNS.EXPIRE_DATE_ES];
                                        let err = false;
                                        if(!esExpiryDate){
                                            errors.push(`ES SDS expiry date not found for "${chemical.productName}", row "${index + 2}"`);
                                            err= true;
                                        }else if(!moment(esExpiryDate, 'YYYY-MM-DD', true).isValid()){
                                            errors.push(`ES SDS expiry date is not valid for "${chemical.productName}", row "${index + 2}"`);
                                            err= true;
                                        }
                                        if(!err){
                                            locale.push('ES');
                                            chemical.esFileName = esFileName;
                                            chemical.esFileExpiryDate = esExpiryDate;
                                        }
                                    } else {
                                        errors.push(`ES file not found in S3 for "${chemical.productName}", row "${index + 2}", file:${esFileName}`);
                                    }
                                }
                                chemical.locale = locale.join(',');
                                return chemical;

                            });
                            this.setState({ chemicals, errors });
                            console.log("All done!");
                        }
                    });

                }
            }
        };
        return (
            <div>
                <div className="pure-u-1">
                    <div className="pure-g" style={{ float: 'right' }}>
                        <Upload {...uploadProps}>
                            <Button disabled={this.state.uploading} className='epar__tasks__action--button'>
                                <Icon type="upload" /> Upload CSV
                        </Button>
                        </Upload>
                        <Button
                            className='epar__tasks__action--button'
                            onClick={this.onSubmit}
                            disabled={this.state.uploading || this.state.chemicals.length === 0 || this.state.errors.length !== 0}
                        > Save All
                    </Button>
                        <Button className='epar__tasks__action--button'>
                            <a href='/assets/chemicals.csv'>Download CSV Template</a>
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        {this.state.errors.map(err =>
                            <Alert
                                message={err}
                                type="error"
                                closable
                            />
                        )}
                        <Card>
                            <Table columns={columns} dataSource={this.state.chemicals} />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }

    s3View = () => {
        // const uploadProps = {
        //     accept: '.pdf',
        //     name: 'file',
        //     multiple: true,
        //     action: '//jsonplaceholder.typicode.com/posts/',
        //     fileList: [],
        //     beforeUpload: (file, fileList) => false,
        //     onChange: async ({ file, fileList, event }) => {
        //         console.log('fileList:', fileList);
        //         console.log('file:', file);
        //         console.log('event:', event);
        //     }
        // }
        return (
            <div>
                <div className="pure-u-1">
                    {/* <div className="pure-g" style={{ float: 'right' }}>
                        <Upload {...uploadProps}>
                            <Button className='epar__tasks__action--button'>
                                <Icon type="upload" /> Upload SDS Files
                            </Button>
                        </Upload>
                    </div> */}
                </div>
                    <Card>
                        <List
                            dataSource={Array.from(this.state.chemicalFileList)}
                            renderItem={item => (<List.Item>{item}</List.Item>)}
                        />
                    </Card>
            </div >
        );
    }

    render() {

        return (
            <Card>
                <Tabs className='epar__details__view'>
                    <TabPane tab="Chemicals" key="0">
                        {this.chemicalsView()}
                    </TabPane>
                    <TabPane tab="S3 Files" key="1">
                        {this.s3View()}
                    </TabPane>
                </Tabs>
            </Card>
        );
    }
}


function mapStateToProps(state, props) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemicalBulkUpload));
