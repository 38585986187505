import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Modal, Input, message, Select, Switch } from 'antd';
import { EQUIPMENT_QR } from '../constants';

import { addNewEquipment } from '../actions/equipmentActions';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

class NewEquipmentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false
        }
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            await this.setState({ savingChanges: true });
            message.loading('Adding new equipment...', 0);
            const equipmentInfo = {
                productName: values.productName,
                manufacturer: values.manufacturer,
                modelNo: values.modelNo,
                serialRange: values.serialRange,
                competencyTimeFrame: values.competencyTimeFrame,
                country: values.country,
                qrStatus: values.qrStatus ? EQUIPMENT_QR.ENABLED : EQUIPMENT_QR.DISABLED
            }

            const result = await addNewEquipment(equipmentInfo);
            message.destroy();
            if(result){
                const equipmentId = result.equipmentId;
                message.success('Equipment added successfully');
                form.resetFields();
                this.props.onSave(equipmentId);
            }else{                
                message.error("Error in adding new equipment");
                await this.setState({ savingChanges: false });
            }
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title={"Add New Equipment"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Country"}>
                        {
                            getFieldDecorator('country', { rules: [{ required: true, message: 'Please select a country' }] })(
                                <Select>
                                    <Option value="AUS">Australia</Option>
                                    <Option value="USA">United States</Option>
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Model"}>
                        {
                            getFieldDecorator('productName', { rules: [{ required: true, message: 'Please enter model' }] })(
                                <Input maxLength={100} placeholder={"Enter model"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Manufacturer"}>
                        {
                            getFieldDecorator('manufacturer', { rules: [{ required: true, message: 'Please enter manufacturer' }] })(
                                <Input maxLength={100} placeholder={"Enter manufacturer"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Model Number"}>
                        {
                            getFieldDecorator('modelNo', { rules: [{ required: true, message: 'Please enter model number' }] })(
                                <Input maxLength={100} placeholder={"Enter model number"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Serial Range"}>
                        {
                            getFieldDecorator('serialRange')(
                                <Input maxLength={100} placeholder={"Enter serial range"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Competency Timeframe"}>
                        {
                            getFieldDecorator('competencyTimeFrame')(
                                <Select>
                                    <Option value={null} >N/A</Option>
                                    <Option value="ONE_YEAR">One year</Option>
                                    <Option value="TWO_YEARS">Two years</Option>
                                    <Option value="THREE_YEARS">Three years</Option>
                                    <Option value="FOUR_YEARS">Four years</Option>
                                    <Option value="FIVE_YEARS">Five years</Option>
                                </Select>
                            )
                        }
                    </FormItem>
                    <Form.Item {...formItemLayout}  label={"QR Code"}>
                        {
                            getFieldDecorator('qrStatus', { initialValue: true})( 
                                <Switch defaultChecked="true" checkedChildren="Enable" unCheckedChildren="Disable"/>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewEquipmentModal));