import { Map } from 'immutable';
import { CHEMICAL_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildUserIndex(chemicals) {
    return SearchUtils.buildIndex(chemicals, 'chemical_id', ['product_name', 'manufacturer_name']);
};

const initialState = Map({
    chemicals: undefined,
    chemicalIndex: buildUserIndex([]),
    sdsExpiredChemicalids: new Set()
});

export default function chemicalState(state = initialState, action) {
    switch (action.type) {
        case CHEMICAL_ACTIONS.CHEMICAL_LIST:            
            const {chemicalList, sdsExpiredList} = action.payload
            return state.set('chemicals', chemicalList).set('sdsExpiredChemicalids', new Set(sdsExpiredList)).set('chemicalIndex',
            buildUserIndex(chemicalList));
        default:
            return state;
    }
}  