import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSelectedDocument } from '../action/CertificationAction';
import { setBreadcrumb } from '../../../actions/commonActions';
import { bindActionCreators } from 'redux';
import { Tabs } from 'antd';
import ConnectLoading from '../../../components/ConnectLoading';
import DetailsTable from '../../../components/DetailsTable';
import { withRouter } from 'react-router-dom';
import NewDocumentEntryForm from '../components/NewDocumentEntryForm';

const TabPane = Tabs.TabPane;
class AudubonDocDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isBreadcumSet: false,
            isEditVisible: false
        }
    }

    async componentDidMount() {
        this.props.getSelectedDocument(this.props.docId);
    }

    componentDidUpdate = () => {
        const { selectedDocument, setBreadcrumb, docId } = this.props;
        if (selectedDocument && docId === selectedDocument.doc_id && !this.state.isBreadcumSet) {
            const path = [
                { route: '/audubon/audubon_document', label: "Audubon Documents" },
                { route: `/audubon/docs/doc_view/${selectedDocument.doc_id}`, label: selectedDocument.doc_title }
            ];
            setBreadcrumb(selectedDocument.doc_title, path);
            this.setState({ isBreadcumSet: true });
        }
    }

    downloadDoc(fileName, docId, locale) {
        this.props.downloadDocument(fileName, docId, locale);
    }

    getDetailTabPaneContent() {
        const { selectedDocument } = this.props;
        let fields = [
            { key: "Title", value: selectedDocument.doc_title },
            { key: "Is Preliminary Document", value: selectedDocument.is_preliminary && selectedDocument.is_preliminary === 1 ? 'True' : 'False' }
        ];

        return (
            <div>
                <div className="pure-g">
                    <div className="pure-u-1">
                        <DetailsTable dataSource={fields} />
                    </div>
                </div>
            </div>
        );
    }

    onCancel = () => {
        this.setState({isEditVisible : false});
    }

    onComplete = (document) => {
        console.log('update');
    }

    render() {
        const { selectedDocument } = this.props;
        if (!selectedDocument) {
            return (<ConnectLoading />);
        }

        return (
            <div>
                {<NewDocumentEntryForm
                    visible={this.state.isEditVisible}
                    onCancel={this.onCancel}
                    onComplete={this.onComplete}
                    mode="edit"
                /> 
                }
                <div className="pure-u-1 pure-u-md-1-3 epar__details__edit--button">
                    {/* To-do - edit document basic details/*
                    /* <Button
                        className='epar__tasks__action--button'
                        onClick={() => this.setState({ isEditVisible: true })}>
                        Edit
                    </Button> */}
                </div>
                <Tabs className='epar__details__view'>
                    <TabPane tab={"Details"} key="details"> 
                    {this.getDetailTabPaneContent()}
                    </TabPane>
                </Tabs>
            </div>
        )
    }

}

function mapStateToProps(state, props) {
    return {
        selectedDocument: state.getIn(["certificationState", "selectedDoc"])
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSelectedDocument: bindActionCreators(getSelectedDocument, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudubonDocDetailView));