import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { setBreadcrumb } from '../../../actions/commonActions';
import { loadTemplateList } from '../actions/jobPlannerActions';
import { TemplateListItem } from '../JobPlannerListItemBuilder';


class TemplateListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null
        };
        this.loadTemplateList();

        this.props.setBreadcrumb("Job Planner Templates", [
            { route: '', label: 'Job Planner Templates' }
        ]);
    }

    loadTemplateList = async () => {
        this.props.loadTemplateList();
    }

    getTemplatesForListView = () => {
        let templateList = this.filterTemplates().map((template) => {
            let builder = new TemplateListItem.Builder(template);
            return builder.build();
        });
        return templateList;
    }

    filterTemplates = () => {
        return SearchUtils.filter(this.props.templateList ? this.props.templateList : [], "task_template_id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const templates = this.filterTemplates();
        const optionsMap = { title: "Templates", children: [] };
        templates.forEach((template) => {
            optionsMap.children.push({ title: template.title, id: template.task_template_id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.templateIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    render() {
        return (
            <div>
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getTemplatesForListView()} loading={this.props.templateList === undefined} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        templateList: state.getIn(['jobPlannerState', 'templateList']),
        templateIndex: state.getIn(["jobPlannerState", "templateIndex"]),
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadTemplateList: bindActionCreators(loadTemplateList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateListView));
