import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Modal, Input, message, Select } from 'antd';

import { addNewChemical } from '../actions/chemicalActions';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};

class NewChemicalModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false
        }
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            await this.setState({ savingChanges: true });
            message.loading('Adding new chemical...', 0);
            const chemicalInfo ={
                productName : values.productName,
                manufacturer : values.manufacturer,
                activeIngredient : values.activeIngredient,
                activeGroup : values.activeGroup,
                formulation : values.formulation,
                country: values.country
            }

            const result = await addNewChemical(chemicalInfo);
            message.destroy();
            if(result){
                message.success('Chemical added successfully', 5);
                form.resetFields();
                const chemicalId = result.chemicalId;
                this.props.onSave(chemicalId);
            }else{
                message.error('Failed to add new chemical', 5);
                await this.setState({ savingChanges: false });
            }
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title={"Add New Chemical"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                width={800}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Country"}>
                        {
                            getFieldDecorator('country', { rules: [{ required: true, message: 'Please select a country' }] })(
                                <Select>
                                    <Option value="AUS">Australia</Option>
                                    <Option value="USA">United States</Option>
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Product Identifier"}>
                        {
                            getFieldDecorator('productName', { rules: [{ required: true, message: 'Please enter product identifier' }] })(
                                <Input maxLength={100} placeholder={"Enter Product Name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Manufacturer"}>
                        {
                            getFieldDecorator('manufacturer', { rules: [{ required: true, message: 'Please enter manufacturer' }] })(
                                <Input maxLength={100} placeholder={"Enter manufacturer"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Active Ingredient"}>
                        {
                            getFieldDecorator('activeIngredient')(
                                <Input maxLength={100} placeholder={"Enter active ingredient"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Active Group"}>
                        {
                            getFieldDecorator('activeGroup')(
                                <Input maxLength={100} placeholder={"Enter active group"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Formulation "}>
                        {
                            getFieldDecorator('formulation')(
                                <Input maxLength={100} placeholder={"Enter formulation"} />
                            )
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewChemicalModal));
