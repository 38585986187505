import { getApi, ConnectAdminApi, postApi } from "../../../utils/api-utils";
import { message } from "antd";
import { JOB_PLANNER_ACTIONS } from '../actions/actionTypes';

export function loadTemplateList() {
    return async function (dispatch, getState) {
        try {
            const result = await getApi(ConnectAdminApi.JOB_PLANNER_API, '/templates');
            await dispatch({
                type: JOB_PLANNER_ACTIONS.TEMPLATE_LIST,
                payload: result.templates
            });
        } catch (e) {
            console.log(e);
            message.error("Error in getting template list");
        }
    }
}
export async function getTemplate(templateId) {
    try {
        const url = `/template/${templateId}`;
        const result = await getApi(ConnectAdminApi.JOB_PLANNER_API, url);
        return result.template;
    } catch (e) {
        console.log(e);
        message.error("Error in getting template");
    }
}

export async function getTemplateOrgList(templateId) {
    try {
        const url = `/orgs/${templateId}`;
        const result = await getApi(ConnectAdminApi.JOB_PLANNER_API, url);
        return result.orgList;
    } catch (e) {
        console.log(e);
        message.error("Error in getting template orgs");
    }
}

export async function addTemplateToOrg(templateId, orgId, createdBy) {
    try {
        const url = `/add_to_org/${templateId}/${orgId}/${createdBy}`;
        await postApi(ConnectAdminApi.JOB_PLANNER_API, url);
    } catch (e) {
        console.log(e);
        message.error("Error in adding template to org");
    }
}