import { DatePicker, Form, Input, Modal, Button, Divider, message } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateEHSTip, deleteEHSTip, getEHSTipAssetUrl } from '../actions/eshTipActions';
import { Editor } from '@tinymce/tinymce-react';
import { uploadFile } from '../../../utils/common-utils';
import { CONFIG } from '../../../config/app-config';

const confirm = Modal.confirm;
const FormItem = Form.Item;
const dateFormat = 'YYYY-MM-DD';
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class EHSTipsDetailsModal extends Component {

    handleEditorChange = (e) => {
        console.log('Content was updated:', e.target.getContent());
        alert(this.state.content);
    }

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false,
            slide: [],
            content: null,
            contentUrl: {},
            currentImg: undefined,
            isDisable: true,
        }
    }

    componentDidMount() {
        const pubDate = moment(this.props.tip.publish_date).format(dateFormat)
        if(pubDate>moment().format(dateFormat)){
            this.setState({isDisable:false});
        }
        setTimeout(this.loadImages, 500);
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form, tip } = this.props;
        const tip_id = this.props.tip.tip_id;
        let slideContent = this.state.content;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            if(this.state.content == null){
                slideContent=this.props.tip.slide_content;
            }
            
            const loggedInUser = this.props.userId;

            let contentUrl = this.state.contentUrl;
            message.loading('EHS Tip is updating. Please wait..', 0);
            const slide_images = tip.slide_images;
            const bucket = this.props.ehsSlideBucket;
            
            for(let img in contentUrl) {
                var isExist = slideContent.includes('id="'+img+'"');
                if(isExist===true){
                    // Upload Image to S3
                    await uploadFile(contentUrl[img].blob(), contentUrl[img].name()+'.jpg', bucket,`${tip_id}`, contentUrl[img].blob().type);
                    const imageName = contentUrl[img].name();
                    slide_images.push(imageName+".jpg");
                }
            }

            const tip_info = {
                title: values.title,
                publishDate: values.date,
                updatedBy: loggedInUser,
                content : slideContent,
                slideImages : slide_images
            }

            await updateEHSTip(tip_id, tip_info);
            message.destroy();
            this.props.form.resetFields();
            this.props.onCancel();
            message.success('EHS Tip is updated successfully.', 5);
        });
    }

    handleEditorChange = (content, editor) => {
        this.setState({ content: content.target.contentDocument.body.innerHTML});
    }

    onDelete = () => {
        confirm({
            title: 'Do you want to delete selected EHS Tip?',
            onOk: () => {
                this.deleteTip();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    deleteTip = async () => {
        await deleteEHSTip(this.props.tip.tip_id);
        this.props.form.resetFields();
        this.props.onCancel();
    }


    loadImages = async () => {
        const { tip } = this.props;
        const images = tip.slide_images || [];
        images.map(async (image) => {
            const url = await getEHSTipAssetUrl(tip.tip_id, image);
            const id = image.split('.').slice(0,-1).join('.');
            var img = window.tinyMCE.activeEditor.dom.get(id);
            if(img){
                img.src = url;
            }
        });
    }
    

    textEditor = () => {
        const _this = this;
        return (
            <Editor
            disabled={this.state.isDisable}
              apiKey={CONFIG.tinyMceApiKey}
              initialValue={_this.props.tip.slide_content}
              init={{
                // define selector
                media_alt_source: true,
                images_upload_base_path: '/some/basepath',
                file_picker_types: 'file image media',
                    
                    setup: function(editor){            //Add Id for image
                        editor.on('NodeChange', function (e) {
                            if(e.element.tagName === "IMG"){          
                            e.element.setAttribute("id", _this.state.currentImg);
                            }
                        })
                    },
                    
                    images_upload_handler: async function (blobInfo, success, failure, progress) {
                    _this.setState({
                        contentUrl: {                   // All selected images
                            ..._this.state.contentUrl,    
                            [blobInfo.name()]: blobInfo        
                        },
                        currentImg: blobInfo.name()     // last selected image
                        });
                    success(URL.createObjectURL(blobInfo.blob()));
                    },
                    
                automatic_uploads: false, 
                height: 500,
                plugins: 'image table',                 // define plugins and toolbars for tinymce editor
                toolbar: 'undo redo | formatselect |image code | bold italic forecolor backcolor | table | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat '
              }}
              onChange={this.handleEditorChange}
            />
        );
    }

    render() {
        const { form, tip } = this.props;
        const { getFieldDecorator } = form;
        const { isDisable } = this.state;

        return (
            <Modal
                width='800px'
                style={{ top: 20 }}
                bodyStyle={{height:'100%'}}
                title={"Edit EHS Tip"}
                visible={this.props.visible}
                okText={"Save changes"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.isDisable }}
            >
                <div className="pure-g">
                    <div className="pure-u-1-3">
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <Button onClick={()=> this.onDelete()} className="epar__tasks__action--button" icon="delete" type="danger"></Button>
                    </div>
                </div>
                <Divider style={{margin:'10px'}} />
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Title"}>
                        {
                            getFieldDecorator('title', { initialValue: tip.title, rules: [{ required: true, message: 'Please Enter EHS Tip Title' }] })(
                                <Input maxLength={100} placeholder={"Enter EHS Tip Title"} disabled={isDisable} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Date"}>
                        {
                            getFieldDecorator('date', { initialValue: moment(tip.publish_date), rules: [{ required: true, message: 'Please Enter EHS Tip Publish Date' }] })(

                                <DatePicker
                                    format="YYYY-MM-DD"
                                    disabledDate={current => {
                                        return current < moment().subtract(1, "day");
                                    }}
                                    disabled={isDisable}
                                />
                            )
                        }
                    </FormItem>
                    <FormItem>
                        {this.textEditor()}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
        ehsSlideBucket: state.getIn(['appConfigState', 'ehsSlideBucket']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EHSTipsDetailsModal));