import lunr from 'lunr';

/**
 * Lunr uses +,-,^,* as wild card tokens for optimizations and for logic, which Lunr.Index.search doen't support.
 */
const LUNR_SEARCH_WILD_CARD_REGEX = /\+|-|\*|\^/gi;
const LUNR_SEARCH_WILD_CARD_REGEX_REPLACER = '_'

/**
 * Builds the index
 * @param {*} docs 
 * @param {*} ref 
 * @param {*} fields 
 */
export function buildIndex(docs, ref, fields) {
    return lunr(function () {
        this.k1(1.5);
        this.b(0);
        this.tokenizer.separator = /[\s]+/;
        this.ref(ref);
        const _this = this;
        fields.forEach(field => _this.field(field));
        this.pipeline.add(lunr.trimmer)
        this.pipeline.remove(lunr.stemmer)
        this.pipeline.remove(lunr.stopWordFilter)

        docs.forEach((doc) => {
            const _doc = {};
            _doc[ref] = doc[ref];
            fields.forEach(field => {
                if (doc[field]) {
                    _doc[field] = doc[field].replace(LUNR_SEARCH_WILD_CARD_REGEX, LUNR_SEARCH_WILD_CARD_REGEX_REPLACER)
                }
            });
            _this.add(_doc);
        });
    });
}

/**
 * searches a given phrase in the given index.
 * @param {*} index 
 * @param {*} phrase 
 */
export function search(index, phrase) {
    if (!phrase) {
        return null;
    }
    const phrases = phrase.split(' ').filter(phrase => phrase.trim().length > 0).map(
        phrase => {
            phrase = phrase.replace(LUNR_SEARCH_WILD_CARD_REGEX, LUNR_SEARCH_WILD_CARD_REGEX_REPLACER);
            return (phrase.length > 1) ? `+*${phrase}*` : `${phrase}^50` // boosting;
        });
    const searchQuery = phrases.join(' ');
    return (searchQuery.trim().length !== 0) ? index.search(searchQuery) : null;
}

export function filter(allDocs, refCol, matches) {
    let docs = [];
    const refs = matches;
    if (!refs) {
        // initial setting
        docs = allDocs;
    } else {
        // hit by onSearch
        refs.forEach((ref) => {
            const doc = allDocs.find((doc) => { return doc[refCol] === ref.ref; });
            if (doc) {
                docs.push(doc);
            }
        });
    }
    return docs;
}