import { message } from "antd";
import { ConnectAdminApi, getApi, postApi, putApi } from "../../../utils/api-utils";
import { EHS_ACTION } from '../actions/actionTypes';

export async function addNewEHSTip(tip_info) {
  const result = await postApi(ConnectAdminApi.EHS_TIPS_API, '/add_ehs_tip', tip_info);
  if (!result.error) {
    const { ehsTipId } = result;
    return ehsTipId;
  } else {
    console.error(`Error creating ehs tip`, result.error);
    return undefined;
  }
}


export async function getEHSTips() {
  const result = await getApi(ConnectAdminApi.EHS_TIPS_API, '/get_ehs_tips');
  if (!result.error) {
    return result.ehsTips;
  } else {
    console.error(`Error getting ehs tips`, result.error);
    return undefined;
  }
}

export async function updateEHSTip(tip_id, tip_info) {
  const result = await putApi(ConnectAdminApi.EHS_TIPS_API, `/update_ehs_tip/${tip_id}`, tip_info);
  if (!result.error) {
    message.success('EHS tip updated', 1);
    return result.ehsTips;
  } else {
    console.error(`Error updating ths tip`, result.error);
    return undefined;
  }
}

export async function deleteEHSTip(tip_id) {
  const result = await putApi(ConnectAdminApi.EHS_TIPS_API, `/delete_ehs_tip/${tip_id}`);
  if (!result.error) {
    message.success('EHS tip deleted', 1);
    return result.ehsTips;
  } else {
    console.error(`Error deleting ths tip`, result.error);
    return undefined;
  }
}

export async function getEHSTipAssetUrl(tipId, fileName) {
  try {
      const url = await getApi(ConnectAdminApi.EHS_TIPS_API, '/get_ehs_slide_image', { queryStringParameters: { path: `${tipId}/${fileName}` } });
      if (url.error) {
          throw new Error(url.error);
      }
      return url;
  } catch (e) {
      console.log(e);
      return '';
  }
}

export function getEHSTipOrganisations() {
  return async function (dispatch, getState) {
    const response = await getApi(ConnectAdminApi.EHS_TIPS_API, `/get_ehs_tip_orgs`, {});
    await dispatch({
      type: EHS_ACTION.EHS_ORGS,
      payload: response.organisations
    })
  }
}

export async function enableEHSTipOrgs(organisations) {
  const response = await putApi(ConnectAdminApi.EHS_TIPS_API, `/enable_ehs_tip_orgs`, { organisations });
  if (!response.error) {
    message.success('EHS Tips enabled for organisation successfully');
    return response;
  }
  else {
    message.error(`Error enable EHS Tips for the organisation`);
  }
}

export async function disableEHSTipOrgs(orgId) {
  const response = await postApi(ConnectAdminApi.EHS_TIPS_API, `/disable_ehs_tip_org/${orgId}`, {});
  if (!response.error) {
    message.success('EHS Tips disabled for organisation successfully');
    return response;
  }
  else {
    message.error(`Error disable EHS Tips for the organisation`);
  }
}