import { Button, Card, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setBreadcrumb } from '../../../actions/commonActions';
import { getEHSTips } from '../actions/eshTipActions';
import EHSTipsCalender from './EHSTipsCalender';
import EHSTipsDetailsModal from './EHSTipsDetailsModal';
import NewTipModal from './NewTipModal';
import EHSTipOrgSelectModal from './EHSTipOrgSelectModal';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

class EHSTIpsListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddNewTipsModal: false,
            ehsTips: [],
            showListView: true,
            detailsModal : {
                visible : false,
                tip : undefined
            },
            showSelectOrgUnitModal: false
        };

        this.props.setBreadcrumb("EHS Tips", [
            { route: '', label: 'EHS-Tips' }
        ]);
    }

    componentWillMount = async () => {
        const ehsTips = await getEHSTips();
        this.setState({ ehsTips })
    }

    newTipModal = () => {
        if (this.state.showAddNewTipsModal) {
            return (
                <NewTipModal
                    visible={this.state.showAddNewTipsModal}
                    onCancel={() => this.newTipModalOnCancel()}
                />
            );
        }
    }

    newTipModalOnCancel = async () =>{
        const ehsTips = await getEHSTips();
        this.setState({showAddNewTipsModal: false , ehsTips});
    }

    getView = () => {
        if (this.state.showListView) {
            return this.getListView();
        } else {
            return this.getCalenderView();
        }
    }

    columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (name, tip) => {
                return <Link className='epar--link' onClick={() => this.showDetailsModal(tip)} to='#'>{name}</Link>
            }
        },
        {
            title: 'Publish date',
            dataIndex: 'publish_date',
            key: 'publish_date',
            render: (date) => {
                return <div>{moment(date).format(dateFormat)}</div>
            }
        }
    ];

    getListView = () => {
        return (
            <div className="pure-g" style={{ marginTop: 10 }}>
                <div className="pure-u-1">
                    <Card>
                        <Table dataSource={this.state.ehsTips} columns={this.columns} />;
                </Card>
                </div>
            </div>
        )
    }

    getCalenderView = () => {
        return (
            <div className="pure-g" style={{ marginTop: 10 }}>
                <div className="pure-u-1">
                    <Card>
                        <EHSTipsCalender 
                            tips={this.state.ehsTips} 
                            showDetailsModal={(tips)=>this.showDetailsModal(tips)}/>
                    </Card>
                </div>
            </div>
        )
    }

    toggleView = () => {
        this.setState({
            showListView: !this.state.showListView
        })
    }

    showDetailsModal = (tip) => {
        this.setState({ detailsModal: { visible: true, tip } });
    }

    renderDetailsModal = () =>{
        if(this.state.detailsModal.visible){
            return <EHSTipsDetailsModal 
            tip={this.state.detailsModal.tip} 
            visible={this.state.detailsModal.visible}
            onCancel={async ()=> this.detailsModalOnCancel()}
            />
        }
    }

    detailsModalOnCancel = async () =>{
        const ehsTips = await getEHSTips();
        this.setState({detailsModal:{visible:false},ehsTips});
    }

    render() {
        return (
            <div>
                <div className="pure-g">
                    <div className="pure-u-1-3">
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <Button className='epar__tasks__action--button'
                            onClick={() => this.setState({ showAddNewTipsModal: true })}>
                            Add New Tip
                        </Button>
                        <Button className='epar__tasks__action--button'
                            onClick={() => this.toggleView()}>
                            Switch view
                        </Button>
                        <Button className='epar__tasks__action--button' onClick={() => this.setState({showSelectOrgUnitModal: true})}>
                            Select Organisations
                        </Button>
                    </div>
                </div>
                {this.getView()}
                {this.newTipModal()}
                {this.renderDetailsModal()}
                {this.state.showSelectOrgUnitModal && 
                    <EHSTipOrgSelectModal
                        onCancel = {()=>this.setState({showSelectOrgUnitModal: false})}
                    />
                }
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EHSTIpsListView));
