import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tree, TreeSelect } from 'antd';
import { bindActionCreators } from 'redux';
import { loadOrgOUList, loadOrgAudubonOUList } from '../actions/commonActions';

const TreeNode = Tree.TreeNode;

class OrgOuMultiSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ouList: [],
      ouOrgMap: new Map(),
      isLoading: true
    };
  }

  componentDidMount = async () => {
    await this.props.loadOrgOUList(this.props.orgId);
    const selectedOUs = this.props.isNationalMapForm ? [] : await loadOrgAudubonOUList(this.props.orgId);

    const ouOrgMap = new Map();
    const ous = this.props.ouList.map(ou => {
      ouOrgMap.set(ou.org_unit_id, {ouId:ou.org_unit_id, orgId:ou.org_id, orgCode:ou.org_code});
      let selectedState;
      if(selectedOUs.find(selectedOu => selectedOu.org_unit_id === ou.org_unit_id)){
        selectedState = true; 
      }
      return {
        title: ou.ou_name,
        value: ou.org_unit_id,
        key: ou.org_unit_id,
        children: [],
        parent: ou.parent_org_unit_id,
        countryCode: ou.country_code,
        state: selectedState
      }
    });
    this.setState({ouOrgMap});

    ous.forEach(ou => {
      if (ou.parent) {
        const parentOu = ous.find(item => item.key === ou.parent);
        parentOu.children.push(ou);
      }
    });
    
    const ouList = ous.filter(item => !item.parent);
    this.setState({ ouList, isLoading: false });
  }

  renderTreeNodes = (data) => {
    const items = data.slice();
    return items.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} value={item.value} disabled={item.state === true ? true : false}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });
  }

  onChange = (value) => {
    this.props.onValue(value.map(ouId=>this.state.ouOrgMap.get(ouId)));
  };

  getSelectedOus = () => {
    return this.state.value;
  }


  render() {
    return (
      <div hidden={this.state.isLoading}>
        <TreeSelect
          showSearch
          style={{ width: '100%' }}
          placeholder="Please select organisation units"
          multiple
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          onChange={this.onChange}
          value={this.props.selectedOus.map(ou=>ou.ouId)}
        >

          {this.renderTreeNodes(this.state.ouList)}

        </TreeSelect>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    ouList: state.getIn(['orgState', 'ouList'])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadOrgOUList: bindActionCreators(loadOrgOUList, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgOuMultiSelector);