// @flow
import React, { Component } from 'react';
import { Menu, Drawer } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleSidePanel } from '../actions/commonActions';


class SideDrawer extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.props.toggleSidePanel(!this.props.collapsed);
  }

  logo = () => {
    return (
      <div className="logo epar__logo">
      </div>
    );
  }

  render() {
    return (
      <div>
        <Drawer
          title={this.logo()}
          placement="left"
          closable={false}
          onClose={this.toggle}
          width={250}
          className='epar__side__drawer'
          visible={!this.props.collapsed}>
          <Menu
            defaultOpenKeys={['organisations']}
            defaultSelectedKeys={['dashboard']}
            theme="dark"
            mode="inline"
            onClick={this.props.handleMenuClick}
            className='epar__side-menu'>
            {this.props.children}
          </Menu>
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    collapsed: state.getIn(['appConfigState', 'sidePanelToggle'])
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidePanel: bindActionCreators(toggleSidePanel, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);