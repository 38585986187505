export const FREQUENCIES = {
    BI_ANNUAL: '6 months',
    ANNUAL: '1 Year',
    TWO_YEARS: '2 Years',
    THREE_YEARS: '3 Years',
    FOUR_YEARS: '4 Years',
    FIVE_YEARS: '5 Years',
    NA: 'N/A'
}

export const CATEGORIES = {
    CAREER_DEVELOPMENT: 'Career Development',
    ENVIRONMENTAL_MANAGEMENT: 'Environmental Management',
    HUMAN_RESOURCES: 'Human Resources',
    MANAGERS_AND_SUPERVISORS: 'Managers & Supervisors',
    PERSONAL_DEVELOPMENT: 'Personal Development',
    WORKPLACE_SAFETY: 'Workplace Safety'
}

export const COURSE_CONTENT_FILE_NAME  = 'slides.pdf'