import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Modal, Input, message } from 'antd';

import { updateChemicalDetails } from '../actions/chemicalActions';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class EditChemicalModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false
        }
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            await this.setState({ savingChanges: true });
            message.loading('Saving changes...', 0);
            const chemicalInfo ={
                productName : values.productName,
                manufacturer : values.manufacturer,
                activeIngredient : values.activeIngredient,
                activeGroup : values.activeGroup,
                formulation : values.formulation
            }

            const result = await updateChemicalDetails(this.props.chemicalInfo.chemical_id, chemicalInfo);
            message.destroy();
            if(result){
                message.success('Chemical details updated successfully');
                form.resetFields();
                this.props.onSave();
            }else{
                message.error('Failed to update chemical details');
                await this.setState({ savingChanges: false });
            }
        });
    }

    render() {
        const { form, chemicalInfo } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title={"Edit Chemical Details"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Product Identifier"}>
                        {
                            getFieldDecorator('productName', { initialValue:chemicalInfo.product_name, rules: [{ required: true, message: 'Please enter product identifier' }] })(
                                <Input maxLength={100} placeholder={"Enter Product Name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Manufacturer"}>
                        {
                            getFieldDecorator('manufacturer', { initialValue:chemicalInfo.manufacturer_name,  rules: [{ required: true, message: 'Please enter manufacturer' }] })(
                                <Input maxLength={100} placeholder={"Enter manufacturer"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Active Ingredient"}>
                        {
                            getFieldDecorator('activeIngredient', { initialValue:chemicalInfo.active_ingredient })(
                                <Input maxLength={100} placeholder={"Enter active ingredient"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Active Group"}>
                        {
                            getFieldDecorator('activeGroup', { initialValue:chemicalInfo.group })(
                                <Input maxLength={100} placeholder={"Enter active group"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Formulation "}>
                        {
                            getFieldDecorator('formulation', { initialValue:chemicalInfo.formulation })(
                                <Input maxLength={100} placeholder={"Enter formulation"} />
                            )
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditChemicalModal));