import React, {Component} from 'react';
import {Modal, Form, Input, Upload, Icon, message, InputNumber, DatePicker, Checkbox, Select, Button} from 'antd';
import TimezonePicker from 'react-timezone';
import { CONFIG } from '../../../config/app-config';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import { getSSOProviderStatus } from '../actions/orgActions';
import {getOrgUsers, addQRLoginUser, resetQRLoginUser, addUserToGroup, removeUserFromGroup, removeTempLogin} from '../../users/actions/userActions';
import { USER_GROUPS } from '../../users/constants';
import { CONNECT_FEATURE_NAMES } from '../../organisations/constants';

const { v4: uuidv4 } = require('uuid');

const FormItem = Form.Item;
const Option = Select.Option;
const dateFormat="YYYY-MM-DD";
const SSO_ENABLED = 1;

class EditOrganisationDetailsFormClass extends Component{
  state = {
    loading: false,
    logoFile: undefined,
    orgUsers: [],
    kioskContractor: null,
    removeContractor: false
  };

  componentDidMount = async() =>{
    const users = await getOrgUsers(this.props.orgInfo.org_id);
    this.setState({orgUsers: users});
  }

  beforeUpload = (logoFile) => {
    const isJPG = logoFile.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file');
      return false;
    }
    const isLt2M = logoFile.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB');
      return false;
    }

    let reader = new FileReader();
    reader.readAsDataURL(logoFile);

    reader.onloadend = () => {
      logoFile.url = reader.result;
        this.setState( {logoFile} );        
    }
    return false;
  }
  
  onCancel = () =>{
    this.props.form.resetFields();
    this.setState({
      logoFile: undefined, 
      kioskContractor:null,
      removeContractor: false
    });
    this.props.onCancel();
  }; 

  onOk = () =>{
    this.props.onSave(this.state.logoFile, this.state.kioskContractor, this.onCancel, this.state.removeContractor);
  };

  render(){
    const { visible, form, modalTitle } = this.props;
    const { getFieldDecorator, setFieldsValue } = form;

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
    };
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
   
    let imageUrl;
    if(this.state.logoFile){
      imageUrl=this.state.logoFile.url;
    }else{       
      const { s3HostUrl, orgPublicBucket } = CONFIG;
      imageUrl = `${s3HostUrl}/${orgPublicBucket}/${this.props.orgInfo.org_id}/logo.jpg`;
    }

    const constractorsOptions = this.state.orgUsers.map(user => (
      <Option key={user.user_id} value={user.user_id}>
          {`${user.first_name} ${user.last_name}`}
      </Option>
    ));

    const onSelectContractor = (value) => {
      const user = this.state.orgUsers.find(user => user.user_id === value);
      this.setState({kioskContractor: user});
    }

    const removeKioskContractor = () => {
      const { form } = this.props;
      form.resetFields(['contractorKioskUser']);
      this.setState({kioskContractor: null, removeContractor: true});
    }

    return (
      <Modal
        width={700}
        visible={visible}
        title={modalTitle}
        okText="Save"
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        <Form layout="horizontal">
          <FormItem label="Organisation Name" {...formItemLayout}>
            {getFieldDecorator('organizationName', {
              rules: [{required: true, message: 'Please enter organisation name.'}],
            })(
              <Input/>
            )}
          </FormItem>
          <FormItem label="Default Timezone" {...formItemLayout} >
            {getFieldDecorator('defaultTimezone', {
              rules: [{ required: true, message: 'Please select default timezone.' }],
            })(
              <TimezonePicker  
                style={{zIndex: '1'}}
                onChange={timezone =>  setFieldsValue({ defaultTimezone: timezone })}
                inputProps={{
                  placeholder: 'Select timezone',
                  name: 'defaultTimezone',
                }}
              />
            )}
          </FormItem>
          <FormItem label="User Limit" {...formItemLayout} >
            {getFieldDecorator('userLimit', {
              rules: [{ required: true, message: 'Please add a valid number.' }],
            })(
              <InputNumber min={1} defaultValue={1} step={1} />
            )}
          </FormItem>
          <FormItem label="Expiry Date" {...formItemLayout} >
            {getFieldDecorator('expiryDate', {
              rules: [{ required: true, message: 'Please set a valid date.' }],
            })(
              <DatePicker format={dateFormat}/>
            )}
          </FormItem>
          <FormItem label="Logo" {...formItemLayout}>
            {getFieldDecorator('orgLogo')(
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="//jsonplaceholder.typicode.com/posts/"
                beforeUpload={this.beforeUpload}
              >
                {imageUrl ? <img src={imageUrl} width="200" alt="avatar" /> : uploadButton}
              </Upload> 
            )}
          </FormItem>
          <FormItem wrapperCol={{ span: 16, offset: 9 }} >
            {getFieldDecorator('is_sso', { valuePropName: 'checked', })(
                <Checkbox onChange={this.toggleSingleSignOn}>Enable Single Sign-On</Checkbox>
            )}
          </FormItem>
          {form.getFieldValue('is_sso') && <FormItem {...formItemLayout} label="Identity Provider">
            {
              getFieldDecorator('providerName', { rules: [{ required: true, message: 'Please enter identity provider' }] })(
                  <Input maxLength={100} placeholder={"Identity Provider"} />
              )
            }
          </FormItem>}
          {this.props.orgInfo.features.includes(CONNECT_FEATURE_NAMES.CONTRACTORS) &&
            <FormItem label='Kiosk Contractor' {...formItemLayout}>
              <div div style={{ display: 'flex', alignItems: 'center' }}>
              {getFieldDecorator('contractorKioskUser', {
                rules: [{ required: false }]
              })(
                  <Select placeholder="Select user" onSelect={(el) => onSelectContractor(el)} style={{ marginRight: '8px' }} 
                  showSearch = {true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {constractorsOptions}
                  </Select>
              )}
              <Button type="primary" onClick={() => removeKioskContractor()} shape="circle" icon="delete" />
              </div>
            </FormItem>
          }
          {this.state.kioskContractor && <span>
            <Form.Item label={"Username"} {...formItemLayout}>
            {getFieldDecorator('userName', {
              initialValue: this.state.kioskContractor.username || '',
              rules: [{ required: true, message: 'Please input username!' }],
            })(
              <Input disabled = {true} placeholder="Username" onChange={this.handleQRUsernameChange} />
            )}
          </Form.Item>
          <Form.Item label={"Password"} {...formItemLayout}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input password!' }],
            })(
              <Input.Password placeholder="Password" onChange={this.handleQRPasswordChange} />
            )}
          </Form.Item>
          </span>}
        </Form>
      </Modal>
    );
  }
}   

const EditOrganisationDetailsForm = Form.create({
  mapPropsToFields(props) {
    return {
      organizationName: Form.createFormField({
        value:props.orgInfo.name
      }),
      defaultTimezone: Form.createFormField({
        value:props.orgInfo.timezone
      }),
      userLimit: Form.createFormField({
        value:  props.orgInfo.user_limit? props.orgInfo.user_limit : ''
      }),
      expiryDate: Form.createFormField({
        value:  props.orgInfo.expiry_date? moment(props.orgInfo.expiry_date, dateFormat) : ''
      }),
      is_sso: Form.createFormField({
        value:  props.orgInfo.is_sso === SSO_ENABLED
      }),
      providerName: Form.createFormField({
        value:  props.orgInfo.providerName || ''
      }),
      contractorKioskUser: Form.createFormField({
        value:  props.orgInfo.settings && props.orgInfo.settings.kioskUser ? props.orgInfo.settings.kioskUser.userId : ''
      })
    };
  }
})(EditOrganisationDetailsFormClass);     



export class EditOrgDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle:  'Edit Organisation Details',      
      orgLogo: undefined
    }
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  handleCancel = () => {
    this.props.callback();
  }

  handleEditOrgDetails = (logoFile, kioskContractor, onSave, kioskContractorRemoveState) => {
    const form = this.form;
    form.validateFields(async(err, values) => {
      if (err) {
        return;
      }

      let organisation = {
        name: form.fieldsStore.getFieldValue('organizationName'),
        timeZone: form.fieldsStore.getFieldValue('defaultTimezone'),
        userLimit: form.fieldsStore.getFieldValue('userLimit'),
        expiryDate: form.fieldsStore.getFieldValue('expiryDate').format(dateFormat),
        isSSO: form.fieldsStore.getFieldValue('is_sso'),
        providerName: form.fieldsStore.getFieldValue('providerName')
      };
      if(organisation.isSSO) {
        const isSSO = await getSSOProviderStatus(this.props.orgInfo.org_id, organisation.providerName);
        if(!isSSO) {
            return;
        }
        if(!isSSO.isOrgProviderExist) {
            message.warning('Unable to find the SSO Provider. Please check again.')
            return;
        }
        if(!isSSO.isProviderAvailable) {
            message.warning(`The SSO provider "${organisation.providerName}" already exists in the system. Please check again`);
            return;
        }
      }
      if(kioskContractor) {
        await addUserToGroup(kioskContractor.username, [USER_GROUPS.KIOSK_CONTRACTOR]);
        if(this.props.orgInfo.settings && this.props.orgInfo.settings.kioskUser && (kioskContractor.user_id !== this.props.orgInfo.settings.kioskUser.userId)) {
          // reset auto login if user changed
          await resetQRLoginUser(this.props.orgInfo.settings.kioskUser.userId, false);
          await removeUserFromGroup(this.props.orgInfo.settings.kioskUser.username, USER_GROUPS.KIOSK_CONTRACTOR);
        }
        
        // create and save auto-login token for the user
        const secretKey = uuidv4();
        const tokenPayload = {
          username: kioskContractor.username,
          password: form.fieldsStore.getFieldValue('password')
        };
        const token = jwt.sign(tokenPayload, secretKey);
        const response = await addQRLoginUser(this.props.orgInfo.org_id, token, kioskContractor.user_id, false);

        if (response.status === 'ok') {
          organisation.settings = {
            kioskUser: {
              userId: kioskContractor.user_id,
              username: kioskContractor.username,
              firstName: kioskContractor.first_name              ,
              lastName: kioskContractor.last_name,
            }
          }  
        }
      }
      else if(kioskContractorRemoveState && this.props.orgInfo.settings && this.props.orgInfo.settings.kioskUser) {
        await removeUserFromGroup(this.props.orgInfo.settings.kioskUser.username, USER_GROUPS.KIOSK_CONTRACTOR);
        await removeTempLogin(this.props.orgInfo.org_id);
        organisation.settings = this.props.orgInfo.settings;
        delete organisation.settings.kioskUser;
      }
      this.props.editOrgDetails(organisation, logoFile)
      onSave();
      this.props.callback();
    });
  }

  saveFormRef = (form) => {
    this.form = form;
  }

  render() {
    return (
      <div>
        <EditOrganisationDetailsForm 
          orgInfo={this.props.orgInfo}
          ref={this.saveFormRef}
          visible={this.props.visible}
          modalTitle={this.state.modalTitle}
          onSave={this.handleEditOrgDetails}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}

export default EditOrgDetailsForm;
