import { Map } from 'immutable';
import { CERTIFICATION_ACTION } from '../action/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

const initialState = Map({
    documentList: undefined,
    selectedDoc: undefined,
    documentIndex: buildDocIndex([]),
    inspectionList: [],
    inspectionIndex: buildTemplateIndex([]) 
});

function buildDocIndex(docs) {
    return SearchUtils.buildIndex(docs, 'doc_id', ['doc_title']);
};

function buildTemplateIndex(templates) {
    return SearchUtils.buildIndex(templates, 'template_id', ['name']);
};

export default function inspectionState(state = initialState, action) {
    switch (action.type) {
        case CERTIFICATION_ACTION.DOC_LIST:
            return state.set('documentList', action.payload).set('documentIndex',
            buildDocIndex(action.payload));
        case CERTIFICATION_ACTION.SELECTED_DOC:
            return state.set('selectedDoc', action.payload);
        case CERTIFICATION_ACTION.INSPECTION_LIST:
            return state.set('inspectionList', action.payload).set('inspectionIndex', 
            buildTemplateIndex(action.payload));
        default:
            return state;
    }
}  