import React from "react";
import { List } from 'antd';
import { isMobile } from '../utils/common-utils';


function getTemplate(item) {
  if (item.template) {
    return item.template;
  }
  return (
    <div className='pure-g epar__details__table--item'>
      <div className='pure-u-md-5-24 pure-u-1'><b>{item.key} : </b></div>
      <div className='pure-u-md-19-24 pure-u-1'>{item.value}</div>
    </div>
  )
}

export default function DetailsTable(props) {
  if(!isMobile()){
    return (
      <List
        className='epar__details__table'
        size="small"
        dataSource={props.dataSource}
        renderItem={item => (
          <List.Item>
            {getTemplate(item)}
          </List.Item>
        )}
      />
    )
  }else {
    return (
      <div className='epar_summery_view'>
      <List
        className='epar__details__table'
        size="small"
        dataSource={props.dataSource}
        renderItem={item => (
          <List.Item>
            {getTemplate(item)}
          </List.Item>
        )}
      />
      </div>
    )
  }
}