import { Map } from 'immutable';
import { JOB_PLANNER_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildTemplateIndex(templateList) {
    return SearchUtils.buildIndex(templateList, 'task_template_id', ['title']);
};

const initialState = Map({
    templateList: undefined,
    templateIndex: buildTemplateIndex([])
});

export default function jobPlannerState(state = initialState, action) {
    switch (action.type) {
        case JOB_PLANNER_ACTIONS.TEMPLATE_LIST:
            return state.set('templateList', action.payload).set('templateIndex',
            buildTemplateIndex(action.payload));
        default:
            return state;
    }
}  