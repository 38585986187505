import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Row, Upload, Col, Input, Button, Icon, Modal, Select, message } from 'antd';
import center from '@turf/center';
import moment from 'moment';
import { CONFIG } from '../../../config/app-config';
import { updateNationalMapSite } from '../actions/orgActions';
import { compressImage, isFileExists } from '../../../utils/common-utils';
import OrgOUMultiSelector from '../../../components/OrgOUMultiSelector';
import { NATIONAL_MAP_INSTANCES, NATIONAL_MAP_MAX_IMAGE_SIZE, SITE_TYPE } from '../constants';
import Map from '../../../components/Map';

const FormItem = Form.Item;
const { Option } = Select;

class EditNationalMapSiteForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false,
            showDrawMapAreaModal: false,
            bannerImage: undefined,
            profileImage: undefined,
            climateChampionImage: undefined,
            geometry: undefined,
            polygonFeatures: [],
            selectedOus: [],
            updatedImageFiles: [],
            ouIds: []
        }
    }

    componentDidMount = () => {
        const { s3HostUrl, nationalMapBucket } = CONFIG;
        const profileImage = { url: `${s3HostUrl}/${nationalMapBucket}/${this.props.site.siteId}/profileImage.jpeg?${moment()}` };
        const bannerImage = { url: `${s3HostUrl}/${nationalMapBucket}/${this.props.site.siteId}/bannerImage.jpeg?${moment()}` };
        const climateChampionImageUrl = `${s3HostUrl}/${nationalMapBucket}/${this.props.site.siteId}/climateChampionImage.jpeg?${moment()}`;
        const climateChampionImage = isFileExists(climateChampionImageUrl) && { url: climateChampionImageUrl };
        const selectedOus = [];
        this.props.site.ouIds.map(ouId => selectedOus.push({ ouId }))
        this.setState({ 
            profileImage, 
            bannerImage, 
            climateChampionImage, 
            selectedOus, 
            ouIds: this.props.site.ouIds,
            geometry: this.props.site.geometry,
            savingChanges: false
        });
    }

    beforeUploadProfileImage = async (imageFile) => {
        imageFile = await compressImage(imageFile, NATIONAL_MAP_MAX_IMAGE_SIZE);
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            imageFile.url = reader.result;
            this.setState({ profileImage: imageFile });
        };
        this.setState({
            updatedImageFiles: [...this.state.updatedImageFiles, 'profileImage']
        });
    }

    beforeUploadBannerImage = async (imageFile) => {
        imageFile = await compressImage(imageFile, NATIONAL_MAP_MAX_IMAGE_SIZE);
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            imageFile.url = reader.result;
            this.setState({ bannerImage: imageFile });
        };
        this.setState({
            updatedImageFiles: [...this.state.updatedImageFiles, 'bannerImage']
        });
    }

    beforeUploadClimateChampionImage = async (imageFile) => {
        imageFile = await compressImage(imageFile, NATIONAL_MAP_MAX_IMAGE_SIZE);
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            imageFile.url = reader.result;
            this.setState({ climateChampionImage: imageFile });
        };
        this.setState({
            updatedImageFiles: [...this.state.updatedImageFiles, 'climateChampionImage']
        });
    }

    handleOk = () => {
        const { form, site, callBack } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            if (this.state.ouIds.length === 0 && site.siteType === SITE_TYPE.OU_LEVEL) {
                message.warn('Please select Organization Units..');
                return;
            }
            this.setState({ savingChanges: true });
            const previousInstanceType = (site.instanceType !== values.instanceType) ? site.instanceType : undefined;
            const nationalMapSite = {
                ...site,
                instanceType: values.instanceType,
                ouIds: this.state.ouIds,
                geometry: this.state.geometry,
                properties: {
                    contactNumber: values.contactNumber,
                    traditionalOwners: values.traditionalOwners,
                    siteName: values.siteName,
                    siteDescription: values.siteDescription,
                    website: values.website,
                    shortDescription: values.shortDescription
                },
                team: {
                    climateChampions: [
                        {
                            climateChampionName: values.climateChampionName,
                            climateChampionDescription: values.climateChampionDescription
                        }
                    ]
                }
            }

            const imageFiles = {};
            this.state.updatedImageFiles.map(imageFile => {
                imageFiles[`${imageFile}`] = this.state[`${imageFile}`];
            });

            await updateNationalMapSite(site.orgId, site.siteId, nationalMapSite, imageFiles, previousInstanceType);
            await callBack();
            this.handleCancel();
        });
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.setState({ updatedImageFiles: [] });
        this.props.onCancel();
    }

    handleSelectOus = (ous) => {;
        this.setState({ selectedOus: ous });
        const ouIds = [];
        ous.map(ou => ouIds.push(ou.ouId));
        this.setState({ ouIds });;
    }

    handleDrawMapArea = () => {
        if (this.state.polygonFeatures.length) {
            const geometry = this.state.polygonFeatures[0].geometry;
            // change polygon format to display in NM side
            if (geometry.type === 'Polygon') {            
            const multiPolygon = {
                type: "MultiPolygon",
                coordinates: [geometry.coordinates]
            };
                this.setState({ geometry: multiPolygon });
            } else {
                this.setState({ geometry });
            }
        } else {
            this.setState({ geometry: undefined });
        }
        
        this.handleCancelDrawMapArea();
    }

    handleCancelDrawMapArea = () => {
        this.setState({ showDrawMapAreaModal: false });
    }

    onMapAreaDraw = (data) => {
        const polygonFeatures = data.features;
        this.setState({ polygonFeatures });
    }

    onMapAreaDelete = (data) => {
        const polygonFeatures = data.features;
        this.setState({ polygonFeatures });
    }

    viewSiteAreaMap = (site) => {
        let centerPoint = [150.82520658489005, -33.850000647657914]; // Default Value: coordinates around Sydney
        let zoom = 10;

        if (site.geometry) {
            centerPoint = center(site).geometry.coordinates;
            zoom = 15;
        }

        return (
            <div>
                {this.state.showDrawMapAreaModal &&
                    <Modal
                        title='View Map Area'
                        visible={this.state.showDrawMapAreaModal}
                        width={'80vw'}
                        okText="Save"
                        onOk={() => this.handleDrawMapArea()}
                        onCancel={() => this.handleCancelDrawMapArea()}
                    >
                        <Map 
                            geometry={site.geometry} 
                            center={centerPoint} 
                            zoom={zoom} 
                            createMode={true}
                            viewMode={false}
                            onCreate={this.onMapAreaDraw}
                            onDelete={this.onMapAreaDelete}
                            onUpdate={this.onMapAreaDraw}
                        />
                    </Modal>
                }
            </div>
        );
    }

    render() {
        const { form, site } = this.props;
        const { getFieldDecorator } = form;
        const { properties, team, instanceType, siteType } = site;
        const climateChampion = team ? team.climateChampions[0] : undefined;
        const profileImage = this.state.profileImage && this.state.profileImage.url;
        const bannerImage = this.state.bannerImage && this.state.bannerImage.url;
        const climateChampionImage = this.state.climateChampionImage && this.state.climateChampionImage.url;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <Modal
                title='Edit Site'
                visible={this.props.visible}
                width={'80vw'}
                bodyStyle={{ minHeight: 1000, height: '100vh' }}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <div className='epar_national_map_edit_form'>
                    <Form layout="vertical">
                        <div className='epar_national_map'>
                            <Row>
                                <Col sm={24} md={12} style={{ paddingInline: 10 }}>
                                    <FormItem label="Instance">
                                        {getFieldDecorator('instanceType', {
                                            initialValue: instanceType,
                                        })(
                                            <Select>
                                                {NATIONAL_MAP_INSTANCES.map(instance => (
                                                    <Option value={instance.key}>{instance.name}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                {
                                    siteType === SITE_TYPE.OU_LEVEL &&
                                    <Col sm={24} md={12} style={{ paddingInline: 10 }}>
                                        <FormItem label="Organization Unit" validateStatus={this.state.ouIds.length === 0 ? 'error' : ''}>
                                            <OrgOUMultiSelector
                                                onValue={selectedOus => this.handleSelectOus(selectedOus)}
                                                orgId={this.props.site.orgId}
                                                selectedOus={this.state.selectedOus}
                                                isNationalMapForm={true}
                                            />
                                        </FormItem>
                                    </Col>
                                }
                            </Row>
                            <Col sm={24} md={12} style={{ paddingInline: 10 }}>
                                <p className='epar_national_map_form_category'>General Details</p>
                                <FormItem label="National Map Site Name">
                                    {getFieldDecorator('siteName', {
                                        initialValue: properties.siteName,
                                        rules: [
                                            { required: true, message: 'Please enter site name!' },
                                            { max: 100, message: 'Site name must be less than 100 characters.' },
                                        ],
                                    })(
                                        <Input placeholder="Enter site name" />
                                    )}
                                </FormItem>
                                <FormItem label="Short Description">
                                    {getFieldDecorator('shortDescription', {
                                        initialValue: properties.shortDescription,
                                        rules: [
                                            { required: true, message: 'Please enter short description!' },
                                            { max: 200, message: 'Short description must be less than 200 characters.' },
                                        ],
                                    })(
                                        <Input.TextArea placeholder="Enter short description" />
                                    )}
                                </FormItem>
                                <FormItem label="Description">
                                    {getFieldDecorator('siteDescription', {
                                        initialValue: properties.siteDescription,
                                        rules: [
                                            { required: true, message: 'Please enter site description!' },
                                            { min: 100, message: 'Site description must be minimum 100 characters.' },
                                            { max: 1500, message: 'Site description must be less than 1500 characters.' },
                                        ],
                                    })(
                                        <Input.TextArea placeholder="Enter site description" />
                                    )}
                                </FormItem>
                                <FormItem label="Traditional Custodians">
                                    {getFieldDecorator('traditionalOwners', {
                                        initialValue: properties.traditionalOwners,
                                        rules: [{ required: true, message: 'Please enter traditional custodians!' }],
                                    })(
                                        <Input placeholder="Enter traditional custodians name" />
                                    )}
                                </FormItem>
                                <FormItem label="Profile Image">
                                    {getFieldDecorator('profileImage', {
                                        initialValue: profileImage,
                                        rules: [{ required: true, message: 'Please enter profile image!' }],
                                    })(
                                        <Upload
                                            name="avatar"
                                            accept="image/png, image/jpeg"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.beforeUploadProfileImage(file)}
                                        >
                                            {profileImage ? <img src={profileImage} width="100" alt="profileImage" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>
                                <FormItem label="Banner Image">
                                    {getFieldDecorator('bannerImage', {
                                        initialValue: bannerImage,
                                        rules: [{ required: true, message: 'Please enter banner image!' }],
                                    })(
                                        <Upload
                                            name="avatar"
                                            accept="image/png, image/jpeg"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.beforeUploadBannerImage(file)}
                                        >
                                            {bannerImage ? <img src={bannerImage} width="100" alt="bannerImage" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>

                            <Col sm={24} md={12} style={{ paddingInline: 10 }}>
                                <p className='epar_national_map_form_category'>Climate Champion Details</p>
                                <FormItem label="Climate Champion's Name">
                                    {getFieldDecorator('climateChampionName', {
                                        initialValue: climateChampion && climateChampion.climateChampionName,
                                    })(
                                        <Input placeholder="Enter climate champion's name" />
                                    )}
                                </FormItem>
                                <FormItem label="Description">
                                    {getFieldDecorator('climateChampionDescription', {
                                        initialValue: climateChampion && climateChampion.climateChampionDescription,
                                        rules: [
                                            { max: 1500, message: 'Description must be less than 1500 characters.' },
                                        ]
                                    })(
                                        <Input.TextArea placeholder="Enter description" />
                                    )}
                                </FormItem>
                                <FormItem label="Profile Image">
                                    {getFieldDecorator('climateChampionImage', {
                                        initialValue: climateChampionImage,
                                    })(
                                        <Upload
                                            name="climateChampionImage"
                                            accept="image/png, image/jpeg"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.beforeUploadClimateChampionImage(file)}
                                        >
                                            {climateChampionImage ? <img src={climateChampionImage} width="100" alt="climateChampionImage" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>

                                <p className='epar_national_map_form_category'>Contact Details</p>
                                <FormItem label="Website">
                                    {getFieldDecorator('website', {
                                        initialValue: properties.website,
                                        rules: [
                                            {
                                                pattern: new RegExp(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g),
                                                message: 'Please enter valid website URL'
                                            }
                                        ]
                                    })(
                                        <Input placeholder="Enter website" />
                                    )}
                                </FormItem>
                                <FormItem label="Phone Number">
                                    {getFieldDecorator('contactNumber', {
                                        initialValue: properties.contactNumber,
                                        rules: [
                                            { pattern: new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/), message: 'Please enter valid Phone Number' }
                                        ]
                                    })(
                                        <Input placeholder="Enter phone number" />
                                    )}
                                </FormItem>

                                <Row><div>Mark Area</div>
                                    <Button onClick={() => this.setState({ showDrawMapAreaModal: true })}>Open Map</Button></Row>
                            </Col>
                        </div>
                    </Form>
                    {this.viewSiteAreaMap(site)}
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
    };
  }

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditNationalMapSiteForm));