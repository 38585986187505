// @flow
import uuidv4 from 'uuid/v4';
import { ConnectAdminApi, getApi, postApi, putApi } from './../../../utils/api-utils';
import { message } from 'antd';
import { CERTIFICATION_ACTION } from '../action/actionTypes';

export async function addDocument(document) {
  document.doc_id = uuidv4();
  const { pdfFile, doc_id } = document;
  const file = pdfFile[0];
  try {
    const url =  await getContentSignedURL(doc_id);
    const response = await fetch(url.contentUrl, {
      method: 'PUT',
      body: file,
      headers: {
        "Content-Type": file.type,
      }
    });
    if(response.ok){
      await addDocumentRecord(document);
    }
  } catch (e) {
    message.destroy();
    message.error('Error in adding document. Retry later');
  }
}

async function addDocumentRecord(document) {
  let doc = document;
  delete doc.pdfFile;
  const result = await postApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, '/add_document', doc);
  if (!result.error) {
    message.destroy();
    message.success('Document added successfully.');
    loadDocuments();
  } else {
    message.destroy();
    console.error(`Error in adding document`, result.error);
  }
}

export function loadDocuments() {
  return async function (dispatch, getState) {
    try {
        const result = await getApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, `/get_documents`);
        await dispatch({
            type: CERTIFICATION_ACTION.DOC_LIST,
            payload: result
        });
    } catch (e) {
        console.log(e);
        message.error("Error in getting document list");
    }
  }
}

export function getSelectedDocument(docId) {
  return async function (dispatch, getState) {
    try {
        const result = await getApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, `/get_document/${docId}`);
        console.log('result:', result);
        await dispatch({
          type: CERTIFICATION_ACTION.SELECTED_DOC,
          payload: result[0]
        });
    } catch (e) {
        console.log(e);
        message.error("Error in getting document");
    }
  }
}

async function getContentSignedURL(docId) {
  const result = await getApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, `/doc_content_url/${docId}`, {});
  if (!result.error) {
    return result;
  } else { 
    console.error(`Error in adding document`, result.error);
    return undefined;
  } 
}

export async function openS3DocumentByURL(apiUrl) {
  message.loading("please wait",0);
  await fetch(apiUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
          'Content-Type': 'application/pdf'
      },
  })
  .then( res => res.blob())
  .then( blob => {
    var url = new Blob([blob], {type: 'application/pdf'});
    var file = window.URL.createObjectURL(url);
    message.destroy();
    window.open(file);
  });
}

export function loadInspectionList() {
  return async function (dispatch, getState) {
    try {
        const result = await getApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, `/inspections`);
        await dispatch({
            type: CERTIFICATION_ACTION.INSPECTION_LIST,
            payload: result
        });
    } catch (e) {
        console.log(e);
        message.error("Error in getting document list");
    }
  }
}

export async function createInspectionTemplate(template, createdBy) {
  try {
    const inspectionDetaisls = {
      template,
      createdBy
    }
    const url = `/inspection-template`;
    await postApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, url, inspectionDetaisls);
  } catch (e) {
    console.log(e);
    message.error("Error in creating template");
  }
}


export async function getTemplate(templateId) {
  try {
    const url = `/template`;
    const result = await getApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, url, { queryStringParameters: { templateId } });
    const response = await fetch(result.url);
    const json = await response.json();
    return json;
  } catch (e) {
    console.log(e);
    message.error("Error in creating template");
  }
}

export async function editTemplate(template, templateId) {
  try {
      const url = `/template/${templateId}`;
      await putApi(ConnectAdminApi.EXTERNAL_CERTIFICATION_API, url, template);
  } catch (e) {
      console.log(e);
      message.error("Error in editing template");
  }
}