import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumb, loadOrgAudubonOUList } from '../../../actions/commonActions';
import { List, Button, Skeleton, Tooltip } from 'antd';
import SelectCertificationOrgUnits from './SelectCertificationOrgUnits';
import ConnectLoading from '../../../components/ConnectLoading';

class AudubonCertificationOU extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOus: undefined,
            ouSelectModalVisible: false 
        }
    }

    loadOUList = async() => {
      const ous = await loadOrgAudubonOUList(this.props.orgId);
      this.setState({selectedOus: ous && ous.length > 0 ? ous : [] });
    }
    
    componentDidMount = async() => {
      this.loadOUList();
    }
    
    onSaveFeature = () => {
        this.setState({ouSelectModalVisible: false});
        this.loadOUList();
    }

    render(){
      if(!this.state.selectedOus) {
        return <ConnectLoading />;
      }
      
      return (
        <div>
            <div className="pure-u-3-5">
            </div>
            <div className='pure-u-1-5'>
              <Tooltip title={'Please select organisation units for Audubon certification'}>
                <Button style={{float: 'right'}} type="primary" shape="circle" icon="question" />
              </Tooltip>
            </div>
            <div className='pure-u-1-5'>
              <Button className='epar__tasks__action--button' type='primary' onClick={() => this.setState({ouSelectModalVisible:true})}>
                Select organisation units
              </Button>
            </div>
            {this.state.ouSelectModalVisible && 
            
            <SelectCertificationOrgUnits 
                 onCancel={()=>this.setState({ouSelectModalVisible: false})}
                 onSave={()=>this.onSaveFeature()}
                 orgId={this.props.orgId}
            />}
            
            <div className="pure-g" style={{ marginTop: 20 }}>
              <div className="pure-u-1">
                <List
                    locale = {{emptyText: "No organisation unit to display"}}
                    className="demo-loadmore-list"
                    loading={false}
                    itemLayout="horizontal"
                    dataSource={this.state.selectedOus}
                    renderItem={ou => (
                      <List.Item>
                        <Skeleton avatar title={ou.ou_name} loading={false} active>
                          <List.Item.Meta
                            title={ou.ou_name}
                          />
                        </Skeleton>
                      </List.Item>
                    )}
                />
              </div>
            </div>
        </div>
          
      );
    }
}

const mapStateToProps = (state, props) => {
    return {
      ouList: state.getIn(['orgState', 'ouList'])
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return {
      setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudubonCertificationOU);