import { getApi, ConnectApi, putApi, postApi, ConnectAdminApi } from "../../../utils/api-utils";
import { message } from 'antd';

import { CONFIG } from '../../../config/app-config';
import { uploadFile } from '../../../utils/common-utils';

export const USER_OU_ROLES = {
  GENERAL : 1,
  OU_ADMIN_NODE : 2,
  OU_ADMIN_ROOT : 3
}

export async function getOrgInfo(orgId) {
  const result = await getApi(ConnectApi.ADMIN_API, `/${orgId}/basic_org_info`);
  return result.data;
}


export async function updateOrgInfo(orgId, orgInfo, logoFile) {
  const { orgPublicBucket } = CONFIG;
  await putApi(ConnectApi.ADMIN_API, `/${orgId}/basic_org_info`, orgInfo)
    .then((data) => {
      if (data.error) {
        message.error(`Problem updating details. Please retry.`);
      } else {
        message.success('Organization details updated');
      }
    })
    .catch((e) => {
      console.log(e);
      message.error(`Problem updating details. Please retry.`);
    })
  if (logoFile) {
    await uploadFile(logoFile, 'logo.jpg', orgPublicBucket, orgId).then(() => {
      message.success('Organization logo updated');
    })
      .catch((e) => {
        console.log(e);
        message.error(`Problem updating logo. Please retry.`);
      });
  }
}

export async function createOrgAndSysAdmin(orgInfo, userInfo, features, authInfo) {
  message.loading('Creating organisation, please wait...', 0);
  const { existingUser } = await getApi(ConnectAdminApi.USER_API, `/existing_user/${userInfo.username}`);
  if (existingUser) {
    await message.destroy();
    message.error('Username already exists, please enter a new username.')
    return false;
  }

  const createOrgResponse = await createOrg(orgInfo, authInfo);

  if (createOrgResponse) {
    const { orgId, ouId } = createOrgResponse;
    await message.destroy();
    message.success('Successfully created organisation');
    message.loading('Enabling features, please wait...', 0);
    await setOrgFeatures(orgId, features);
    await message.destroy();
    message.success('Successfully enabled features');
    const ouAdmin = {[ouId]: USER_OU_ROLES.OU_ADMIN_ROOT}
    userInfo.orgUnits.push(ouId);
    userInfo.orgId = orgId;
    userInfo.settings.oUAdmin = ouAdmin;
    message.loading('Creating admin user, please wait...', 0);
    const error = await createAdminUser(userInfo, orgId);
    await message.destroy();
    if (!error) {
      message.success('Successfully created admin user');
      return orgId;
    } else {
      message.error('Error creating user: ', error);
      return false;
    }
  }
}

export async function createOrg(orgInfo, authInfo) {
  try {
    const response = await postApi(ConnectAdminApi.ORG_API, '/new_org', { orgInfo, authInfo });
    return response;
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return false;
  }
}

export async function createAdminUser(userInfo, orgId) {
  const response = await postApi(ConnectApi.USER_API, `/${orgId}/users`, userInfo);
  if (response.error) {
    return response.error.message;
  }
}


export async function updateOrgStatus(orgId, status) {
  try {
    await putApi(ConnectAdminApi.ORG_API, `/status/${orgId}/${status}`);
    return true;
  } catch (err) {
    return false;
  }
}

export async function setOrgFeatures(orgId, features, newFeatures = []) {
  const response = await postApi(ConnectAdminApi.ORG_API, `/org_features/${orgId}`, { features, newFeatures });
  return response;
}

export async function disableOrgFeature(orgId, features, featureToRemove) {
  const response = await postApi(ConnectAdminApi.ORG_API, `/disable_org_feature/${orgId}/${featureToRemove}`, { features });
  if (response.error) {
    message.error('Error removing Organisation feature');
  }
  else{
    message.success('Feature removed successfully');
  }
  return response;
}

export const getSSOProviderStatus = async(orgId, provider) => {
  try {
    const response = await getApi(ConnectAdminApi.ORG_API, `/provider_status/${provider}`, {queryStringParameters:{orgId}});
    if (response.error) {
      message.error('Error getting provider status');
    }
    else  {
      return response.data;
    }
  }
  catch(err) {
    console.error(err);
    message.error('Error getting provider status');
  }
}

export const updateOrgCustomFeatures = async (orgId, customFeature) => {
  try {
    message.loading('Updating custom features..');
    await putApi(ConnectApi.ADMIN_API, `/${orgId}/update-custom-feature`, customFeature);
    message.destroy();
    message.success('Custom features updated');
    return true;
  }
  catch(err) {
    console.error(err);
    message.error('Error updating custom features');
    return false;
  }
}

export const saveNationalMapSite = async (orgId, nationalMapSite, images) => {
  try {
    message.loading('Saving national map site..');
    const { nationalMapBucket } = CONFIG;
    const { siteId } = await postApi(ConnectApi.NATIONAL_MAP_API, `/orgs/${orgId}/national-map-site`, nationalMapSite);
    await Promise.all(Object.keys(images).map(async imageName => {
      const image = images[imageName];
      if (image) {
        await uploadFile(image, `${imageName}.jpeg`, nationalMapBucket, siteId);
      }
    }));    
    message.destroy();
    message.success('National map site saved');
  }
  catch(err) {
    console.error(err);
    message.error('Error saving national map site');
  }
}

export const updateNationalMapSite = async (orgId, id, nationalMapSite, images, previousInstanceType = undefined) => {
  try {
    message.loading('Updating national map site..');
    const { nationalMapBucket } = CONFIG;
    const { siteId } = await putApi(ConnectApi.NATIONAL_MAP_API, `/orgs/${orgId}/national-map-site/${id}/update`, { site: nationalMapSite, previousInstanceType });
    await Promise.all(Object.keys(images).map(async imageName => {
      const image = images[imageName];
      if (image) {
        await uploadFile(image, `${imageName}.jpeg`, nationalMapBucket, siteId);
      }
    }));    
    message.destroy();
    message.success('National map site updated');
  }
  catch(err) {
    console.error(err);
    message.error('Error Updating national map site');
  }
}

export const removeNationalMapSite = async (orgId, siteId, instanceType) => {
  try {
    await putApi(ConnectApi.NATIONAL_MAP_API, `/orgs/${orgId}/national-map-site/${siteId}/remove`, { instanceType })
  }
  catch(err) {
    console.error(err);
    message.error('Error removing national map site');
  }
}

export const getOrgNationalMapDetails = async (orgId) => {
  try {
    return await getApi(ConnectApi.NATIONAL_MAP_API, `/orgs/${orgId}/national-map-sites`);
  }
  catch(err) {
    console.error(err);
    message.error('Error getting national map sites');
  }
}
