//@flow
import { Map } from 'immutable';
import { BREADCRUMB_ACTIONS } from './../actions/actionTypes';

const initialState = Map({
    title: '',
    path: null
});

export default function breadcrumbState(state = initialState, action) {
  switch(action.type){
    case BREADCRUMB_ACTIONS.SET_BREADCRUMB:
      return state.set("title", action.payload.title).set('path', action.payload.path);
    default: 
      return state;
  }
}