import { Map } from 'immutable';
import { DASHBOARD_ACTIONS } from '../actions/actionTypes';

const initialState = Map({
    orgUserStatusCounts: undefined
});

export default function dashboardState(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_ACTIONS.ORG_USER_STATUS_COUNTS:
            return state.set('orgUserStatusCounts', action.payload);
        default:
            return state;
    }
}  