import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CONFIG } from '../config/app-config';

mapboxgl.accessToken = CONFIG.mapboxglAccessToken;

const Map = ({
    createMode = false,
    viewMode = true,
    center = [150.82520658489005, -33.850000647657914], // Default Value: coordinates around Sydney
    zoom = 9,
    onCreate,
    onDelete,
    onUpdate = () => { },
    geometry
}) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: center,
            zoom: zoom
        });

        if (createMode) {
            draw.current = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                }
            });

            if (geometry) {
                const polygon = {
                    'type': 'Feature',
                    'geometry': geometry,
                    'properties': {}
                };
        
                map.current.on('load', function() {
                    draw.current.add(polygon);
                });
            }

            map.current.addControl(draw.current);

            map.current.on('draw.create', createArea);
            map.current.on('draw.delete', deleteArea);
            map.current.on('draw.update', updateArea);

            function createArea(e) {
                const data = draw.current.getAll();
                onCreate(data, e);
            }

            function deleteArea(e) {
                const data = draw.current.getAll();
                onDelete(data, e);
            }

            function updateArea(e) {
                const data = draw.current.getAll();                
                onUpdate(data, e);
            }
        }

        if (viewMode) {
            map.current.on('load', () => {
                map.current.addSource('maine', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': geometry
                    }
                });

                map.current.addLayer({
                    'id': 'maine',
                    'type': 'fill',
                    'source': 'maine',
                    'layout': {},
                    'paint': {
                        'fill-color': '#E27C03',
                        'fill-opacity': 0.5
                    }
                });

                map.current.addLayer({
                    'id': 'outline',
                    'type': 'line',
                    'source': 'maine',
                    'layout': {},
                    'paint': {
                        'line-color': '#9B6019',
                        'line-width': 3
                    }
                });
            });
        }

    }, []);

    return <div ref={mapContainer} style={{ width: '100%', height: '70vh' }} />;
};

export default Map;