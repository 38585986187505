import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Modal, Input, message, Upload, Icon, Select, TreeSelect } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { createReportTemplate, getTags } from '../actions/reportActions';
import { CONFIG } from '../../../config/app-config';
import { uploadFile, getFileLink } from '../../../utils/common-utils';

const { Option } = Select;

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const keyword='.keyword';
class NewReportModal extends Component {

    constructor(props) {
        super(props);
        console.log(props.reportDetails);
        this.state = {
            savingChanges: false,
            previewImage: '',
            img: null,
            fileList: [],
            uploadFileCount:0,
            previewVisible: false,
            addedTags: [],
            tagList: [],
            allFields: [],
            hiddenFields: null,
            filterableFieldsList: []
        }
    }

    buildFieldTrees = (hiddenFields) => {
        // if we changed the values inside the original one, it will be added to others as well.
        const propsHiddenFields = this.props.hiddenFields;
        const hiddens = {};
        const filterableFields = [];
        const tree = {
                prod : {
                "title": "Production",
                "value": "prod",
                "key": "prod",
                "disableCheckbox": true,
                "children": {},
            }, qa: {
                "title": "Quality Assurance",
                "value": "qa",
                "key": "qa",
                "disableCheckbox": true,
                "children": {}
            }, dev: {
                "title": "Development",
                "value": "dev",
                "key": "dev",
                "disableCheckbox": true,
                "children": {}
            }, other: {
                "title": "Other",
                "value": "other",
                "key": "other",
                "disableCheckbox": true,
                "children": {}
            }
        };

        Object.keys(propsHiddenFields).forEach(indexName => {
            hiddens[indexName] = [];
            const fields = propsHiddenFields[indexName];
            let stage = indexName.split('_')[0].toLocaleLowerCase();
            if(!tree[stage]){
                stage = "other";
            }

            if(!tree[stage][indexName]){
                tree[stage].children[indexName] = {
                    "title": indexName,
                    "value": indexName,
                    "key": indexName,
                    "disableCheckbox": true,
                    "children": []
                }
            }

            // filter .keyword
            fields.filter(field => !field.includes(keyword)).forEach(field => {
                if(hiddenFields[indexName] && !hiddenFields[indexName].includes(field)){
                    filterableFields.push(`${indexName}-${field}`);
                } else {
                    hiddens[indexName].push(field); // hidden fields.
                }
                tree[stage].children[indexName].children.push({
                    "title": field,
                    "value": indexName + "-" +field,
                    "key": indexName + "-" +field
                })
            });
        });

        tree.prod.children = Object.keys(tree.prod.children).map(name => tree.prod.children[name]);
        tree.qa.children = Object.keys(tree.qa.children).map(name => tree.qa.children[name]);
        tree.dev.children = Object.keys(tree.dev.children).map(name => tree.dev.children[name]);
        tree.other.children = Object.keys(tree.other.children).map(name => tree.other.children[name]);
        return {
            tree : Object.keys(tree).map(t => tree[t]),
            hiddenFields : hiddens,
            filterableFields : filterableFields
        }
    }

    componentDidMount = async () => {
        const { reportDetails } = this.props;
        let trees;
        if (reportDetails && reportDetails.length > 0) {
            const reportDetail = reportDetails[0];
            const imageUrl = await getFileLink(`${reportDetail.template_id}.jpg`, CONFIG.reportUploadBucket, 'main-images');
            trees = this.buildFieldTrees(reportDetails[0].hidden_fields || {});
            this.setState({
                fileList: [{ uid: '1', url: imageUrl }],
                uploadFileCount:1,
                filterableFieldsList: trees.filterableFields
            });
        } else {
            trees = this.buildFieldTrees({});
        }

        const tags = await getTags();

        this.setState({
            tagList: tags,
            allFields: trees.tree,
            hiddenFields: trees.hiddenFields
        });
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };


    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const removeFields = this.state.hiddenFields;
 
            var reportId = await createReportTemplate(this.props.dashboardId, this.props.userId, values.title, values.description, values.tags, removeFields);
            if (this.state.img) {
                message.loading('Uploading image...');
                await uploadFile(this.state.img, reportId + '.' + this.state.img.name.split('.').pop(), CONFIG.reportUploadBucket, 'main-images');
            }
            this.props.onSave();
        })
    }

    selectFile = async (file) => {
        this.setState({ img: file, uploadFileCount: 1 });
    }

    imageOnRemove = () => {
            this.setState({ uploadFileCount: 0 })
    }

    handlePreviewCancel = () => this.setState({ previewVisible: false });

    getTags() {
        const children = [];
        const tagList = this.state.tagList;
        for (let i = 0; i < tagList.length; i++) {
            children.push(<Option key={tagList[i].label}>{tagList[i].label}</Option>);
        }
        return children;
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    hiddenFieldsTreeOnChange = (values, label, extra) => {

        const prev = extra.preValue.map(v=> v.value);
        const added = values.filter(v => !prev.includes(v));
        const removed = prev.filter(v => !values.includes(v));
        const {filterableFieldsList, hiddenFields } = this.state;

        // filterable
        added.forEach(value => {
            const [indexName, field] = value.split('-');
            if(!filterableFieldsList.includes(value)){
                filterableFieldsList.push(value);
            }
            if(hiddenFields[indexName]){
                const idx = hiddenFields[indexName].indexOf(field);
                if(idx > -1){
                    hiddenFields[indexName].splice(idx, 1);
                }
            }
        });
        // non filterable
        removed.forEach(value => {
            const [indexName, field] = value.split('-');
            const fidx = filterableFieldsList.indexOf(value);
            if(fidx > -1){
                filterableFieldsList.splice(fidx, 1);
            }
            if(hiddenFields[indexName] && !hiddenFields[indexName].includes(field)){
                hiddenFields[indexName].push(field);
            }
        });

        this.setState({ hiddenFields, filterableFieldsList });
    }

    render() {

        const { form, reportDetails } = this.props;
        const { getFieldDecorator } = form;

        let isEditMode = false;

        const addedTagChildren = [];
        if (reportDetails && reportDetails.length > 0) {
            isEditMode = true;
            const reportDetail = reportDetails[0];
            for (let i = 0; i < reportDetail.tags.length; i++) {
                const tag = reportDetail.tags[i];
                const val = tag.label;
                addedTagChildren.push(val);
            }
        }

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const treeData = this.state.allFields;
        const tProps = {
            treeData,
            showCheckedStrategy: TreeSelect.SHOW_PARENT,
            treeCheckable: true,
            searchPlaceholder: 'Please select',
            style: {
                width: 300,
            },
            multiple: true,
            showSearch: true,
            onChange: this.hiddenFieldsTreeOnChange,
            treeDefaultExpandedKeys: this.state.filterableFieldsList
        }

        return (
            <Modal
                title={isEditMode ? "Edit Report" : "New Report"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Title"}>
                        {
                            getFieldDecorator('title', { rules: [{ required: true, message: 'Please enter title' }], initialValue: isEditMode ? reportDetails[0].title : '' })(
                                <Input maxLength={100} placeholder={"Enter Title"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Description"}>
                        {
                            getFieldDecorator('description', { initialValue: isEditMode ? reportDetails[0].description : '' })(
                                <TextArea rows={3} maxLength={100} placeholder={"Enter description"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Tags"}>
                        {getFieldDecorator('tags', {
                            initialValue: addedTagChildren
                        })(
                            <Select mode="tags" style={{ width: '100%' }}>
                                {this.getTags()}
                            </Select>
                        )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Filterable Fields"}>
                        {getFieldDecorator('removed', {
                            initialValue: this.state.filterableFieldsList
                        })(
                            <TreeSelect {...tProps} />
                        )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Image"}>
                        {getFieldDecorator('upload', {
                            valuePropName: "fileList",
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.fileList,
                            rules: [{ required: true }]
                        })(
                            <Upload
                                action={this.selectFile}
                                listType="picture-card"
                                onPreview={this.handlePreview}
                                accept=".jpg"
                                onRemove={this.imageOnRemove}
                            >
                                {this.state.uploadFileCount >= 1 ? null : uploadButton}
                            </Upload>
                        )}
                    </FormItem>
                </Form>
                <Modal title={'Preview'} visible={this.state.previewVisible} footer={null} onCancel={this.handlePreviewCancel} cancelText={"Cancel"} destroyOnClose={true}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewReportModal));