import React, { Component } from 'react';
import { Tree } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadOUList } from '../actions/commonActions';
import ConnectLoading from './ConnectLoading';
import { sortOuList } from '../utils/common-utils';


const TreeNode = Tree.TreeNode;

class OrganisationTreeView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ouList: [],
    };
  }

  componentDidMount = async () => {
    await this.props.loadOUList();
    let orgTreeNodes;
    let ouArray = [];
    await this.props.assignedOuList.map(item => {
      const check = ouArray.find(unit => unit.org_unit_id === item.org_unit_id);
      if(!check) {
        orgTreeNodes = this.props.ouList.filter(ou => item.org_id === ou.org_id);
        ouArray.push(...orgTreeNodes);
      }
    })
    const ous =ouArray.map(ou => {
      return {
        title: ou.ou_name,
        value: ou.org_unit_id,
        key: ou.org_unit_id,
        children: [],
        parent: ou.parent_org_unit_id,
        countryCode: ou.country_code,
        isDisabled: this.props.assignedOuList.find(item => item.org_unit_id === ou.org_unit_id),
      }
    });
    ous.forEach(ou => { 
      if (ou.parent) {
        const parentOu = ous.find(item => item.key === ou.parent);
        if(parentOu) {
          parentOu.children.push(ou);
        }
      }
    });
    const ouList = ous.filter(item => !item.parent);
    this.setState({ ouList });
  }

  renderTreeNodes = (data) => {
    const items = data.slice();
    return sortOuList(items).map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} value={item.value} disabled={!item.isDisabled}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });
  }

  render() {
    if(!this.props.ouList) {
      return <ConnectLoading />
    }
    return (
        <Tree>
            {this.renderTreeNodes(this.state.ouList)}
        </Tree>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    ouList: state.getIn(['orgState', 'ouList'])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadOUList: bindActionCreators(loadOUList, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationTreeView);