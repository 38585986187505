import { DatePicker, Form, Input, Modal, message } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addNewEHSTip } from '../actions/eshTipActions';
import { uploadFile } from '../../../utils/common-utils';
import { Editor } from '@tinymce/tinymce-react';
import { CONFIG } from '../../../config/app-config';
import uuidv4 from 'uuid/v4'; 

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class NewTipModal extends Component {

    handleEditorChange = (e) => {
        console.log('Content was updated:', e.target.getContent());
    }

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false,
            slide: [],
            content: null,
            contentUrl: {},
            currentImg: undefined
        }
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const tip_id = uuidv4();
        const { form } = this.props;
        
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            if(this.state.content == null){
                message.warning('Please type EHS Tip');
                return;
            }
            
            let code= this.state.content;
            let contentUrl = this.state.contentUrl;
            message.loading('EHS Tip is creating. Please wait..', 0);
            const slide_images=[];
            const bucket = this.props.ehsSlideBucket;
            
            for(let img in contentUrl) {
                var isExist = code.includes('id="'+img+'"');
                if(isExist===true){
                    // Upload Image to S3
                    await uploadFile(contentUrl[img].blob(), contentUrl[img].name()+'.jpg', bucket,`${tip_id}`, contentUrl[img].blob().type);
                    const imageName = contentUrl[img].name();
                    slide_images.push(imageName+".jpg");
                }
            }
            const loggedInUser = this.props.userId;
            const publishDate = moment(values.date).format("YYYY-MM-DD");
            const tip_info ={
                tipId : tip_id,
                title : values.title,
                publishDate : publishDate, 
                createdBy : loggedInUser,
                content : this.state.content,
                slideImages : slide_images
            }

            await addNewEHSTip(tip_info);
            message.destroy();
            this.props.form.resetFields();
            this.props.onCancel();
            message.success('EHS Tip is successfully created.', 5);
        });
    }

    handleEditorChange = (content, editor) => {
        this.setState({ content: content.target.contentDocument.body.innerHTML});
    }

    onChange = (slide) =>{
        this.setState({slide});
      };
    
    textEditor = () => {
        const _this = this;
        return (
            <Editor
              apiKey={CONFIG.tinyMceApiKey}
              initialValue="<p>Type EHS Tip here</p>"
              init={{
                // define selector
                media_alt_source: true,
                images_upload_base_path: '/some/basepath',
                file_picker_types: 'file image media',
                    
                    setup: function(editor){            //Add Id for image
                        editor.on('NodeChange', function (e) {
                            if(e.element.tagName === "IMG"){          
                            e.element.setAttribute("id", _this.state.currentImg);
                            }
                        })
                    },
                    
                    images_upload_handler: async function (blobInfo, success, failure, progress) {
                    _this.setState({
                        contentUrl: {                   // All selected images
                            ..._this.state.contentUrl,    
                            [blobInfo.name()]: blobInfo        
                        },
                        currentImg: blobInfo.name()     // last selected image
                        });
                    success(URL.createObjectURL(blobInfo.blob()));
                    },
                    
                automatic_uploads: false,
                height: 500,
                plugins: 'image table',          // define plugins and toolbars for tinymce editor
                toolbar: 'undo redo | formatselect |image code | bold italic forecolor backcolor | table | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat '
              }}
              onChange={this.handleEditorChange}
            />
        );
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            
            <Modal
                width='800px'
                style={{ top: 20 }}
                bodyStyle={{height:'100%'}}
                title={"New EHS Tip"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Title"}>
                        {
                            getFieldDecorator('title', { rules: [{ required: true, message: 'Please Enter EHS Tip Title' }] })(
                                <Input maxLength={100} placeholder={"Enter EHS Tip Title"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Date"}>
                        {
                            getFieldDecorator('date', { rules: [{ required: true, message: 'Please Enter EHS Tip Publish Date' }] })(

                                <DatePicker
                                    format="YYYY-MM-DD"
                                    disabledDate={current => {
                                        return current < moment().subtract(1, "day");
                                    }}
                                />
                            )
                        }
                    </FormItem>
                    <FormItem>
                        {this.textEditor()}
                    </FormItem>

                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
        ehsSlideBucket: state.getIn(['appConfigState', 'ehsSlideBucket']), 
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewTipModal));
