import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoginPage from './views/login/containers/LoginPage';
import { continueSession } from './views/login/actions/loginActions';
import MainDashboardPage from './views/dashboard/containers/MainDashboardPage';
import ConnectLoading from './components/ConnectLoading';



class App extends Component {
  constructor(props) {
    super(props);
    this.props.continueSession();
  }

  render() {
    if (this.props.isLoading) {
      return <ConnectLoading/>;
    }
    const isAuthenticated = this.props.isAuthenticated;
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          {isAuthenticated && <Route render={() => <MainDashboardPage />} />}
          {!isAuthenticated && <Route exact path='/login' render={() => <LoginPage />} />}
          <Route render={() => isAuthenticated ? <Redirect to="/" /> : <Redirect from='/' to="/login" />} />
        </Switch>
      </BrowserRouter>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    isAuthenticated: state.getIn(['loginUserState', 'isAuthenticated']),
    isLoading: state.getIn(['loginUserState', 'isLoading'])
  }
}

function mapDispatchToProps(dispatch) {
  return {
    continueSession: bindActionCreators(continueSession, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
