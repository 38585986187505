import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Tabs, List, Button, message, Collapse } from 'antd';
import { withRouter } from 'react-router-dom';

import { getTemplate, editTemplate } from '../action/CertificationAction';
import { setBreadcrumb } from '../../../actions/commonActions';
import InspectionTemplateForm from '../components/InspectionTemplateForm';
import ConnectLoading from '../../../components/ConnectLoading';
import DetailsTable from '../../../components/DetailsTable';
import { AUDUBON_CERTIFICATION_TYPES } from '../Constants';
import _ from 'lodash';

const TabPane = Tabs.TabPane;
const { Panel } = Collapse;
const QUESTIONS_CATEGORY = 'withCategories';

class AudubonInspectionDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            template: undefined,
            editDetailsFormVisible: false,
            editQuestionsFormVisible: false
        };
    }

    componentDidMount = () => {
        this.loadTemplate();
    }

    loadTemplate = async () => {
        const template = await getTemplate(this.props.templateId);
        this.setState({ template });

        this.props.setBreadcrumb(template.title, [
            { route: '/audubon/audubon_inspections', label: 'Audubon Certifications' },
            { route: '', label: template.title },
        ]);

    }

    handleEditDetails = () => {
        this.setState({
            editDetailsFormVisible: true
        });
    };

    handleEditQuestions = () => {
        this.setState({
            editQuestionsFormVisible: true
        });
    };

    updateTemplate = async (template) => {
        message.loading('Updating template...', 0);
        await editTemplate(template, this.props.templateId);
        message.destroy();
        message.success('Template updated successfully');

        this.setState({ template: {} });
        await this.loadTemplate();
    }

    renderDetails = () => {
        const { title, templateType, isPreliminary } = this.state.template;

        const fields = [
            { key: "Certification Title", value: title },
            { key: "Certification Type", value: AUDUBON_CERTIFICATION_TYPES[templateType] },
            { key: "Is Preliminary Template", value: isPreliminary && isPreliminary === 1 ? 'True' : 'False' }
        ];
        return (
            <div className="pure-g">
                <InspectionTemplateForm
                    visible={this.state.editDetailsFormVisible}
                    mode={'edit_details'}
                    template={this.state.template}
                    callback={async (data) => {
                        this.setState({ editDetailsFormVisible: false });
                        if (data) {
                            await this.updateTemplate(data);
                        }
                    }}
                />
                <div className="pure-u-1">
                    <div className="pure-g" >
                        <div className="pure-u-1">
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.handleEditDetails()}>Edit
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DetailsTable dataSource={fields} />
                    </div>
                </div>
            </div>
        );
    };

    renderQuestionList = (questions) => {
        return (
            <div className="pure-g">
                <InspectionTemplateForm
                    visible={this.state.editQuestionsFormVisible}
                    mode={'edit_questions'}
                    template={this.state.template}
                    callback={async (data) => {
                        this.setState({ editQuestionsFormVisible: false });
                        if (data) {
                            await this.updateTemplate(data);
                        }
                    }}
                />
                <div className="pure-u-1">
                    <div>
                        <List
                            className='epar__details__table'
                            size="small"
                            dataSource={questions}
                            renderItem={question => (
                                <List.Item>
                                    <div className='pure-g epar__details__table--item'>
                                        <div className='pure-u-md-1-24 pure-u-1'>{question.questionId}</div>
                                        <div className='pure-u-md-23-24 pure-u-1'>{question.question}</div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>

        );
    };

    questions = (template) => {
        if(template && template.questionsType && template.questionsType === QUESTIONS_CATEGORY) {
            return (
              <Collapse accordion defaultActiveKey={'0'}>
              { template.questions.map((list, index) => (
                  <Panel header={<span>{list.category}</span>} key={index}>
                    {list.questions && this.renderQuestionList(list.questions)}
                  </Panel>
                ))}
            </Collapse>
            )        
        } else if(template.questions){
        return (
            this.renderQuestionList(template.questions)
        )
        }
    }

    render() {
        if (!this.state.template) {
            return <ConnectLoading />;
        }
        return (
            <div>
                <Tabs className='epar__details__view'>
                    <TabPane tab="Details" key="1">
                        {!_.isEmpty(this.state.template) 
                            ? this.renderDetails()
                            : <ConnectLoading />}
                    </TabPane>
                    <TabPane tab="Question List" key="2">
                        <div className="pure-u-1" style={{marginBottom: 10}}>
                            <div className="pure-g" >
                                <div className="pure-u-1">
                                    <Button
                                        className="epar__tasks__action--button"
                                        type='primary' onClick={() => this.handleEditQuestions()}>Edit Questions
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {!_.isEmpty(this.state.template) 
                            ? this.questions(this.state.template)
                            : <ConnectLoading />}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"])
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudubonInspectionDetailView));