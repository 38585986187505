import { Map } from 'immutable';
import { EHS_ACTION } from '../actions/actionTypes';

const initialState = Map({
    ehsOrganisations: null
});

function sortByName(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

export default function ehsState(state = initialState, action) {
    switch (action.type) {
        case EHS_ACTION.EHS_ORGS:            
            return state.set('ehsOrganisations', action.payload.sort(sortByName));
        default:
            return state;
    }
}  