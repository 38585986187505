import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Tabs, List, Modal, Select } from 'antd';
import { withRouter } from 'react-router-dom';

import { getTemplate, editTemplate, addTemplateToOrg } from '../actions/inspectionActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import InspectionTemplateForm from './InspectionTemplateForm';
import ConnectLoading from '../../../components/ConnectLoading';
import DetailsTable from '../../../components/DetailsTable';
import { getOrgList } from '../../../actions/commonActions';

import { INSPECTION_CATEGORIES, INSPECTION_FREQUENCIES } from '../constants';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

class InspectionTemplateView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            template: undefined,
            editDetailsFormVisible: false,
            editQuestionsFormVisible: false,
            orgModalVisible: false,
            selectedOrgs: []
        };
    }

    componentDidMount = () => {
        this.loadTemplate();
        this.props.getOrgList();
    }

    loadTemplate = async () => {
        const template = await getTemplate(this.props.templateId);
        this.setState({ template });

        this.props.setBreadcrumb(template.title, [
            { route: '/inspections', label: 'Inspections' },
            { route: '', label: template.title },
        ]);

    }

    handleEditDetails = () => {
        this.setState({
            editDetailsFormVisible: true
        });
    };

    handleEditQuestions = () => {
        this.setState({
            editQuestionsFormVisible: true
        });
    };

    updateTemplate = async (template) => {
        message.loading('Updating template...', 0);
        await editTemplate(template, this.props.userId, this.props.templateId);
        message.destroy();
        message.success('Template updated successfully');
        this.loadTemplate();
    }

    onAddToOrgs = async() => {
        message.loading('Adding template...', 0);
        await Promise.all(this.state.selectedOrgs.map(async org=>await addTemplateToOrg(this.state.template, this.props.userId, org.key)));
        this.setState({orgModalVisible:false});
        message.destroy();
        message.success('Template added successfully');

    }

    renderDetails = () => {
        const title = this.state.template.title;
        const category = INSPECTION_CATEGORIES[this.state.template.category];
        const frequency = INSPECTION_FREQUENCIES[this.state.template.frequency];

        const fields = [
            { key: "Inspection Title", value: title },
            { key: "Category", value: category },
            { key: "Frequency", value: frequency }
        ];
        return (
            <div className="pure-g">
                <InspectionTemplateForm
                    visible={this.state.editDetailsFormVisible}
                    mode={'edit_details'}
                    template={this.state.template}
                    callback={(data) => {
                        this.setState({ editDetailsFormVisible: false });
                        if (data) {
                            this.updateTemplate(data);
                        }
                    }}
                />
                <div className="pure-u-1">
                    <div className="pure-g" >
                        <div className="pure-u-1">
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.handleEditDetails()}>Edit Details</Button>
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.setState({ orgModalVisible: true })}>Add to organisation(s)</Button>
                        </div>
                    </div>
                    <div>
                        <DetailsTable dataSource={fields} />
                    </div>
                </div>
            </div>
        );
    };

    renderQuestionList = () => {
        return (
            <div className="pure-g">
                <InspectionTemplateForm
                    visible={this.state.editQuestionsFormVisible}
                    mode={'edit_questions'}
                    template={this.state.template}
                    callback={(data) => {
                        this.setState({ editQuestionsFormVisible: false });
                        if (data) {
                            this.updateTemplate(data);
                        }
                    }}
                />
                <div className="pure-u-1">
                    <div className="pure-g" >
                        <div className="pure-u-1">
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.handleEditQuestions()}>Edit Questions</Button>
                        </div>
                    </div>
                    <div>
                        <List
                            className='epar__details__table'
                            size="small"
                            dataSource={this.state.template.questions}
                            renderItem={question => (
                                <List.Item>
                                    <div className='pure-g epar__details__table--item'>
                                        <div className='pure-u-md-1-24 pure-u-1'>{question.questionId}</div>
                                        <div className='pure-u-md-23-24 pure-u-1'>{question.question}</div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>

        );
    };

    renderOrgModal = () => {
        return (
            <Modal
                title="Add to Organisation(s)"
                visible={this.state.orgModalVisible}
                onOk={this.onAddToOrgs}
                onCancel={() => this.setState({ selectedOrgs: [], orgModalVisible:false })}
            >
                <Select mode="multiple" style={{ width: '100%' }} onChange={(value, selectedOrgs)=>this.setState({selectedOrgs})}>
                    {this.props.orgList.map(org => <Option key={org.org_id} value={`${org.name} - ${org.org_code}`}>{org.name}</Option>)}
                </Select>
            </Modal>
        )
    }

    render() {
        if (!this.state.template) {
            return <ConnectLoading />;
        }
        return (
            <div>
                {this.state.orgModalVisible && this.renderOrgModal()}
                <Tabs className='epar__details__view'>
                    <TabPane tab="Details" key="1">
                        {this.renderDetails()}
                    </TabPane>
                    <TabPane tab="Question List" key="2">
                        {this.renderQuestionList()}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
        orgList: state.getIn(['orgState', 'orgList']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionTemplateView));
