// @flow
import React, { Component } from 'react';
import { Menu, Layout, Button, Icon } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleSidePanel } from '../actions/commonActions';
import { isMobile } from '../utils/common-utils';

const { Sider } = Layout;

class SidePanel extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.props.toggleSidePanel(!this.props.collapsed);
  }

  logo = () => {
    if (this.props.collapsed) {
      return (
        <div className="logo epar__logo">
          <img src='/assets/epar-connect-icon.png' alt='' />
        </div>
      );
    }
    else {
      return (
        <div className="logo epar__logo">
          <a href="# ">
            <img src='/assets/epar-connect-icon.png' alt='' />
            <div className='epar__logo__label'>&nbsp;&nbsp;Connect Admin&nbsp;&nbsp;</div>
          </a>
        </div>);
    }
  }

  renderMobileSidePanel = () => {

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        collapsedWidth={0}
        width={212}
        onCollapse={this.toggle}
        className="epar__sider">

        <div className="pure-g">
          <div className="pure-u-1">
            {this.logo()}
          </div>
        </div>
        <Menu
          defaultOpenKeys={['organisations']}
          defaultSelectedKeys={['dashboard']}
          selectedKeys={this.props.selectedMenus}
          theme="dark"
          mode="inline"
          onClick={this.props.handleMenuClick}
          className='epar__side-menu'>
          {this.props.children}
        </Menu>
      </Sider>
    )
  }

  renderDesktopSidePanel = () => {
    return (
      <Sider
        // trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        width={212}
        onCollapse={this.toggle}
        className="epar__sider">

        <div className="pure-g">
          <div className="pure-u-1">
            {this.logo()}
            <Button className='epar__header__sidebar-collapse--button' onClick={this.toggle}>
              <Icon type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </div>
        </div>
        <Menu
          defaultOpenKeys={['organisations']}
          defaultSelectedKeys={['dashboard']}
          selectedKeys={this.props.selectedMenus}
          theme="dark"
          mode="inline"
          onClick={this.props.handleMenuClick}
          className='epar__side-menu'>
          {this.props.children}
        </Menu>
      </Sider>
    )
  }

  render() {
    if (isMobile()) {
      return this.renderMobileSidePanel();
    }
    else {
      return this.renderDesktopSidePanel();
    }
  }
}

function mapStateToProps(state, props) {
  return {
    collapsed: state.getIn(['appConfigState', 'sidePanelToggle']),
    selectedMenus: state.getIn(['appConfigState', 'selectedMenus'])
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidePanel: bindActionCreators(toggleSidePanel, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);