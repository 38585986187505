import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import { Provider } from 'react-redux';
import store from './reducers/store'
import App from './App';
import * as serviceWorker from './serviceWorker';

import { awsConfigInitialise, awsAmplifyApiInit } from './utils/aws-configure';

awsConfigInitialise(); // Initialise AWS
awsAmplifyApiInit();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
