import { combineReducers } from 'redux-immutable';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import loginUserState from '../views/login/reducers/loginUserState';
import appConfigState from './appConfigState';
import breadcrumbState from './breadcrumbState';
import orgState from './orgState';
import chemicalState from '../views/chemicals/reducer/chemicalState';
import equipmentState from '../views/equipment/reducer/equipmentState';
import inspectionState from '../views/inspection/reducer/inspectionState';
import courseState from '../views/course/reducer/courseState';
import dashboardState from '../views/dashboard/reducer/dashboardState';
import ehsState from '../views/ehs-tips/reducers/ehsState';
import jobPlannerState from '../views/jobPlanner/reducer/jobPlannerState';
import certificationState from '../views/external-certification/ruducer/certificationState';

import { LOGIN_ACTIONS } from '../views/login/actions/actionTypes';


const appReducer = combineReducers({
  ehsState,
  loginUserState,
  appConfigState,
  chemicalState,
  breadcrumbState,
  equipmentState,
  inspectionState,
  courseState,
  orgState,
  dashboardState,
  jobPlannerState,
  certificationState
});

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware // lets us dispatch() functions
  )
);


export default store;
