import { getApi, ConnectAdminApi, postApi, ConnectApi, putApi } from "../../../utils/api-utils";
import { message } from "antd";
import { CONFIG } from "../../../config/app-config";


export async function getUsers(queryStringParameters) {
  const result = await getApi(ConnectAdminApi.USER_API, `/users`, { queryStringParameters });
  return result.users;
}

export async function getUser(userName) { 
  const result = await getApi(ConnectAdminApi.USER_API, `/user`, { queryStringParameters: { userName } });
  return result;
}

export async function addUserToGroup(userName, groups) {
  message.loading('Adding user to the group...', 0);
  try {
    groups.forEach(async (group) => { 
      await putApi(ConnectAdminApi.USER_API, `/addToGroup`, { userName, group });
    });
    message.destroy();
    message.success('User added to the group.');
  }
  catch(err) {
    console.log(err);
    message.destroy();
    message.error("Error adding user into group.");
  }
  
}

export async function removeUserFromGroup(userName, group) {
  const result = await putApi(ConnectAdminApi.USER_API, `/removeFromGroup`, { userName, group });
  return result;
}

export async function removeTempLogin(orgId) {
  const response = await fetch(`${CONFIG.adminAPIEndPoint}/orgs/${orgId}/remove-temp-login-user`,{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
}

export function sendWelcomeEmail(email, firstName, lastName, username, orgId) {
  const name = firstName + ' ' + lastName;
  const path = `/${orgId}/email/welcome/${username}`
  const password = "Connect123";

  message.loading("Sending welcome email...", 0);
  postApi(ConnectApi.USER_API, path, { name, email, password }).then((resp) => {
    if (resp.error) {
      throw resp.error;
    }
    message.destroy();
    message.success("Welcome email sent");
  })
    .catch((e) => {
      console.log(e);
      message.destroy();
      message.error("Problem sending welcome email.");
    });
}


export async function activateUser(userId, userName, orgId) {
  try {
    message.loading("Activating user...", 0);
    let response = await putApi(ConnectApi.USER_API, `/${orgId}/activate_user/${userId}/${userName}`, {}); 
    if (response.error) {
      message.destroy();
      message.error("Error activating user");
    }
    else {
      message.destroy();
      message.success("User activated successfully, please refesh the page");
    }
  }
  catch (err) {
    console.log(err);
    message.error("Error activating user");
  }
}

export async function resetPassword(userName, forceChangePassword) {
  message.loading("Resetting user password...", 0)
  try{
    await putApi(ConnectAdminApi.USER_API, `/reset_password`,  { userName, forceChangePassword } );
    message.destroy();
    message.success("Password reset successfull");
  }catch(err){
    message.destroy();
    message.error("Password reset unsuccessfull");
  }
}

export async function verifyEmail(userName) {
  message.loading("Verifying user email...", 0)
  try{
    await putApi(ConnectAdminApi.USER_API, `/verify_email`,  { userName } );
    message.destroy();
    message.success("Email verified successfully, please refesh the page");
  }catch(err){
    message.destroy();
    message.error("Email verifying unsuccessfull");
  }
}

export async function addQRLoginUser(orgId, token, userId, displayMessages = true) {
  if(displayMessages) message.loading("Creating QR code...",0);
  try {
    const response = await fetch(`${CONFIG.adminAPIEndPoint}/orgs/${orgId}/add-qr-login-user`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token , userId}),
    });
    const responseData = await response.json();

    message.destroy();
    if(displayMessages) message.success("QR code created successfully. Please download the QR code.");
    return {
      status: "ok",
      tokenId: responseData.tokenId
    }
  } catch (error) {
    console.log(error);
    message.destroy("QR create unsuccefull");
    return {
      status: "false",
    }
  }
}

export async function checkQRLoginUser(userId) {
  try {
    const response = await fetch(`${CONFIG.adminAPIEndPoint}/check-qr-login-user`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
    const responseData = await response.json();

    return responseData;

  } catch (error) {
    console.log(error)
  }
}

export async function resetQRLoginUser(userId, displayMessages = true) {
  message.destroy();
  if(displayMessages) message.loading("Removing QR code...",0);
  try {
    const response = await fetch(`${CONFIG.adminAPIEndPoint}/reset-qr-login-user`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
    const responseData = await response.json();
    message.destroy();
    if(displayMessages) message.success("QR code removed successfully.");

    return responseData.message;

  } catch (error) {
    console.log(error)
    message.error("QR code reset unsuccessfull");
  }
}

export const getOrgUsers = async(orgId) => { 
  try {
    const result = await getApi(ConnectAdminApi.USER_API, `/org-users` , { queryStringParameters: {orgId} })
    return result;
  }
  catch (err) {
    console.log(err);
  }
}

export async function sendUserUploadRequests(orgId, users) {
  message.loading('Sending user upload requests, please wait...', 0);
  try{
    await postApi(ConnectApi.ADMIN_API, `/${orgId}/upload-users`, { usersData: users });
    message.destroy();
    message.success(`User upload requests sent successfully.`);
  }catch(err){
    message.destroy();
    message.error("Error sending user upload requests!");
  }
}

export async function checkExistUsers(users){
  try {
    const result = await postApi(ConnectAdminApi.USER_API, `/check-exist-users`, users);
    return result;
  } catch(err) {
    console.log(err);
  }
}