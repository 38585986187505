// @flow

import { APP_ACTIONS, BREADCRUMB_ACTIONS, ORG_ACTIONS } from './actionTypes';
import { getApi, ConnectAdminApi, putApi, ConnectApi } from '../utils/api-utils';
import { message } from 'antd';

export function toggleSidePanel(toggle) {
  return function (dispatch, getState) {
    dispatch({
      type: APP_ACTIONS.TOGGLE_SIDEPANEL,
      payload: toggle
    })
  }
}

export function setSelectedMenu(menus) {
  return function (dispatch, getState) {
    dispatch({
      type: APP_ACTIONS.SET_SELECTED_MENU,
      payload: menus
    })
  }
}

export function setBreadcrumb(title, path) {
  return function (dispatch, getState) {
    dispatch({
      type: BREADCRUMB_ACTIONS.SET_BREADCRUMB,
      payload: {
        title: title,
        path: path
      }
    })
  }
}

export function getOrgList(force=false) {
  return async function (dispatch, getState) {
    try {
      if(force || !getState().getIn(['orgState', 'orgList'])){
        const result = await getApi(ConnectAdminApi.COMMON_API, '/org_list');
        await dispatch({
          type: ORG_ACTIONS.ORG_LIST,
          payload: result.orgList
        })
      }
    } catch (e) {
      console.log(e);
      message.error("Error in getting org list.");
    }
  }
}

export function loadOUList(force=false) {
  return async function (dispatch, getState) {
    try {
      if(force || !getState().getIn(['orgState', 'ouList'])){
        const result = await getApi(ConnectAdminApi.COMMON_API, '/ou_list');
        await dispatch({
          type: ORG_ACTIONS.OU_LIST,
          payload: result.ouList
        })
      }
    } catch (e) {
      console.log(e);
      message.error("Error in getting organisation units");
    }
  }
}

export function loadOrgOUList(orgId) {
  return async function (dispatch, getState) {
    try {
      const result = await getApi(ConnectAdminApi.COMMON_API, `/org_ou_list/${orgId}`);
      await dispatch({
        type: ORG_ACTIONS.OU_LIST,
        payload: result.ouList
      })
    } catch (e) {
      console.log(e);
      message.error("Error in getting organisation units");
    }
  }
}

export async function loadOrgAudubonOUList(orgId) {
  try {
      const result = await getApi(ConnectAdminApi.COMMON_API, `/audubon_org_units/${orgId}`);
      return result.data;
  } catch (e) {
      console.log(e);
      message.error("Error in getting Audubon organisation units");
  }
}

export async function setAudubonOrgUnits(orgId, ouIds) {
  try {
      const result = await putApi(ConnectAdminApi.COMMON_API, `/audubon_org_units/${orgId}`, { ouIds });
      message.success("Audubon certification assigned successfully");
      return result;
  } catch (e) {
      console.log(e);
      message.error("Error in setting Audubon organisation units");
  }
}

export async function getAlluserGroups(orgId) {
  try {
    const path = `/${orgId}/permgroups`;
    const result = await getApi(ConnectApi.ADMIN_API, path, {queryStringParameters:{selectedOrgId:orgId}});
    return result;
  } catch(err) {
    console.log(err);
  }
}