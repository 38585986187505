import { Map } from 'immutable';
import { INSPECTION_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildUserIndex(inspectionList) {
    return SearchUtils.buildIndex(inspectionList, 'templateid', ['title', 'category']);
};

const initialState = Map({
    inspectionList: undefined,
    inspectionIndex: buildUserIndex([])
});

export default function inspectionState(state = initialState, action) {
    switch (action.type) {
        case INSPECTION_ACTIONS.INSPECTION_LIST:
            return state.set('inspectionList', action.payload).set('inspectionIndex',
            buildUserIndex(action.payload));
        default:
            return state;
    }
}  