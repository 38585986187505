import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Modal, Select, Switch } from 'antd';
import {Redirect, withRouter} from 'react-router-dom';

import { loadEquipmentList, archiveEquipment, unArchiveEquipment } from '../actions/equipmentActions';
import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { EquipmentListItem } from '../EquipmentListItemBuilder';
import { setBreadcrumb } from '../../../actions/commonActions';
import NewEquipmentModal from './NewEquipmentModal';

const confirm = Modal.confirm;
const Option = Select.Option;

class EquipmentListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            selectedEquipments: new Set(),
            equipmentId: null
        };
        this.loadEquipmentList();

        this.props.setBreadcrumb("Equipment", [
            { route: '', label: 'Equipment' }
        ]);
    }

    loadEquipmentList = async () => {
        this.props.loadEquipmentList();
    }

    getEquipmentForListView = () => {
        let equipmentList = this.filterEquipment().map((equipment) => {
            let builder = new EquipmentListItem.Builder(equipment, this.onEquipmentSelection, this.state.selectedEquipments);
            return builder.build();
        });
        return equipmentList;
    }

    filterEquipment = () => {
        let filteredList = SearchUtils.filter(this.props.equipmentList ? this.props.equipmentList : [], "equipment_id", this.state.searchMatches);
        if(this.state.selectedCountry){
            filteredList = filteredList.filter(equipment=>equipment.country_code===this.state.selectedCountry)
        }
        filteredList = this.state.viewArchived ? filteredList.filter(equipment=>equipment.record_status==="0") : filteredList.filter(equipment=>equipment.record_status==="1")
        return filteredList;
    }

    filterSearchOptions() {
        const equipments = this.filterEquipment();
        const optionsMap = { title: "Equipment", children: [] };
        equipments.forEach((equipment) => {
            optionsMap.children.push({ title: equipment.name, id: equipment.equipment_id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.equipmentIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (chemicalId) => {
        this.setState({ equipmentId: chemicalId });
    }

    onEquipmentSelection = (equipmentId, checked) => {
        const selectedEquipments = this.state.selectedEquipments
        checked ? selectedEquipments.add(equipmentId) : selectedEquipments.delete(equipmentId);
        this.setState({ selectedEquipments });
    }


    archiveEquipments = async () => {
        message.loading('Archiving equipments', 0);
        const equipmentItr = this.state.selectedEquipments.values();
        for (let equipmentId of equipmentItr) {
            await archiveEquipment(equipmentId);
        }
        message.destroy();
        message.success('Equipments archived successfully');
        this.setState({ selectedEquipments: new Set() });
        this.loadEquipmentList();
    }

    unArchiveEquipments = async () => {
        message.loading('Unarchiving equipments', 0);
        const equipmentItr = this.state.selectedEquipments.values();
        for (let equipmentId of equipmentItr) {
            await unArchiveEquipment(equipmentId);
        }
        message.destroy();
        message.success('Equipments unarchived successfully');
        this.setState({ selectedEquipments: new Set() });
        this.loadEquipmentList();
    }

    onArchive = () => {
        confirm({
            title: 'Do you want to archive selected equipments?',
            onOk: () => {
                this.archiveEquipments();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    onUnArchive = () => {
        confirm({
            title: 'Do you want to unarchive selected equipments?',
            onOk: () => {
                this.unArchiveEquipments();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    goToSelectedEquipment() {
        if (this.state.equipmentId) {
            return (<Redirect to={`/equipment/equipment_info/${this.state.equipmentId}`} />);
        }
    }

    render() {
        return (
            <div>
                {this.goToSelectedEquipment()}
                {this.state.isNewEquipmentVisible &&
                    <NewEquipmentModal
                        visible={this.state.isNewEquipmentVisible}
                        onCancel={() => this.setState({ isNewEquipmentVisible: false })}
                        onSave={(equipmentId) => {
                            this.loadEquipmentList();
                            this.setState({ isNewEquipmentVisible: false });
                            this.props.history.push(`/equipment/equipment_info/${equipmentId}`);
                        }}
                    />
                }
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <Select style={{ marginLeft: "6px",  width: 120 }} placeholder="Country" onChange={selectedCountry => this.setState({ selectedCountry })}>
                            <Option value={undefined}>All</Option>
                            <Option value="AUS">Australia</Option>
                            <Option value="USA">United States</Option>
                        </Select>
                        <Switch style={{ marginLeft: "6px", verticalAlign: "baseline" }} unCheckedChildren="Archived" checkedChildren="Active" defaultChecked onChange={active=>this.setState({viewArchived:!active, selectedEquipments: new Set()})}/>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3">
                        <Button
                            className='epar__tasks__action--button'
                            onClick={() => this.setState({ isNewEquipmentVisible: true })}>
                            Add New Equipment
                        </Button>
                        <Button
                            className='epar__tasks__action--button'
                            onClick={this.state.viewArchived ? this.onUnArchive : this.onArchive}
                            disabled={this.state.selectedEquipments.size === 0}>
                            {this.state.viewArchived?'Unarchive':'Archive'}
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getEquipmentForListView()} loading={this.props.equipmentList === undefined} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        equipmentList: state.getIn(['equipmentState', 'equipmentList']),
        equipmentIndex: state.getIn(["equipmentState", "equipmentIndex"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadEquipmentList: bindActionCreators(loadEquipmentList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentListView));
