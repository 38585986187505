import { Modal, Button, Card, Table, Row, Col, AutoComplete } from 'antd';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ConnectLoading from '../../../components/ConnectLoading';
import { EHS_ORG_STATUS } from './../constants';
import { getEHSTipOrganisations, enableEHSTipOrgs, disableEHSTipOrgs } from '../../ehs-tips/actions/eshTipActions';

class EHSTipOrgSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organisations: null,
            ehsEnabledOrganisations: [],
            searchtext:  null
        }
        this.loadEparOrganisations();
    }

    filterOrganisaions = () => {
        let orgs;
        const refs = this.state.searchtext;
        if (!refs) {
            // initial setting
            orgs = this.props.ehsOrganisations;
        } 
        else {
            // hit by onSearch
            let ehsOrgs = this.props.ehsOrganisations;
            ehsOrgs = ehsOrgs.filter(org =>{
            const validOrg = org.name.toLowerCase().includes(this.state.searchtext.toLowerCase())
            if(validOrg){
                return true;
            }
            return false;
            });
            if (ehsOrgs) {
                orgs = ehsOrgs;
            }
        }
        return orgs;
    }

    loadEparOrganisations = async() => {
        await this.props.getEHSTipOrganisations();
    }

    onOrgEnable = async(org_id) => {
        await enableEHSTipOrgs([org_id]);
        this.loadEparOrganisations();
    }
    
    onOrgDisable = async(org_id) => {
        await disableEHSTipOrgs(org_id);
        this.loadEparOrganisations();
    }

    handleOrgDisable = async(org_id) => {
        const _this = this;

        Modal.confirm({
            title: 'Confirm',
            content: (
                <div>
                    <p>{`Are you sure you want to disable EHS Tips from the Organisation?`}</p>
                </div>
            ),
            onOk () {
                _this.onOrgDisable(org_id);
            },
            onCancel() {
                console.log('Cancel');
            },

        });
    }

    handleOrgEnable = async(org_id) => {
        const _this = this;

        Modal.confirm({
            title: 'Confirm',
            content: (
                <div>
                    <p>{`Are you sure you want to enable EHS Tips for the Organisation?`}</p>
                </div>
            ),
            onOk () {
                _this.onOrgEnable(org_id);
            },
            onCancel() {
                console.log('Cancel');
            },

        });
    }

    onChangeSelect = (ous) => {
        console.log(ous);
        this.setState({selectedOus: ous});
    }
    
    columns = [
        {
            title: 'Organisation',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            dataIndex: 'orgId',
            key: 'orgId',
            render: (ehsOrg, org) => {
                if(org.status === EHS_ORG_STATUS.ENABLED){
                    return <Button onClick={()=>this.handleOrgDisable(org.org_id)}>Disable</Button>
                }
                else {
                    return <Button onClick={()=>this.handleOrgEnable(org.org_id)}>Enable</Button>
                }
            }
        }
    ];

    render() {
        return (
            <Modal
                width='800px'
                style={{ top: 20 }}
                bodyStyle={{height:'100%'}}
                title={"Select Organisations"}
                visible={true}
                onCancel={()=>this.props.onCancel()}
                footer={null}
                destroyOnClose={true}
                okButtonProps={{ style: { display: 'none' } }}
            >
                { !this.props.ehsOrganisations ?
                    <ConnectLoading /> :
                    <div>
                        <Row>
                            <Col span={12}>
                                <AutoComplete
                                    style={{ width: '100%' }}
                                    placeholder={'Search organisation'}
                                    onSearch={searchtext => this.setState({ searchtext })}
                                />
                            </Col>
                        </Row>

                        <Card style = {{marginTop: '10px'}}>
                            <Table dataSource={this.filterOrganisaions()} columns={this.columns} />
                        </Card>
                    </div>
                }
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        ehsOrganisations: state.getIn(['ehsState', 'ehsOrganisations']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEHSTipOrganisations: bindActionCreators(getEHSTipOrganisations, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EHSTipOrgSelectModal));