import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { Button, Form, Card, Tag, message, List, Icon, Modal, Switch, Tabs  } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import EditOrgDetailsForm from './EditOrgDetailsForm';
import { getOrgInfo, updateOrgInfo, updateOrgStatus, disableOrgFeature, updateOrgCustomFeatures  } from '../actions/orgActions';
import { CONFIG } from '../../../config/app-config';
import { setBreadcrumb } from '../../../actions/commonActions';
import ConnectLoading from '../../../components/ConnectLoading';
import { CONNECT_FEATURES, CONNECT_FEATURE_NAMES, FEATURES_TO_OFF, CONNECT_CUSTOM_FEATURES, CONNECT_CUSTOM_FEATURES_STATUS } from '../constants';
import { AddFeatureModal } from './AddFeatureModal';
import SelectCertificationOrgUnits from './SelectCertificationOrgUnits';
import AudubonCertificationOU from './AudubonCertificationOU';
import { COMMON_DOC_TYPES } from '../../external-certification/Constants'
import NationalMapSiteDetails from './NationalMapSiteDetails';

const dateFormat = 'YYYY-MM-DD';
const FormItem = Form.Item;
const { TabPane } = Tabs;
const SSO_ENABLED = 1;

const OrgDetailsPageForm = (props) => {
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 15 },
  };
  const { s3HostUrl, orgPublicBucket } = CONFIG;
  const logoUrl = `${s3HostUrl}/${orgPublicBucket}/${props.orgInfo.org_id}/logo.jpg?${moment()}`
  
  return (
    <Form layout="horizontal">
      <FormItem label="Organisation Name" {...formItemLayout}>
        <span className="ant-form-text">{props.orgInfo.name}</span>
      </FormItem>
      <FormItem label="Default Timezone" {...formItemLayout}>
        <span className="ant-form-text">
          {props.orgInfo.timezone}
        </span>
      </FormItem>
      <FormItem label="User Limit" {...formItemLayout}>
        <span className="ant-form-text">
          {props.orgInfo.user_limit ? props.orgInfo.user_limit : ''}
        </span>
      </FormItem>
      <FormItem label="Expiry Date" {...formItemLayout}>
        <span className="ant-form-text">
          {props.orgInfo.expiry_date ? moment(props.orgInfo.expiry_date).format(dateFormat) : ''}
        </span>
      </FormItem>
      <FormItem label="Logo" {...formItemLayout}>
        <div className="logo"> <img src={logoUrl} width="200" alt='' /></div>
      </FormItem>
      <FormItem label="Status" {...formItemLayout}>
        {props.orgInfo.status === SSO_ENABLED ? <Tag color='green'>Active</Tag> : <Tag color='red'>inactive</Tag>}
      </FormItem>

      <FormItem label="Single Sign-On" {...formItemLayout}>
        {props.orgInfo.is_sso === SSO_ENABLED ? <Tag color='green'>Enabled</Tag> : <Tag color='red'>Disabled</Tag>}
      </FormItem>

      {props.orgInfo.is_sso === SSO_ENABLED && <FormItem label="Identity Provider Name" {...formItemLayout}>
        <span className="ant-form-text">{props.orgInfo.providerName}</span>
      </FormItem>}
      
      {props.orgInfo.features.includes(CONNECT_FEATURE_NAMES.CONTRACTORS) && <FormItem label="Kiosk Contractor" {...formItemLayout}>
        <span className="ant-form-text">{props.orgInfo.settings && props.orgInfo.settings.kioskUser ? `${props.orgInfo.settings.kioskUser.firstName} ${props.orgInfo.settings.kioskUser.lastName}` : '-'}</span>
      </FormItem>}
    </Form>
  );
};


class OrgDetailsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isButtonDisable: false,
      editOrgDetailsFormModalVisible: false,
      orgInfo: undefined,
      addFeaturesModalVisible: false,
      audubonOuSelectVisible: false,
      isAudubonEnabled: false
    }
    this.loadOrgInfo();
  }

  loadOrgInfo = async () => {
    const orgInfo = await getOrgInfo(this.props.orgId);
    const AudubonFeature = orgInfo.features.find(feature => feature === COMMON_DOC_TYPES.AUDUBON);
    this.setState({ orgInfo , isAudubonEnabled :AudubonFeature ? true : false });
    this.props.setBreadcrumb(orgInfo.name, [
      { route: '/organisations', label: 'Organisations' },
      { route: '', label: orgInfo.name }
    ]);
  }

  handleEditOrgDetailsButtonClick = () => {
    this.setState({
      editOrgDetailsFormModalVisible: true
    });
  };

  handleEditOrgDetailsFormClose = () => {
    this.setState({
      editOrgDetailsFormModalVisible: false
    });
  };

  handleEditOrgDetails = async (org, orgLogo) => {
    await updateOrgInfo(this.props.orgId, org, orgLogo);
    this.loadOrgInfo();
  };

  componentWillMount() {
  }

  getOrgDetailsView = () => {
    if (!this.state.orgInfo) {
      return <ConnectLoading />
    }

    return (
      <OrgDetailsPageForm
        orgInfo={this.state.orgInfo}
        orgLogo={this.props.orgLogo}
        ref={this.saveFormRef}
        visible={this.props.visible}
      />
    );

  };

  getOrgDetailsEditView = () => {
    if (this.state.orgInfo) {
      return (
        <EditOrgDetailsForm
          orgInfo={this.state.orgInfo}
          visible={this.state.editOrgDetailsFormModalVisible}
          callback={this.handleEditOrgDetailsFormClose}
          editOrgDetails={this.handleEditOrgDetails}
        />
      );
    }
    else {
      return null;
    }
  };

  updateOrgStatus = async (isActiveOrg) => {
    message.loading('Updating organisation status...', 0);
    const updated = await updateOrgStatus(this.props.orgId, isActiveOrg ? 0 : 1);
    message.destroy();
    if (updated) {
      message.success('Organisation status updated successfully.');
    } else {
      message.error('Error updating organisation status.');
    }
    this.loadOrgInfo();
  }

  handleOUDisable = (item) => {
    const _this = this;
    Modal.confirm({
      title: 'Confirm',
      content: (
        <div>
          <p>{`Are you sure you want to disable this feature from ${_this.state.orgInfo.name} Organisation?`}</p>
        </div>
      ),
      onOk () {
        _this.setState({isButtonDisable: true});
        _this.onOUDisable(item);
      },
      onCancel () {
        console.log('Cancel');
      }
    });
  }

  onOUDisable = async(item) => {
    const features = this.state.orgInfo.features.filter(i => i !== item);
    await disableOrgFeature(this.props.orgId, features, item);
    this.loadOrgInfo();
    this.setState({isButtonDisable: false});
  }

  getDetailsTab = () => {
    return (
      <div>
        <div className="pure-g epar__toolbar--row">
          <div className="pure-u-3-5">
          </div>
          <div className='pure-u-2-5'>
            {
              this.state.orgInfo &&
              <Button className='epar__tasks__action--button' type='danger' onClick={() => this.updateOrgStatus(this.state.orgInfo.status === 1)}>
                {this.state.orgInfo.status === 1 ? 'Deactivate' : 'Activate'}
              </Button>
            }
            <Button className='epar__tasks__action--button' type='primary' onClick={() => this.handleEditOrgDetailsButtonClick()}>
              Edit Details
          </Button>
          </div>
        </div>
        <div>
          {this.getOrgDetailsView()}
          {this.getOrgDetailsEditView()}
        </div>
      </div>
    );
  }

  onSaveFeature = (isAudubon) => {
    this.setState({addFeaturesModalVisible: false});
    this.setState({audubonOuSelectVisible: isAudubon});
    this.loadOrgInfo();
  }
  
  onSetAudubonOUs = () => {
    this.setState({audubonOuSelectVisible: false});
    this.loadOrgInfo();
  }

  renderSelectAudubonOUsModal = () => {
    if (this.state.addFeaturesModalVisible) {
      return (
        <AddFeatureModal
          visible={this.state.addFeaturesModalVisible}
          onCancel={()=>this.setState({addFeaturesModalVisible:false})}
          onSave={(isAudubon)=>this.onSaveFeature(isAudubon)}
          orgFeatures={this.state.orgInfo.features}
          orgId={this.props.orgId}
        />
      );
    }
    else {
      return null;
    }
  };

  renderAddFeatureModel = () => {
    if (this.state.audubonOuSelectVisible) {
      return (
        <SelectCertificationOrgUnits
          visible={this.state.addFeaturesModalVisible}
          onCancel={()=>this.setState({audubonOuSelectVisible: false})}
          onSave={()=>this.onSetAudubonOUs()}
          orgId={this.props.orgId}
        />
      );
    }
    else {
      return null;
    }
  };

  setDisableButton = (item) => {
    if(FEATURES_TO_OFF.find(el => el === item)) {
      return true;
    }
  }

  updateCustomFeatures = async (status, customFeature) => {
    const { orgId } = this.props;
    const { loadOrgInfo } = this;
    Modal.confirm({
      title: 'Confirm',
      content: (
          <div>
              <p>{`Are you sure you want to ${status ? 'enable' : 'disable'} this feature?`}</p>
          </div>
      ),
      async onOk () {
        const customFeatureInfo = {
          status: status ? CONNECT_CUSTOM_FEATURES_STATUS.ENABLE : CONNECT_CUSTOM_FEATURES_STATUS.DISABLE,
          feature: customFeature,
        }
        await updateOrgCustomFeatures(orgId, customFeatureInfo);
        await loadOrgInfo();
      },
      async onCancel() {
        console.log('Canceled');
      },
    });    
  }

  renderFeatureList = () => {
    if (!this.state.orgInfo) {
      return <ConnectLoading />
    }
    return <List
      itemLayout="horizontal"
      dataSource={this.state.orgInfo.features.sort()}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={<Icon type={CONNECT_FEATURES[item].icon} />}
            title={CONNECT_FEATURES[item].displayName}
          />
          {this.setDisableButton(item) &&
            <Button type="primary" shape="round" onClick = {()=>this.handleOUDisable(item)} disabled = {this.state.isButtonDisable}>
              Disable
            </Button>}
        </List.Item>
      )}
    />

  }

  getFeaturesTab = () => {
    return (
      <div>
        <div className="pure-g epar__toolbar--row">
          <div className="pure-u-3-5">
          </div>
          <div className='pure-u-2-5'>
            <Button className='epar__tasks__action--button' type='primary' onClick={() => this.setState({addFeaturesModalVisible:true})}>
              Add Feature
            </Button>
          </div>
        </div>
        <div>
          {this.renderFeatureList()}
          {this.renderAddFeatureModel()}
          {this.renderSelectAudubonOUsModal()}
        </div>
      </div>
    );
  }

  getNationalMapTab = () => {
    return (
      <NationalMapSiteDetails
        orgInfo={this.state.orgInfo}
        orgId={this.props.orgId}
      />
    );
  }

  getCustomFeaturesTab = () => {
    return (
      <div>
        <div>
          <List
            itemLayout="horizontal"
            dataSource={CONNECT_CUSTOM_FEATURES}
            renderItem={customFeature => (
              <List.Item>
                <List.Item.Meta
                  title={customFeature.displayName}
                />
                {<Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={this.state.orgInfo.orgCustomFeatures.length > 0 && this.state.orgInfo.orgCustomFeatures.includes(customFeature.key)}
                  onChange={(status) => this.updateCustomFeatures(status, customFeature.key)}
                />}
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }

  orgAudubonDetailsTab = () => {
    return (
      <div>
        <div>
          <AudubonCertificationOU 
            orgId = {this.props.orgId}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="epar-admin">
        <Card>
          <Tabs className='epar__details__view'>
            <TabPane tab="Details" key="1">
              {this.getDetailsTab()}
            </TabPane>
            <TabPane tab="Features" key="2">
              {this.getFeaturesTab()}
            </TabPane>
            <TabPane tab="National Map" key="3">
              {this.getNationalMapTab()}
            </TabPane>
            <TabPane tab="Custom Features" key="4">
              {this.getCustomFeaturesTab()}
            </TabPane>
            {this.state.isAudubonEnabled && 
            <TabPane tab="Audubon" key="5">
              {this.orgAudubonDetailsTab()}
            </TabPane>
            }
          </Tabs>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    orgLogo: '/assets/placeholder.png'
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgDetailsPage);
