import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Button, Tag, Tooltip } from 'antd';

const USER_STATUS = {
    ACTIVE: 0,
    INACTIVE: 1
}

export class UserListItem {
    static get Builder() {
        class Builder {
            constructor(user, onUserOptions) {
                this.user = user;
                this.onUserOptions = onUserOptions;
            }
            build() {
                const user = this.user;
                const tagColor = user.status === USER_STATUS.ACTIVE? 'green':'red';
                const tagText = user.status === USER_STATUS.ACTIVE ? 'Active':'Inactive';
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(`${user.first_name} ${user.last_name}`, `/users/user/${user.user_id}`)
                    .setDescription(
                        <div>
                            <Row>{`Username: ${user.username}`}</Row>
                            <Row>{`Email: ${user.email}`}</Row>
                            <Row>{`Organisation: ${user.org_name}`}</Row>
                        </div>
                    )
                    .setRightIcon(<span><Tag color={tagColor}>{tagText}</Tag><Tooltip title="Options"><Button onClick={()=>this.onUserOptions(this.user)} icon="setting" style={{height:'22px'}}/></Tooltip></span>)      
                return builder.build();
            }
        }
        return Builder;
    }
}
