import { getApi, ConnectAdminApi, postApi, ConnectApi, putApi } from "../../../utils/api-utils";
import { message } from "antd";
import { INSPECTION_ACTIONS } from '../actions/actionTypes';

export function loadInspectionList() {
    return async function (dispatch, getState) {
        try {
            const result = await getApi(ConnectAdminApi.INSPECTION_API, '/templates');
            console.log('result:', result);
            await dispatch({
                type: INSPECTION_ACTIONS.INSPECTION_LIST,
                payload: result.templateList
            });
        } catch (e) {
            console.log(e);
            message.error("Error in getting inspection list");
        }
    }
}

export async function createNewTemplate(template, userId) {
    try {
        template.createdBy = userId;
        const url = `/template`;
        await postApi(ConnectAdminApi.INSPECTION_API, url, template);
    } catch (e) {
        console.log(e);
        message.error("Error in creating template");
    }
}

export async function editTemplate(template, userId, templateId) {
    try {
        template.updatedBy = userId;
        const url = `/template/${templateId}`;
        await putApi(ConnectAdminApi.INSPECTION_API, url, template);
    } catch (e) {
        console.log(e);
        message.error("Error in editing template");
    }
}

export async function getTemplate(templateId) {
    try {
        const url = `/template`;
        const result = await getApi(ConnectAdminApi.INSPECTION_API, url, { queryStringParameters: { templateId } });
        const response = await fetch(result.url);
        const json = await response.json();
        return json;
    } catch (e) {
        console.log(e);
        message.error("Error in getting template");
    }
}


export async function addTemplateToOrg(template, userId, orgId) {
    try {
        console.log('createNewTemplate:');
        template.createdBy = userId;
        const url = `/${orgId}/inspection/create_template`;
        const body = template
        await postApi(ConnectApi.INSPECTION_API, url, body);
    } catch (e) {
        console.log(e);
        message.error("Error in creating template list");
    }
}

export async function archiveTemplate(templateId) {
    try {
        const url = `/archive_template/${templateId}`;
        await putApi(ConnectAdminApi.INSPECTION_API, url);
    } catch (e) {
        console.log(e);
        message.error("Error in archiving template");
    }
}