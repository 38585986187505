import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import TemplateListView from './containers/TemplateListView';
import TemplateView from './containers/TemplateView';

class JobPlannerHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['jobPlanner']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'template':
                return <TemplateView templateId={route.match.params.param}/>
            default:
                return <TemplateListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/job_planner/:page/:param'} render={this.getView} />
                <Route path={'/job_planner'} render={this.getView} />
                <Redirect to='/job_planner' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobPlannerHome));