import { Map } from 'immutable';
import { EQUIPMENT_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildUserIndex(equipmentList) {
    return SearchUtils.buildIndex(equipmentList, 'equipment_id', ['name', 'manufacturer_name', 'model_no']);
};

const initialState = Map({
    equipmentList: undefined,
    equipmentIndex: buildUserIndex([])
});

export default function equipmentState(state = initialState, action) {
    switch (action.type) {
        case EQUIPMENT_ACTIONS.EQUIPMENT_LIST:
            return state.set('equipmentList', action.payload).set('equipmentIndex',
            buildUserIndex(action.payload));
        default:
            return state;
    }
}  