import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import OrgListView from './containers/OrgListView';
import OrgDetailsPage from './containers/OrgDetailsPage';

class OrgHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['organisations']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'org':
                return <OrgDetailsPage orgId = {route.match.params.param} />
            default:
                return <OrgListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/organisations/:page/:param'} render={this.getView} />
                <Route path={'/organisations'} render={this.getView} />
                <Redirect to='/organisations' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgHome));