import React, { Component } from 'react';
import { connect } from 'react-redux'
import {Form, Modal, Input, Select, Divider, InputNumber, DatePicker, Checkbox, message} from 'antd';
import TimezonePicker from 'react-timezone';
import { CONNECT_FEATURES } from '../constants';

import { createOrgAndSysAdmin, getSSOProviderStatus } from '../actions/orgActions';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class NewOrgModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false,
            features: [],
            enableSingleSignOn: false
        }
    }

    toggleSingleSignOn = () => {
        this.setState({enableSingleSignOn: !this.state.enableSingleSignOn})
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const orgInfo = {
                orgName: values.orgName,
                orgCode: values.orgCode,
                country: values.country,
                timezone: values.timezone,
                createdBy: this.props.userId,
                userLimit: values.userLimit,
                expiryDate: values.expiryDate,
            }

            const userInfo = {
                username: values.userName,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                externalEmployeeId: '',
                password: values.password,
                groups: [],
                orgUnits: [],
                courses: [],
                documents: [],
                inspections: [],
                isOUAdmin: true,
                orgCode: values.orgCode,
                settings: {},
                language: 'EN',
                documentsForTasks: [],
                createdBy: this.props.userId,
                isMasterAdmin: true
            };

            let authInfo = {};
            if (this.state.enableSingleSignOn) {
                const isSSO = await getSSOProviderStatus(values.identityProviderName);
                if(!isSSO) {
                    return;
                }
                if(!isSSO.isOrgProviderExist) {
                    message.warning('Unable to find the SSO Provider. Please check again.')
                    return;
                }
                if(!isSSO.isProviderAvailable) {
                    message.warning(`The SSO provider "${values.identityProviderName}" already exists in the system. Please check again`);
                    return;
                }
                authInfo = { isSSO: true, providerName: values.identityProviderName}
            }else {
                authInfo = { isSSO: false, providerName: ""}
            }

            await this.setState({ savingChanges: true });
            if (await createOrgAndSysAdmin(orgInfo, userInfo, this.state.features, authInfo)) {
                form.resetFields();
                this.props.onSave();
            } else {
                await this.setState({ savingChanges: false });
            }

        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title={"Add New Organisation"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"Organisation Name"}>
                        {
                            getFieldDecorator('orgName', { rules: [{ required: true, message: 'Please enter organisation name' }] })(
                                <Input maxLength={100} placeholder={"Enter name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Organisation Code"}>
                        {
                            getFieldDecorator('orgCode', { rules: [{ required: true, message: 'Please enter short code' }] })(
                                <Input maxLength={100} placeholder={"Enter short code"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout}
                        label="Country">
                        {getFieldDecorator('country', {
                            rules: [{ required: true, message: 'Please select the organisation country.' }],
                        })(
                            <Select>
                                <Option value="AUS">Australia</Option>
                                <Option value="USA">United States</Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="Default Timezone" {...formItemLayout} >
                        {getFieldDecorator('timezone', {
                            rules: [{ required: true, message: 'Please select default timezone.' }],
                        })(
                            <TimezonePicker
                                style={{ zIndex: '1' }}
                                inputProps={{
                                    placeholder: 'Select timezone',
                                    name: 'timezone',
                                }}
                            />
                        )}
                    </FormItem>
                    <FormItem label="User Limit" {...formItemLayout} >
                        {getFieldDecorator('userLimit', {
                            rules: [{ required: true, message: 'Please add a valid number.' }],
                        })(
                            <InputNumber min={1} defaultValue={1} step={1} />
                        )}
                    </FormItem>
                    <FormItem label={"Expiry date"} {...formItemLayout}>
                        {
                            getFieldDecorator('expiryDate', { rules: [{ required: true, message: 'Please enter a valid date' }] })(
                                <DatePicker format="YYYY-MM-DD" />
                            )
                        }
                    </FormItem>
                    <Divider orientation="left">System Admin User</Divider>
                    <FormItem {...formItemLayout} label={"Username"}>
                        {
                            getFieldDecorator('userName', { rules: [{ required: true, message: 'Please enter username' }] })(
                                <Input maxLength={100} placeholder={"Enter username"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Password"}
                        help='This is a temporary password and will ask to create a new password on first login'>
                        {
                            getFieldDecorator('password', { rules: [{ required: true, message: 'Please password' }], initialValue: 'Connect123' })(
                                <Input maxLength={100} placeholder={"Enter password"} disabled />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"First Name"}>
                        {
                            getFieldDecorator('firstName', { rules: [{ required: true, message: 'Please enter first name' }] })(
                                <Input maxLength={100} placeholder={"Enter first name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Last Name"}>
                        {
                            getFieldDecorator('lastName', { rules: [{ required: true, message: 'Please enter last name' }] })(
                                <Input maxLength={100} placeholder={"Enter last name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Email"}>
                        {
                            getFieldDecorator('email', { rules: [{ required: true, message: 'Please enter email' }, { type: 'email' }] })(
                                <Input maxLength={100} placeholder={"Enter email"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Features"}>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            onChange={(features) => this.setState({ features })}
                        >
                            {Object.keys(CONNECT_FEATURES).map(key => <Select.Option key={key}>{CONNECT_FEATURES[key].displayName}</Select.Option>)}
                        </Select>
                    </FormItem>
                    <Divider orientation="left">Authentication Strategy</Divider>
                    <FormItem wrapperCol={{ span: 16, offset: 8 }}>
                        <Checkbox onChange={this.toggleSingleSignOn}>Enable Single Sign-On</Checkbox>
                    </FormItem>
                    {this.state.enableSingleSignOn &&
                    <FormItem {...formItemLayout} label="Identity Provider">
                        {
                            getFieldDecorator('identityProviderName', { rules: [{ required: true, message: 'Please enter identity provider' }] })(
                                <Input maxLength={100} placeholder={"Identity Provider"} />
                            )
                        }
                    </FormItem>}
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewOrgModal));
