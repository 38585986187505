import React from 'react';
import DetailsTable from '../../../../components/DetailsTable';
import { FREQUENCIES, CATEGORIES} from '../../constants' 

const CourseDetailsReview = (props) =>{
    let fields = [
        {key: "Course Title", value: props.courseDetails.title},
        {key: "Category", value: CATEGORIES[props.courseDetails.category]},
        {key: "Review Frequency", value: FREQUENCIES[props.courseDetails.frequency]},
        {key: "Course File", value: props.contentFile},
      ];
    return(          
    <DetailsTable  dataSource={fields} />
    );
};

export default CourseDetailsReview;