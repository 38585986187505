
import { Auth } from 'aws-amplify';
import { LOGIN_ACTIONS } from '../actions/actionTypes';

export function authenticate(username, password, newPassword) {
  return async function (dispatch, getState) {
    try {
      const user = await Auth.signIn(username, password);      
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Invoke new password required workflow
        if ((newPassword) && newPassword !== '') { // User has already supplied the new password
          const data = await Auth.completeNewPassword(user, newPassword);
          dispatch({
            type: LOGIN_ACTIONS.SUCCESS,
            payload: {
              username: username,
              credentialsData: data.signInUserSession
            }
          });
          dispatch({
            type: LOGIN_ACTIONS.NEW_PASSWORD_SET,
            payload: null
          });
        }
        else {
          dispatch({
            type: LOGIN_ACTIONS.NEW_PASSWORD_REQUIRED,
            payload: {
              username: username,
              password: ''
            }
          });
        }
      }
      else {
        await dispatch({
          type: LOGIN_ACTIONS.SUCCESS,
          payload: {
            username: username,
            credentialsData: user.signInUserSession
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.message) {
        return err.message
      }
      return 'Error occured, please try again';
    }
  }
}

export function continueSession() {
  return async function (dispatch, getState) {
    try {
      const session = await Auth.currentSession();      
      await dispatch({
        type: LOGIN_ACTIONS.SUCCESS,
        payload: {
          username: session.idToken.payload['cognito:username'],
          credentialsData: session
        }
      });
    } catch (err) {
      console.log(err);
      await dispatch({
        type: LOGIN_ACTIONS.NO_SESSION,
        payload: undefined
      });
    }
  }
}

export function logout() {
  return async function (dispatch, getState) {
    try {
      await Auth.signOut();
      await dispatch({
        type: LOGIN_ACTIONS.LOGOUT,
        payload: undefined
      });
      await dispatch({
        type: LOGIN_ACTIONS.NO_SESSION,
        payload: undefined
      });
    } catch (err) {
      console.log(err);
    }
  }
}

export async function getJWTToken(){
  const session =  await Auth.currentSession();
  return session.idToken.jwtToken;
}