
export const APP_ACTIONS = {
    TOGGLE_SIDEPANEL: 'toggle_sidepanel',
    SET_SELECTED_MENU: 'set_selected_menu',
}

export const BREADCRUMB_ACTIONS = {
    SET_BREADCRUMB: 'set_crumb'
}

export const ORG_ACTIONS = {
    ORG_LIST: 'org_list',
    OU_LIST: 'ou_list'
}