import React,{ Component } from 'react';
import QRCode from 'qrcode';
import { Button,Row } from 'antd';

class QRCodeGenerator extends Component {
  constructor(props) {

    super(props);
    this.qrCodeRef = React.createRef();
  }

  componentDidMount() {
    this.generateQRCode();
  }

  componentDidUpdate() {
    this.generateQRCode();
  }

  generateQRCode = () => {
    const { value } = this.props;
    const canvas = this.qrCodeRef.current;
    QRCode.toCanvas(canvas,value,{ width: 200 },(error) => {
      if (error) console.error('Error generating QR code:',error);
    });
  };

  handleDownload = () => {
    const canvas = this.qrCodeRef.current;
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'qr_code.png';
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <div>
        <Row>
          <canvas ref={this.qrCodeRef} />
        </Row>
      </div>
    );
  }
}

export default QRCodeGenerator;
