import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Checkbox } from 'antd';

export class ChemicalListItem {
    static get Builder() {
        class Builder {
            constructor(chemical, isSdsExpired, onChemicalSelection, selectedChemicals) {
                this.chemical = chemical;
                this.isSdsExpired = isSdsExpired;
                this.onChemicalSelection = onChemicalSelection;
                this.selectedChemicals = selectedChemicals;
            }
            build() {
                const chemical = this.chemical;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(chemical.product_name, `/chemicals/chemical/${chemical.chemical_id}`)
                    .setDescription(
                        <div>
                            <Row>{`Manufacturer: ${chemical.manufacturer_name}`}</Row>
                            <Row>{`Country: ${chemical.country_code==='AUS'?'Australia':chemical.country_code==='USA'?'United States':''}`}</Row>
                        </div>
                    )
                    .setLeftIcon(
                        <Checkbox
                            onChange={e => this.onChemicalSelection(chemical.chemical_id, e.target.checked)}
                            checked={this.selectedChemicals.has(chemical.chemical_id)}
                        />);
                if(this.isSdsExpired){
                    builder.setRightTag('SDS Expired', 'red');
                }
                return builder.build();
            }
        }
        return Builder;
    }
}
