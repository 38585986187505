import { getApi, ConnectAdminApi, putApi, postApi, ConnectApi } from "../../../utils/api-utils";
import { message } from "antd";
import { EQUIPMENT_ACTIONS } from '../actions/actionTypes';

export function loadEquipmentList() {
    return async function (dispatch, getState) {
        try {
            const result = await getApi(ConnectAdminApi.EQUIPMENT_API, '/epar_equipment_list');
            await dispatch({
                type: EQUIPMENT_ACTIONS.EQUIPMENT_LIST,
                payload: result.equipmentList
            });
        } catch (e) {
            console.log(e);
            message.error("Error in getting equipment list");
        }
    }
}

export async function getEquipmentInfo(equipmentId) {
    try {
        const result = await getApi(ConnectAdminApi.EQUIPMENT_API, '/epar_equipment_info', { queryStringParameters: { equipmentId } });
        return result.equipmentInfo;
    } catch (e) {
        console.log(e);
        message.error("Error in getting equipment details");
    }
}
export async function updateEquipmentDetails(equipmentId, equipmentInfo) {
    try {
        const result = await putApi(ConnectAdminApi.EQUIPMENT_API, '/epar_equipment_info', { equipmentId, equipmentInfo });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function addNewEquipment(equipmentInfo) {
    try {
        const result = await postApi(ConnectAdminApi.EQUIPMENT_API, '/epar_equipment_info', { equipmentInfo });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function updateEquipmentLocale(equipmentId, locale) {
    try {
        const result = await putApi(ConnectAdminApi.EQUIPMENT_API, '/epar_equipment_locale', { equipmentId, locale });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function addEquipmentToOu(equipment, equipmentId, orgId) {
    const path = `/${orgId}/assign/${equipmentId}`;
    const result = await postApi(ConnectApi.EQUIPMENT_API, path, equipment);
    if (!result.error) {
      const orgCourseId = result.data;
      return orgCourseId;
    } else {
      console.error(`Error adding equipment`, result.error);
      return undefined;
    }
}


export async function archiveEquipment(equipmentId) {
    try {
        const result = await putApi(ConnectAdminApi.EQUIPMENT_API, '/archive', { equipmentId });
        return result;
    } catch (e) {
        console.log(e);
    }
}


export async function unArchiveEquipment(equipmentId) {
    try {
        const result = await putApi(ConnectAdminApi.EQUIPMENT_API, '/unarchive', { equipmentId });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function openS3DocumentByURL(apiUrl) {
    message.loading('please wait',0);
    await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/pdf'
        },
    })
    .then( res => res.blob())
    .then( blob => {
      var url = new Blob([blob], {type: 'application/pdf'});
      var file = window.URL.createObjectURL(url);
      message.destroy();
      window.open(file);
    });
}

export async function getEquipmentAssignedOuList (equipmentId) {
    try {
        const url = `/ou_list/${equipmentId}`;
        const result = await getApi(ConnectAdminApi.EQUIPMENT_API,url);
        return result;
    } catch (e){
        console.log(e);
        message.error("Error in getting equipment assigned ou list");
    }
}