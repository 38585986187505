import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux'

import LoginFormView from './LoginFormView';
import NewPasswordFormView from './NewPasswordFormView';

const { Content } = Layout;

export class LoginPage extends Component {

  getView = () => {
    if (this.props.requestNewPassword) {
      return <NewPasswordFormView/>
    }
    else {
      return <LoginFormView />
    }
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh', padding: '20px' }}>
        <Content style={{ minHeight: '100vh' }}>
          <Row style={{ minHeight: '100vh' }} className='epar__login__container' type="flex" justify="space-around" align="middle">
            <Col xs={20} sm={20} md={12} lg={9} xl={9}>
              <div className='epar__login__form__container'>
                {this.getView()}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    requestNewPassword: state.getIn(['loginUserState', 'requireNewPassword'])
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);