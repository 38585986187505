import React from 'react';
import { Steps, Button, message, Card, Upload, Icon, Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { setBreadcrumb } from '../../../../actions/commonActions';

import CourseDetailsForm from './CourseDetailsForm';
import CourseContentUpload from './CourseContentUpload';
import CourseQuestions from './CourseQuestions';
import CourseDetailsReview from './CourseDetailsReview';
import { addNewCourse, getSignedUrl, updateCourseDetails, uploadFile } from '../../actions/courseActions';
import { FREQUENCIES, CATEGORIES} from '../../constants' ;
import TrainingVideoFormView from '../../trainingVideo/TrainingVideoForm';

const axios = require('axios').default;

const Step = Steps.Step;
const STEP_COUNT = 3;
const STEP_DETAILS = 0;
const STEP_QUIZ = 1;
const STEP_REVIEW = 2;

class NewCourseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      courseDetails: {
        title: undefined,
        category: undefined,
        frequency: undefined,
      },
      courseContent: [],
      questions: [],
      activeQuestion: '0',
      finished: false,
      submitting: false,
      courseId: undefined,
      uploadFormData: null,
      videoFile: null,
      fileName: null,
      uploadModal: false,
    };
    this.onQuestionAdd();
  }

  videoSource = axios.CancelToken.source();

  videoUploadProgress = (progressEvent) => {
    let uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    if(uploadPercentage % 20 === 0) {
      message.destroy();
      message.loading(`Uploading training video. Please wait.. ${uploadPercentage}%`, 0);
    }
  }


  onSubmit = async () => {
    await this.setState({ submitting: true });
    message.loading('Course is creating. Please wait..', 0);
    const file = this.state.courseContent[0];
    const quiz = {
      questions: this.state.questions
    };
    let courseDetails = this.state.courseDetails;
    courseDetails.createdBy = this.props.userId;
    courseDetails.quiz = JSON.stringify(quiz);
    courseDetails.courseVersionAvailable = file ? true : false;
    const courseId = await addNewCourse(courseDetails, file);
    message.destroy();

    if(this.state.videoFile) {
      try {
        const videoFile = this.state.videoFile;
        const versionId = 1;
        const fileKey = `${courseId}/${versionId}/${videoFile.name}`;
        const url = await getSignedUrl(fileKey, videoFile.type, 'putObject', 3600, versionId);
        await uploadFile(url, videoFile, videoFile.type, this.videoUploadProgress, this.videoSource.token);
        courseDetails.training_video = this.state.uploadFormData;
        courseDetails.trainingVideoVersionId = versionId;
        await updateCourseDetails(courseId, courseDetails);
      }
      catch(err) {
        console.log( err);
        message.error("Error uploading training video");
        message.destroy();
      }
    }
    this.setState({ finished: true, submitting: false, courseId });
    message.destroy();
    message.success('Course is successfully created.', 5);
  };


  onFinish = () => {
    this.props.history.push('/courses');
  }

  onViewCourse = () => {
    this.props.history.push(`/courses/course/${this.state.courseId}`);
  }

  setTrainingFormData = (data)=> {
    data.trainingVideoVersionId = 1;
    this.setState({uploadFormData: data});
  }

  resetTrainingVideoState = () => {
    this.setState({
      uploadFormData: null,
      videoFile: null
    });
  }

  setUploadModalState = (flag) => {
    this.setState({uploadModal: flag});
  }

  handleVideoModalClose = () => {
    this.resetTrainingVideoState();
    this.setState({uploadModal: false});
  }

  next = () => {
    if (this.validateFields(this.state.step)) {
      const step = this.state.step + 1;
      this.setState({ step });
    }
  }

  prev() {
    const step = this.state.step - 1;
    this.setState({ step });
  }

  renderButtons = () => {
    const { step, finished } = this.state;
    return (
      <div className="steps-action">
        {step > 0 && !finished && <Button style={{ marginLeft: 8 }} onClick={() => this.prev()} disabled={this.state.submitting}> Previous </Button>}
        {step < STEP_COUNT - 1 && !finished && <Button type="primary" onClick={() => this.next()} disabled={this.state.submitting}>Next</Button>}
        {step === STEP_COUNT - 1 && !finished && <Button type="primary" onClick={this.onSubmit} disabled={this.state.submitting}>Submit</Button>}
        {finished && <Button type="primary" onClick={this.onViewCourse}>ViewCourse</Button>}
        {finished && <Button type="primary" onClick={this.onFinish}>Finish</Button>}
      </div>
    );
  };

  validateFields = (step) => {
    let validated = true;
    switch (step) {
      case STEP_DETAILS:
        if (!this.state.courseDetails.title || !this.state.courseDetails.category || !this.state.courseDetails.frequency) {
          this.courseDetailsForm.validateFields();
          validated = false;
        } else if (this.state.courseContent.length === 0 && !this.state.videoFile) {
          message.warning('Please upload the course content file.');
          validated = false;
        }
        break;
      case STEP_QUIZ:
        if (this.state.questions.length === 0) {
          message.warning('Please add question(s).');
          validated = false;
        } else {
          for (let index = 0; index < this.state.questions.length; index++) {
            let question = this.state.questions[index];
            let warning = undefined;
            if (!question.question) {
              warning = 'Please enter the question';
            } else if (question.options.length === 0) {
              warning = 'Please add responses';
            } else if (question.options.indexOf("") > -1) {
              let i = question.options.indexOf("");
              warning = `Please enter response ${i + 1} or delete the field`;
            } else if (!question.answer) {
              warning = 'Please select the correct response';
            } else if (question.options.indexOf(question.answer) === -1) {
              warning = 'Selected correct response is not in the responses list. Please select correct response again';
            }
            if (warning) {
              message.warning(warning);
              this.setState({ activeQuestion: `${index}` });
              validated = false;
              break;
            }
          };
        }
        break;
      default:
        break;
    }
    return validated;
  }

  onCourseDetailsChange = (changedFields) => {
    this.setState({
      courseDetails: { ...this.state.courseDetails, ...changedFields }
    });
  };

  onCourseDetailsformRef = (form) => {
    this.courseDetailsForm = form;
  }

  onCourseContent = (courseContent) => {
    this.setState({ courseContent });
  };

  onQuestionAdd = async () => {
    let questions = this.state.questions;
    await questions.push(
      {
        question: undefined,
        options: [''],
        answer: undefined
      });
    await this.setState({ questions: questions, activeQuestion: `${questions.length - 1}` });
  };

  onQuestionRemove = async (index) => {
    let indexInt = parseInt(index, 10);
    let questions = this.state.questions;
    await questions.splice(indexInt, 1);
    this.setState({ questions });
  };

  onQuestionChange = (index, changedFields) => {
    let questions = this.state.questions;
    let question = questions[index];
    if (changedFields.option) {
      let option = changedFields.option;
      question.options[option.length - 1] = option[option.length - 1].value
    } else if (changedFields.question) {
      question.question = changedFields.question.value;
    } else if (changedFields.answer) {
      question.answer = changedFields.answer.value;
    }
    questions[index] = question;
    this.setState({ questions });
  };

  onAddAnswer = (questionIndex) => {
    let questions = this.state.questions;
    let question = questions[questionIndex];
    question.options.push('');
    questions[questionIndex] = question;
    this.setState({ questions });
  }

  onRemoveAnswer = (questionIndex, answerIndex) => {
    let questions = this.state.questions;
    let question = questions[questionIndex];
    question.options.splice(answerIndex, 1);
    questions[questionIndex] = question;
    this.setState({ questions });
  }

  getView = () => {
    switch (this.state.step) {
      case STEP_DETAILS:
        return (
          <div>
            <CourseDetailsForm
              {...this.state.courseDetails}
              onChange={this.onCourseDetailsChange}
              formRef={this.onCourseDetailsformRef}
            />

            <Card title="Course Content">
              <CourseContentUpload
                onChange={this.onCourseContent}
                fileList={this.state.courseContent}
              />
            <Button data-test="upload-training-video" style={{margin: '30px 0px 5px 0px'}} onClick={()=>this.setState({uploadModal: true})}>Add Training Video</Button>
            { this.state.uploadFormData &&
                <div className='trn-video-details'>
                  {<div> <span className='trn-detail-lbl'> Title :</span> {this.state.uploadFormData.title} </div>}
                  {this.state.uploadFormData.description && <div> <span className='trn-detail-lbl'> Description : </span> {this.state.uploadFormData.description}</div>}
                  {(this.state.videoFile && this.state.videoFile.name) && 
                  <Row className='video-lbl-row'>
                    <Col className='video-lbl' span={12}>{this.state.videoFile.name}</Col>
                    <Col span={12}><Button className='video-icon' onClick={()=>this.resetTrainingVideoState()} type="danger" shape="circle" icon="delete" /></Col>
                  </Row>}
                </div>
            }
            </Card>
          </div>
        );
      case STEP_QUIZ:
        return (
          <CourseQuestions
            activeQuestion={this.state.activeQuestion}
            activeQuestionChange={activeQuestion => this.setState({ activeQuestion })}
            add={this.onQuestionAdd}
            remove={this.onQuestionRemove}
            questions={this.state.questions}
            onQuestionChange={this.onQuestionChange}
            onAddAnswer={this.onAddAnswer}
            onRemoveAnswer={this.onRemoveAnswer}
          />
        );
      case STEP_REVIEW:
        return (
          <CourseDetailsReview
            t={this.props.t}
            courseDetails={this.state.courseDetails}
            contentFile={this.state.courseContent.length === 1 ? this.state.courseContent[0].name : undefined}
          />
        );
      default:
        break;

    }

  };

  handleSetFile = (file) => {
    this.setState({videoFile: file})
  }

  onFileLoad = (data) =>{

    let courseDetails = this.state.courseDetails;
    if(data.title){
      courseDetails.title = data.title;      
    }
    if(data.category && data.category in CATEGORIES){
      courseDetails.category = data.category;      
    }
    if(data.reviewFrequency && data.reviewFrequency in FREQUENCIES){
      courseDetails.frequency = data.reviewFrequency;      
    }

    let questions = []
    if(data.questions){
      questions= data.questions.map(question=>{
        return {
          question: question.question,
          options: question.options,
          answer: question.answer
        }
      })
    }
    this.setState({ courseDetails, questions});
  }

  videoUploadModal = () => {
    return (
      <Modal
        title = 'Add Training Video'
        visible={this.state.uploadModal}
        onOk={()=>this.setState({uploadModal: false})}
        onCancel={()=>this.handleVideoModalClose()}
        maskClosable={false}
        footer={null}
        width = {'600px'}
      >
        <TrainingVideoFormView 
          videoSource = {null}
          videoUrl = {null}
          trainingVideoData = {null}
          courseId = {this.state.courseId}
          addNewCourse = {true}
          handleSetFile = {this.handleSetFile}
          setTrainingFormData = {this.setTrainingFormData}
          setUploadModalState = {this.setUploadModalState}     
        />
      </Modal>
    )
  }

  render() {
    const { step } = this.state;
    const uploadProps = {
      accept: '.json',
      name: 'file',
      multiple: false,
      action: '//jsonplaceholder.typicode.com/posts/',
      fileList: [],
      beforeUpload: (file, fileList) => false,
      onChange: async ({ file, fileList }) => {
        if (fileList.length === 1) {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onloadend = () => {
            try {
              const data = JSON.parse(reader.result);
              this.onFileLoad(data);
            } catch (err) {
              message.error(err.message, 10);
            }
          }
          reader.onerror = function (event) {
            console.log('event:', event);
          };
        }
      }
    }
    return (
      <div>
        {this.state.uploadModal && this.videoUploadModal()}
        <Card>
          <div className='epar__wizard--wrapper'>
            <div className="pure-g">
              <div className="pure-u-1">
                <div className="pure-g" style={{ float: 'right' }}>
                  <Upload {...uploadProps} >
                    <Button className='epar__tasks__action--button'>
                      <Icon type="upload" /> Load from file
                    </Button>
                  </Upload>
                  <Button className='epar__tasks__action--button'>
                    <a href='/assets/CourseTemplate.json'>Download JSON Template</a>
                  </Button>
                </div>
              </div>
              <div className='pure-u-1-1'>
                <Steps current={step} size='small'>
                  <Step title={"Course Details & Content"} />
                  <Step title={"Create Course Quiz"} />
                  <Step title={"Review & Finalise"} />
                </Steps>
              </div>
            </div>
            <div className="epar__wizard-content-wrapper" >
              {this.getView()}
            </div>
            <div className="pure-g epar__group-wiz__nav-buttons__container__left" style={{marginTop: '5px'}}>
              {this.renderButtons()}
            </div>
          </div>
        </Card>
      </div>
    );
  }

  componentDidMount = async () => {
    const path = [
      { route: '/courses', label: "Courses" },
      { route: '', label: "Add New Course" },
    ];
    this.props.setBreadcrumb("Add New Course", path);
  }
}

function mapStateToProps(state, props) {
  return {
    courseFilesBucket: state.getIn(['appConfigState', 'courseFilesBucket']),
    userId: state.getIn(['loginUserState', 'userId']),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCourseView));