import React from 'react';
import createFragment from 'react-addons-create-fragment';
import { Modal, Form, Input, Select, Button, Icon, Upload, message } from 'antd';
import { INSPECTION_CATEGORIES, INSPECTION_FREQUENCIES } from '../constants';

const FormItem = Form.Item;
const Option = Select.Option;

const TemplateForm = Form.create({
  mapPropsToFields(props) {
    let questions = {};
    props.questions.map((question, index) => {
      questions[`questions[${index}]`] = Form.createFormField({
        value: question.question
      });
      return undefined;
    });
    return {
      templateTitle: Form.createFormField({
        value: props.templateTitle.value
      }),
      frequency: Form.createFormField({
        value: props.frequency.value
      }),
      category: Form.createFormField({
        value: props.category.value
      }),
      ...questions
    };
  }
}
)(
  (props) => {
    const { form, modalTitle, visible, onCancel, onCreate, inspectionCategories, inspectionFrequencies,
      isEditDetails, isEditQuestions, questions, onFileLoad } = props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      }
    };

    //categories
    const inspectionCategoryOptions = [];
    for (var inspectionCategory in inspectionCategories) {
      inspectionCategoryOptions.push(<Option value={inspectionCategory}>{inspectionCategories[inspectionCategory]}</Option>);
    }
    const inspectionCategoryChildren = createFragment({
      inspectionCategoryKey: inspectionCategoryOptions
    });

    //frequencies
    const inspectionFrquencyOptions = [];
    for (var inspectionFrequency in inspectionFrequencies) {
      inspectionFrquencyOptions.push(<Option value={inspectionFrequency}>{inspectionFrequencies[inspectionFrequency]}</Option>);
    }
    const inspectionFrequencyChildren = createFragment({
      inspectionFreqKey: inspectionFrquencyOptions
    });

    const remove = async (k) => {
      const keys = getFieldValue('keys');
      if (keys.length === 1) {
        return;
      }
      await setFieldsValue({
        [`questions[${k}]`]: '',
        keys: keys.filter(key => key !== k)
      });
    }

    const add = () => {
      const keys = getFieldValue('keys');
      const nextKeys = keys.concat(keys[keys.length - 1] + 1);
      setFieldsValue({
        keys: nextKeys,
      });
    }

    if (questions.length > 0) {
      let keys = [];
      questions.map((k, index) => {
        keys.push(index);
        return undefined;
      });
      getFieldDecorator('keys', { initialValue: keys });
    } else {
      getFieldDecorator('keys', { initialValue: [0] });
    }
    const keys = getFieldValue('keys');

    const formItems = keys.map((k, index) => {
      return (
        <FormItem
          {...formItemLayout}
          style={{display: isEditDetails?'none':''}}
          key={k}
          label={`Question ${index + 1}`}>
          {getFieldDecorator(`questions[${k}]`, {
            rules: [{
              required: true, message: `${index === 0 ? 'Response cannot be empty' : 'Please input question or delete this field.'}`,
            }],
          })(
            <Input placeholder="Enter question here" style={{ width: '90%', marginRight: 3 }} />
          )}
          {
            <Button disabled={keys.length === 1} onClick={() => remove(k)}
              className='epar__action__danger--icon-buttons' type="danger" shape="circle" icon="delete" size='small' />
          }
        </FormItem>
      );
    });
    const uploadProps = {
      accept: '.json',
      name: 'file',
      multiple: false,
      action: '//jsonplaceholder.typicode.com/posts/',
      fileList: [],
      beforeUpload: (file, fileList) => false,
      onChange: async ({ file, fileList }) => {
        if (fileList.length === 1) {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onloadend = () => {
            try {
              const data = JSON.parse(reader.result);
              onFileLoad(data);
            } catch (err) {
              message.error(err.message, 10);
            }
          }
          reader.onerror = function (event) {
            console.log('event:', event);
          };
        }
      }
    }

    return (
      <Modal
        visible={visible}
        title={modalTitle}
        okText="Save"
        onCancel={onCancel}
        onOk={onCreate}
      >
        <div className="pure-u-1">
          <div className="pure-g" style={{ float: 'right' }}>
            <Upload {...uploadProps} >
              <Button className='epar__tasks__action--button'>
                <Icon type="upload" /> Load from file
          </Button>
            </Upload>
            <Button className='epar__tasks__action--button'>
              <a href='/assets/InspectionTemplate.json'>Download JSON Template</a>
            </Button>
          </div>
        </div>

        <Form layout="horizontal">
          <FormItem label="Template Title" {...formItemLayout} style={{display: isEditQuestions?'none':''}} >
            {getFieldDecorator('templateTitle', {
              rules: [{ required: true, message: 'Please enter a template title' }],
            })(
              <Input placeholder="Enter title of inspection template" />
            )}
          </FormItem>
          <FormItem label="Category" {...formItemLayout}  style={{display: isEditQuestions?'none':''}}>
            {getFieldDecorator('category', {
              rules: [{ required: true, message: 'Please select a category' }],
            })(
              <Select
                showSearch
                placeholder="Select category"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {inspectionCategoryChildren}
              </Select>
            )}
          </FormItem>
          <FormItem label="Frequency" {...formItemLayout}  style={{display: isEditQuestions?'none':''}}>
            {getFieldDecorator('frequency', {
              rules: [{ required: true, message: 'Please select frequency of inspection' }],
            })(
              <Select placeholder="Select frequency">
                {inspectionFrequencyChildren}
              </Select>
            )}
          </FormItem>
          {formItems}
          <FormItem {...formItemLayoutWithOutLabel} style={{display: isEditDetails?'none':''}}>
            <Button type="dashed" onClick={() => add()} style={{ width: '60%' }}>
              <Icon type="plus" /> Add question
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
);

export class InspectionTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: props.mode === 'create' ? 'Add New Inspection Template' : props.mode === 'edit_details' ? 'Edit Inspection Template Details' : 'Edit Inspection Template Questions',
      isEditDetails: props.mode === 'edit_details',
      isEditQuestions: props.mode === 'edit_questions',
      isCreate: props.mode === 'create',
      template: props.template,
      fields: {
        templateTitle: {
          value: props.mode === 'create' ? '' : props.template.title
        },
        category: {
          value: props.mode === 'create' ? '' : props.template.category
        },
        frequency: {
          value: props.mode === 'create' ? '' : props.template.frequency
        },
        questions: props.mode === 'create' ? [] : props.template.questions
      }
    }
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  handleCancel = () => {
    this.props.callback();
  }

  handleCreateTask = () => {
    const form = this.form;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let questions = [];
      const keys = form.getFieldValue('keys');
      keys.map((k, index) => {
        questions.push(
          {
            questionId: index + 1,
            question: form.getFieldValue(`questions[${k}]`),
            type: "single",
            answers: ["Yes", "No", "N/A"]
          }
        );
        return undefined;
      });

      let template = {
        title: values.templateTitle,
        category: values.category,
        frequency: values.frequency,
        questions: questions
      };
      form.resetFields();
      this.props.callback(template);
    });
  }
  saveFormRef = (form) => {
    this.form = form;
  }


  componentDidMount = async () => {
  }

  onFileLoad = (data) => {
    let fields = this.state.fields;
    if (data.title) {
      fields.templateTitle = { value: data.title }
    }
    if (data.category && data.category in INSPECTION_CATEGORIES) {
      fields.category = { value: data.category }
    }
    if (data.frequency && data.frequency in INSPECTION_FREQUENCIES) {
      fields.frequency = { value: data.frequency }
    }
    if (data.questions) {
      fields.questions = data.questions.map((question, index) => {
        return {
          questionId: index + 1,
          question: question,
          type: "single",
          answers: ["Yes", "No", "N/A"]
        }
      });
    }
    this.setState({ fields });
  }

  render() {
    let fields = this.state.fields;

    return (
      <div>
        <TemplateForm {...fields}
          ref={this.saveFormRef}
          visible={this.props.visible}
          modalTitle={this.state.modalTitle}
          onCancel={this.handleCancel}
          onCreate={this.handleCreateTask}
          inspectionFrequencies={INSPECTION_FREQUENCIES}
          inspectionCategories={INSPECTION_CATEGORIES}
          isEditDetails={this.state.isEditDetails}
          isEditQuestions={this.state.isEditQuestions}
          isCreate={this.state.isCreate}
          onFileLoad={this.onFileLoad}
        />
      </div>
    );
  }
}

export default InspectionTemplateForm;