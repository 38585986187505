import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, Select, Icon, Menu, Dropdown, Checkbox, Modal, message } from 'antd';
import {Redirect, withRouter} from 'react-router-dom'

import { loadChemicalList, archiveChemical, unArchiveChemical, discontinueChemical, continueChemical } from '../actions/chemicalActions';
import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { ChemicalListItem } from '../ChemicalListItemBuilder';
import { setBreadcrumb } from '../../../actions/commonActions';
import NewChemicalModal from './NewChemicalModal';

const Option = Select.Option;
const confirm = Modal.confirm;

const RECORD_STATUS = {
    DISCONTINUED: "-1",
    ARCHIVED: "0",
    ACTIVE: "1"
}

class ChemicalListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            isNewChemicalVisible: false,
            selectedChemicals: new Set(),
            selectedStatus: RECORD_STATUS.ACTIVE,
        };
        this.loadChemicalList();

        this.props.setBreadcrumb("Chemicals", [
            { route: '', label: 'Chemicals' }
        ]);
    }

    loadChemicalList = async () => {
        this.props.loadChemicalList();
    }

    getChemicalsForListView = () => {
        let chemicalList = this.filterChemicals().map((chemical) => {
            const isSDSExpired = this.props.sdsExpiredChemicalids.has(chemical.chemical_id);
            let builder = new ChemicalListItem.Builder(chemical, isSDSExpired, this.onChemicalSelection, this.state.selectedChemicals);
            return builder.build();
        });
        return chemicalList;
    }

    filterChemicals = () => {
        let filteredList = SearchUtils.filter(this.props.chemicals ? this.props.chemicals : [], "chemical_id", this.state.searchMatches);

        if (this.state.selectedCountry) {
            filteredList = filteredList.filter(chemical => chemical.country_code === this.state.selectedCountry)
        }
        filteredList = filteredList.filter(chemical => chemical.record_status === this.state.selectedStatus);
        filteredList = this.state.onlyExpired ? filteredList.filter(chemical => this.props.sdsExpiredChemicalids.has(chemical.chemical_id)) : filteredList;
        return filteredList;
    }

    filterSearchOptions() {
        const chemicals = this.filterChemicals();
        const optionsMap = { title: "Chemicals", children: [] };
        chemicals.forEach((chemical) => {
            optionsMap.children.push({ title: chemical.product_name, id: chemical.chemical_id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.chemicalIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (chemicalId) => {
        this.setState({ searchSelectedTemplate: chemicalId });
    }

    onSearchTemplateSelect = () => {
        if (this.state.searchSelectedTemplate) {
            return (<Redirect to={`/chemicals/chemical/${this.state.searchSelectedTemplate}`} />);
        }
    }

    onChemicalSelection = (chemicalId, checked) => {
        const selectedChemicals = this.state.selectedChemicals
        checked ? selectedChemicals.add(chemicalId) : selectedChemicals.delete(chemicalId);
        this.setState({ selectedChemicals });
    }
    onAction = ({ key }) => {
        switch (key) {
            case "archive": {
                confirm({
                    title: 'Do you want to archive selected chemicals?',
                    onOk: () => {
                        this.archiveChemicals();
                    }
                });
                break;
            }
            case "discontinue": {
                confirm({
                    title: 'Do you want to discontinue selected chemicals?',
                    onOk: () => {
                        this.discontinueChemicals();
                    }
                });
                break;
            }
            case "unarchive": {
                confirm({
                    title: 'Do you want to unarchive selected chemicals?',
                    onOk: () => {
                        this.unarchiveChemicals();
                    }
                });
                break;
            }
            case "continue": {
                confirm({
                    title: 'Do you want to continue selected chemicals?',
                    onOk: () => {
                        this.continueChemicals();
                    }
                });
                break;
            }
            default: break;
        }
    }

    archiveChemicals = async() => {        
        message.loading('Archiving chemicals', 0);
        const chemicalItr = this.state.selectedChemicals.values();
        for (let chemicalId of chemicalItr) {
            await archiveChemical(chemicalId);
        }
        message.destroy();
        message.success('Chemicals archived successfully');
        this.setState({ selectedChemicals: new Set() });
        this.loadChemicalList();
    }

    unarchiveChemicals = async() => {        
        message.loading('Unarchiving chemicals', 0);
        const chemicalItr = this.state.selectedChemicals.values();
        for (let chemicalId of chemicalItr) {
            await unArchiveChemical(chemicalId);
        }
        message.destroy();
        message.success('Chemicals unarchived successfully');
        this.setState({ selectedChemicals: new Set() });
        this.loadChemicalList();
    }

    discontinueChemicals = async() => {        
        message.loading('Discontinuing chemicals', 0);
        const chemicalItr = this.state.selectedChemicals.values();
        for (let chemicalId of chemicalItr) {
            await discontinueChemical(chemicalId);
        }
        message.destroy();
        message.success('Chemicals discontinued successfully');
        this.setState({ selectedChemicals: new Set() });
        this.loadChemicalList();
    }

    continueChemicals = async() => {        
        message.loading('Continuing chemicals', 0);
        const chemicalItr = this.state.selectedChemicals.values();
        for (let chemicalId of chemicalItr) {
            await continueChemical(chemicalId);
        }
        message.destroy();
        message.success('Chemicals continued successfully');
        this.setState({ selectedChemicals: new Set() });
        this.loadChemicalList();
    }


    render() {
        return (
            <div>
                {this.onSearchTemplateSelect()}
                {this.state.isNewChemicalVisible &&
                    <NewChemicalModal
                        visible={this.state.isNewChemicalVisible}
                        onCancel={() => this.setState({ isNewChemicalVisible: false })}
                        onSave={(chemicalId) => {
                            this.loadChemicalList();
                            this.setState({ isNewChemicalVisible: false });
                            this.props.history.push(`/chemicals/chemical/${chemicalId}`);
                        }}
                    />
                }
                <div className="pure-g">
                    <div className="pure-u-1-5">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-2-5">
                        <Select style={{ marginLeft: "6px", width: 110 }} placeholder="Country" onChange={selectedCountry => this.setState({ selectedCountry, selectedChemicals: new Set() })}>
                            <Option value={undefined}>All</Option>
                            <Option value="AUS">Australia</Option>
                            <Option value="USA">United States</Option>
                        </Select>
                        <Select defaultValue="1" style={{ marginLeft: "6px", width: 110 }} placeholder="Status" onChange={selectedStatus => this.setState({ selectedStatus, selectedChemicals: new Set() })}>
                            <Option value={RECORD_STATUS.ACTIVE}>Active</Option>
                            <Option value={RECORD_STATUS.ARCHIVED}>Archived</Option>
                            <Option value={RECORD_STATUS.DISCONTINUED}>Discontinued</Option>
                        </Select>
                        <Checkbox onChange={e => this.setState({ onlyExpired: e.target.checked, selectedChemicals: new Set() })} style={{ marginLeft: "6px" }}>
                            SDS Expired
                        </Checkbox>

                    </div>
                    <div className="pure-u-2-5">
                        <Button className='epar__tasks__action--button'
                            onClick={() => this.props.history.push('/chemicals/bulk_upload')}>
                            Bulk Upload
                        </Button>
                        <Dropdown
                            overlay={
                                <Menu onClick={this.onAction}>
                                    {this.state.selectedStatus === RECORD_STATUS.ACTIVE && <Menu.Item key="archive" disabled={this.state.selectedChemicals.size === 0}>Archive</Menu.Item>}
                                    {this.state.selectedStatus === RECORD_STATUS.ACTIVE && <Menu.Item key="discontinue" disabled={this.state.selectedChemicals.size === 0}>Discontinue</Menu.Item>}
                                    {this.state.selectedStatus === RECORD_STATUS.ARCHIVED && <Menu.Item key="unarchive" disabled={this.state.selectedChemicals.size === 0}>Unarchive</Menu.Item>}
                                    {this.state.selectedStatus === RECORD_STATUS.DISCONTINUED && <Menu.Item key="continue" disabled={this.state.selectedChemicals.size === 0}>Continue</Menu.Item>}
                                </Menu>}>
                            <Button className='epar__tasks__action--button'>
                                Options <Icon type="down" />
                            </Button>
                        </Dropdown>
                        <Button className='epar__tasks__action--button'
                            onClick={() => this.setState({ isNewChemicalVisible: true })}>
                            Add New Chemical
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getChemicalsForListView()} loading={this.props.chemicals === undefined} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        chemicals: state.getIn(['chemicalState', 'chemicals']),
        chemicalIndex: state.getIn(["chemicalState", "chemicalIndex"]),
        sdsExpiredChemicalids: state.getIn(["chemicalState", "sdsExpiredChemicalids"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadChemicalList: bindActionCreators(loadChemicalList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemicalListView));
