import React, { Component } from 'react';
import { Form, Icon, Input, Button, message, Upload, Card, Progress, Row, Col } from 'antd';
import { getFileExtension } from '../../../utils/common-utils';
import { getSignedUrl, uploadFile, updateCourseDetails } from '../actions/courseActions';
import ReactPlayer from 'react-player';

const { Dragger } = Upload;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

class TrainingVideoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: null,
            fileType: null,
            videoProgress: 0,
            videoFile: null,
            videoUploading: false,
            deleteVideo: false,
            isDisableSave: false,
            videoVersionId: 0
        }
    }

    componentDidMount = () => {
        if(this.props.isEdit) {
            this.setState({
                fileName: this.props.trainingVideoData.fileName, 
                fileType: this.props.trainingVideoData.fileType
            })
        }
    }

    videoUploadProgress = (progressEvent) => {
        let uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        this.setState({ videoProgress: uploadPercentage });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { courseId, load, setUploadModalState, courseDetails } = this.props;
        let newCourseData = courseDetails;
        this.props.form.validateFields(async (err, values) => {
            if((this.state.deleteVideo && !this.state.videoFile) || !this.state.fileName || (this.props.addNewCourse && !this.state.videoFile)) {
                message.warning("Please upload the training video");
                return;
            }
            if (!err) {
                let data = {
                    fileName: this.state.fileName,
                    fileType: this.state.fileType,
                    title: values.title,
                    description: values.description,
                    trainingVideoVersionId: this.state.videoVersionId
                }
                if(this.props.addNewCourse) {
                    this.props.setTrainingFormData(data);
                    this.props.setUploadModalState(false);
                    return;
                }
                newCourseData.training_video = data;
                newCourseData.trainingVideoVersionId = this.state.videoVersionId;
                try {
                    message.loading('Updating training video...', 0);
                    await updateCourseDetails(courseId, newCourseData);
                    load();
                    message.destroy();
                    setUploadModalState(false);
                }
                catch (err) {
                    message.destroy();
                    message.error("Error updating training video");
                }
            }
        })

    }

    uploadVideo = async (file, key) => {
        const { courseId, videoSource, courseDetails } = this.props;
        const versionId = courseDetails.trainingVideoVersionId ? courseDetails.trainingVideoVersionId + 1 : 1;
        this.setState({videoVersionId: versionId});
        const fileKey = `${courseId}/${versionId}/${file.name}`;
        const url = await getSignedUrl(fileKey, file.type, 'putObject', 3600, versionId);
        this.setState({ videoUploading: true, isDisableSave: true });
        await uploadFile(url, file, file.type, this.videoUploadProgress, videoSource.token).then(async () => {
            this.setState({isDisableSave: false});
        });
    }

    handleRemove = () => {
        if(this.props.addNewCourse) {
            this.setState({ videoFile: null });
            this.props.handleSetFile(null);
        }
    }

    videoDraggerProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file, fileList) => {
            const fileSize = file.size / 1000000;
            // Max upload size limit 1GB
            if (fileSize >= 1024) {
                message.warning("File size exceeded!");
                return false;
            }
            const ext = getFileExtension(file.name);
            if (!ext) {
                message.warning("incorrect file type!");
                return false;
            }
            this.setState({ videoFile: file });
            this.setState({ fileName: file.name, fileType: file.type });
            const key = `${file.name}.${ext}`;
            if(!this.props.addNewCourse) {
                this.setState({ videoUploading: true, videoFile: file });
                this.uploadVideo(file, key);
            }
            else {
                this.props.handleSetFile(file);
            }
            return false;
        },
        showUploadList: false
    };

    videoUploadprogressView = () => {
        const { videoProgress, videoFile } = this.state;
        return (
            <Card className='intnnt-prgrss-crd' style={{ marginBottom: '20px' }} bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Progress type='circle' percent={videoProgress} width={40} strokeWidth={6} strokeColor={'#09B95B'} />
                <p className='intnnt-prgrss-fl-nm'>{videoFile && videoFile.name ? videoFile.name : ''}</p>
                <p className='intnnt-prgrss-fl-sz'>{Math.round((videoFile && videoFile.size ? videoFile.size : 1) / 1000000)}MB</p>
            </Card>
        );
    };

    render() {
        const { trainingVideoData, form, t, isEdit, videoUrl } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label={'Video Title'}>
                    {getFieldDecorator('title', {
                        initialValue: trainingVideoData && !trainingVideoData.isDeleted ? trainingVideoData.title : '',
                        rules: [
                            {
                                required: true,
                                message: 'Please enter video title!'
                            }
                        ]
                    })(
                        <Input
                            data-testid="add_new_course_video_title"
                            placeholder={'Enter video title here'}
                        />
                    )}
                </Form.Item>

                <Form.Item {...formItemLayout} label={'Video Description'}
                    help={"Limit description to 200 characters" }>
                    {getFieldDecorator('description', {
                        initialValue: trainingVideoData && trainingVideoData.description && !trainingVideoData.isDeleted ? trainingVideoData.description : '',
                        rules : [
                            {
                              max: 200,
                              message: "The description may not be longer than 200 characters.",
                            }
                        ]
                    })(
                        <TextArea
                            data-testid="add_new_course_video_description"
                            maxRows = {2}
                            placeholder={'Enter video description here'}
                        />
                    )}
                </Form.Item>
                {!isEdit || this.state.deleteVideo ?  
                    !this.state.videoUploading ?
                        <div data-testid="add_new_course_video_section" className='trn-video-details'>
                            <Dragger
                                {...this.videoDraggerProps}
                                className='training-drggr' accept='.mp4, .mov' style={{ display: 'block' }}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Drag & Drop to upload or</p>
                                <p className="new-user-upload-hint margin-bottom-10">browse from your device"</p>
                                <p className="ant-upload-text">Only .MP4 and .MOV formats"</p>
                                <p className="ant-upload-text">with max size of 1GB</p>
                            </Dragger>
                            {(this.props.addNewCourse && this.state.videoFile) &&
                            <Row className='video-lbl-row' style={{margin: '10px 0px 10px 0px'}}>
                                <Col className='video-lbl' span={12}>{this.state.videoFile.name}</Col>
                                <Col span={12}><Button className='video-icon' onClick={()=>this.handleRemove()} type="danger" shape="circle" icon="delete" /></Col>
                            </Row>}
                        </div>
                        :
                        this.videoUploadprogressView()
                :   
                <div>
                    <Button data-test="upload-new-version" onClick={()=>this.setState({deleteVideo: true})} icon="delete" style={{marginBottom: '10px'}}>Delete Video</Button>
                    <div className="ind-video-player" style={{marginBottom: '10px'}}> 
                        <ReactPlayer playing light={'/assets/training-background.png'} width={'100%'} controls url={videoUrl} />
                    </div>
                </div>}
                <div style={{ height: '30px' }}>
                    <Button data-testid="add_new_course_video_save_btn" type="primary" disabled={this.state.isDisableSave} htmlType="submit" style={{ float: 'right' }}>
                        Save
                    </Button>
                </div>
            </Form>
        )
    }
}

const TrainingVideoFormView = Form.create()(TrainingVideoForm);
export default TrainingVideoFormView;