import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Checkbox } from 'antd';
import { INSPECTION_CATEGORIES, INSPECTION_FREQUENCIES } from './constants';

export class InspectionListItem {
    static get Builder() {
        class Builder {
            constructor(template, onTemplateSelection, selectedTemplates) {
                this.template = template;
                this.onTemplateSelection = onTemplateSelection;
                this.selectedTemplates = selectedTemplates;
            }
            build() {
                const template = this.template;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(template.title, `/inspections/template/${template.templateid}`)
                    .setDescription(
                        <div>
                            <Row>{`Category: ${INSPECTION_CATEGORIES[template.category]}`}</Row>
                            <Row>{`Frequency: ${INSPECTION_FREQUENCIES[template.frequency]}`}</Row>
                        </div>
                    )
                    .setLeftIcon(
                        <Checkbox 
                            onChange={e => this.onTemplateSelection(template.templateid, e.target.checked)}
                            checked = { this.selectedTemplates.has(template.templateid) }
                        />);
                return builder.build();
            }
        }
        return Builder;
    }
}
