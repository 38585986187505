import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, Icon, Upload, message, Select, Switch, Checkbox, Popover} from 'antd';
import {AUDUBON_CERTIFICATIONS} from '../Constants';

const FormItem = Form.Item;
const {Option} = Select;

const TemplateForm = Form.create({
        mapPropsToFields(props) {
            let questions = {};
            props.questions.map((question, index) => {
                questions[`questions[${index}]`] = Form.createFormField({
                    value: question.question
                });
                return undefined;
            });
            return {
                templateTitle: Form.createFormField({
                    value: props.templateTitle.value
                }),
                certification_type: Form.createFormField({
                    value: props.certification_type.value
                }),
                ...questions
            };
        }
    }
)(
    (props) => {
        const {
            form, modalTitle, visible, onCancel, onCreate,
            isEditDetails, isEditQuestions, questions, onFileLoad, isCreate, isPreliminary, questionsType, categoriesProp, categoryKeysProp, templateTitle
        } = props;
        const {getFieldDecorator, getFieldValue, setFieldsValue} = form;

        const formItemLayout = {
            labelCol: {span: 8},
            wrapperCol: {span: 14},
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4},
            }
        };

        const remove = async (k) => {
            const keys = getFieldValue('keys');
            if (keys.length === 1) {
                return;
            }
            await setFieldsValue({
                [`questions[${k}]`]: '',
                keys: keys.filter(key => key !== k)
            });
        }

        const add = () => {
            const keys = getFieldValue('keys');
            const nextKeys = keys.concat(keys[keys.length - 1] + 1);
            setFieldsValue({
                keys: nextKeys,
            });
        }

        const addCategory = () => {
            const categories = getFieldValue('categories');
            const nextKeys = categories.concat(categories[categories.length - 1] + 1);
            setFieldsValue({
                categories: nextKeys,
            });
        }

        const addCategoryKey = (c) => {
            const categoryKeys = getFieldValue('categoryKeys');
            if (categoryKeys[c-1]){
                categoryKeys[c-1] = [...categoryKeys[c-1], categoryKeys[c-1].length + 1];
            }else{
                categoryKeys.push([1]);
            }
            setFieldsValue({
                categoryKeys,
            });
        }

        const removeCategoryKey = async (c, k) => {
            const categoryKey = getFieldValue('categoryKeys');
            if (categoryKey[c-1].length === 1) {
                return;
            }
            categoryKey[c-1] = categoryKey[c-1].filter(key => key !== k);
            await setFieldsValue({
                [`category${c}questions[${k}]`]: '',
                categoryKeys: categoryKey
            });
        }

        if (questions.length > 0) {
            let keys = [];
            questions.map((k, index) => {
                keys.push(index);
                return undefined;
            });
            getFieldDecorator('keys', {initialValue: keys});
        } else {
            getFieldDecorator('keys', {initialValue: [0]});
        }
        const keys = getFieldValue('keys');

        const formItems =
            <>
                {keys.map((k, index) => {
                    return (
                        <FormItem
                            {...formItemLayout}
                            style={{display: isEditDetails ? 'none' : ''}}
                            key={k}
                            label={`Question ${index + 1}`}>
                            {getFieldDecorator(`questions[${k}]`, {})(
                                <Input.TextArea placeholder="Enter question here"
                                                style={{width: '90%', marginRight: 3}}/>
                            )}
                            {
                                <Button disabled={keys.length === 1} onClick={() => remove(k)}
                                        className='epar__action__danger--icon-buttons' type="danger" shape="circle"
                                        icon="delete" size='small'/>
                            }
                        </FormItem>
                    );
                })}
                <FormItem wrapperCol={{offset: 18}} style={{display: isEditDetails ? 'none' : ''}}>
                    <a onClick={() => add()}>
                        <Icon type="plus"/> Add Question
                    </a>
                </FormItem>
            </>;

        const categories = getFieldValue('categories');
        const categoryKeys = getFieldValue('categoryKeys');

        const formItemsWithCategory =
            <>
                {categories && categories.map((c, index) => {
                    return (
                        <>
                            <FormItem label={`Category ${c}`} {...formItemLayout} style={{display: isEditDetails ? 'none' : ''}}>
                                {getFieldDecorator(`category${c}`,{initialValue: questions[index] && questions[index].category})(
                                    <Input/>
                                )}
                            </FormItem>
                            {categoryKeys && categoryKeys[c-1] && categoryKeys[c-1].map((k, kIndex) => {
                                return (
                                    <FormItem
                                        {...formItemLayout}
                                        style={{display: isEditDetails ? 'none' : ''}}
                                        key={k}
                                        label={`Question ${kIndex + 1}`}>
                                        {getFieldDecorator(`category${c}questions[${k}]`, {initialValue: questions[index] && questions[index].questions[kIndex] && questions[index].questions[kIndex].question})(
                                            <Input.TextArea placeholder="Enter question here"
                                                            style={{width: '90%', marginRight: 3}}/>
                                        )}
                                        {
                                            <Button disabled={categoryKeys[c-1].length === 1}
                                                    onClick={() => removeCategoryKey(c, k)}
                                                    className='epar__action__danger--icon-buttons' type="danger"
                                                    shape="circle" icon="delete" size='small'/>
                                        }
                                    </FormItem>
                                );
                            })}
                            <FormItem wrapperCol={{offset: 18}} style={{display: isEditDetails ? 'none' : ''}}>
                                <a onClick={() => addCategoryKey(c)}>
                                    <Icon type="plus"/> Add Question
                                </a>
                            </FormItem>
                            <hr style={{display: isEditDetails ? 'none' : ''}}/>
                        </>
                    )
                })}
                <FormItem wrapperCol={{offset: 18}} style={{display: isEditDetails ? 'none' : ''}}>
                    <a onClick={() => addCategory()}>
                        <Icon type="plus"/> Add Category
                    </a>
                </FormItem>
            </>;

        const uploadProps = {
            accept: '.json',
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            fileList: [],
            beforeUpload: (file, fileList) => false,
            onChange: async ({file, fileList}) => {
                if (fileList.length === 1) {
                    let reader = new FileReader();
                    reader.readAsText(file);
                    reader.onloadend = () => {
                        try {
                            const data = JSON.parse(reader.result);
                            onFileLoad(data);
                        } catch (err) {
                            message.error(err.message, 10);
                        }
                    }
                    reader.onerror = function (event) {
                        console.log('event:', event);
                    };
                }
            }
        }
        const certificationTypes = AUDUBON_CERTIFICATIONS.map(i => <Option value={i.id}>{i.name}</Option>);

        const downloadContent = () => (
            Modal.info({
                title: "Download JSON Template",
                okText:'Cancel',
                content:(
                    <div>
                        <Button style={{marginBottom: 10}}>
                            <a href='/assets/CommonInspectionTemplate.json' download>Download Common Template</a>
                        </Button>
                        <Button>
                            <a href='/assets/CategoryInspectionTemplate.json' download>Download Category Template</a>
                        </Button>
                    </div>
                )
            })
        )

        return (
            <Modal
                visible={visible}
                title={modalTitle}
                okText="Save"
                onCancel={onCancel}
                onOk={onCreate}
            >
                <div style={{ display: isEditDetails || isEditQuestions ? 'none' : ''}} className="pure-u-1">
                    <div className="pure-g" style={{float: 'right'}}>
                        <Upload {...uploadProps} >
                            <Button className='epar__tasks__action--button'>
                                <Icon type="upload"/> Load from file
                            </Button>
                        </Upload>
                        <Button onClick={downloadContent} className='epar__tasks__action--button'>Download JSON Template</Button>
                    </div>
                </div>

                <Form layout="horizontal">
                    <FormItem label="Template Title" {...formItemLayout}
                              style={{display: isEditQuestions ? 'none' : ''}}>
                        {getFieldDecorator('templateTitle', { initialValue: templateTitle && templateTitle.value,
                            rules: [{required: true, message: 'Please enter a template title'}],
                        })(
                          <Input placeholder="Enter title of certification template"/>
                        )}
                    </FormItem>

                    <FormItem label="Certification Type" {...formItemLayout} >
                        {getFieldDecorator('certification_type', {
                            rules: [{
                                required: isCreate ? true : false,
                                message: 'Please select the certification type'
                            }],
                        })(
                            <Select
                                disabled={isCreate ? false : true}
                                showSearch
                                style={{width: '100%'}}
                                placeholder="Select certification type"
                                optionFilterProp="children"
                            >
                                {certificationTypes}
                            </Select>
                        )}
                    </FormItem>

                    <FormItem wrapperCol={{offset: 8}} style={{display: isEditDetails ? 'none' : ''}}>
                        {getFieldDecorator(`includeQuestionsFromCategories`, {initialValue: questionsType && questionsType === "withCategories", value: questionsType && questionsType === "withCategories", valuePropName: 'checked'})(
                            <Checkbox disabled={isEditQuestions}>Include questions from categories</Checkbox>
                        )}
                    </FormItem>

                    {form.getFieldValue(`includeQuestionsFromCategories`) ? formItemsWithCategory : formItems}

                    <Form.Item {...formItemLayout} label={"Is Preliminary Template"}>
                        {
                            getFieldDecorator('isPreliminary', {initialValue: isPreliminary === 1 ? true : false})(
                                <Switch checkedChildren="True" unCheckedChildren="False"
                                        defaultChecked={isPreliminary === 1 ? true : false}/>
                            )
                        }
                    </Form.Item>
                    {getFieldDecorator('categories', {initialValue: categoriesProp})}
                    {getFieldDecorator('categoryKeys', {initialValue: categoryKeysProp })}
                </Form>
            </Modal>
        );
    }
);

export class InspectionTemplateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalTitle: props.mode === 'create' ? 'Add Audubon Certification Template' : props.mode === 'edit_details' ? 'Edit Audubon Certification Template' : 'Edit Audubon Certification Template Questions',
            isEditDetails: props.mode === 'edit_details',
            isEditQuestions: props.mode === 'edit_questions',
            isCreate: props.mode === 'create',
            template: props.template,
            fields: {
                templateTitle: {
                    value: props.mode === 'create' ? '' : props.template.title
                },
                certification_type: {
                    value: props.mode === 'create' ? '' : props.template.templateType
                },
                isPreliminary: props.mode === 'create' ? '' : props.template.isPreliminary ? props.template.isPreliminary : '',
                questions: props.mode === 'create' ? [] : props.template.questions,
                questionsType: props.mode !== 'create' && props.template.questionsType,
                categoriesProp: this.props.mode === 'create' ? [1] : ((this.getCategories() && this.getCategories().categoriesProp) || [1]),
                categoryKeysProp: this.props.mode === 'create' ? [[1]] : ((this.getCategories() && this.getCategories().categoryKeysProp) || [[1]]),
            }
        }
    }

    getCategories = () =>{
        if (this.props.mode !== 'create' && this.props.template && this.props.template.questionsType === "withCategories") {
            let categories = [];
            let categoryKeysProp = [];
            this.props.template.questions.map((c, index) => {
                categories.push(index + 1);
                let tempArray = [];
                this.props.template.questions[index].questions.map((question, index) => {
                    tempArray.push(index+1);
                });
                categoryKeysProp.push(tempArray);
            })
            return {categoriesProp: categories, categoryKeysProp: categoryKeysProp};
        }
    }

    showModal = () => {
        this.setState({visible: true});
    }

    handleCancel = () => {
        this.props.callback();
    }

    handleCreateTask = () => {
        const form = this.form;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let questions = [];
            const keys = form.getFieldValue('keys');
            keys.map((k, index) => {
                questions.push(
                    {
                        questionId: index + 1,
                        question: form.getFieldValue(`questions[${k}]`),
                        type: "single",
                        answers: ["Yes", "No", "N/A"]
                    }
                );
                return undefined;
            });

            let categoryQuestion = [];
            const categories = form.getFieldValue("categories");
            const categoryKeys = form.getFieldValue("categoryKeys");
            categories.map((c,index)=>{
                let tempArray = [];
                categoryKeys[c-1].map((q, index)=>{
                    tempArray.push(
                        {
                            question: form.getFieldValue(`category${c}questions[${q}]`),
                            type: "single",
                            answers: ["Yes", "No", "N/A"],
                            questionId: index + 1,
                        })
                });
                categoryQuestion.push({category: form.getFieldValue(`category${c}`), questions: tempArray})
            });

            let template = {
                title: values.templateTitle,
                templateType: values.certification_type,
                questions: form.getFieldValue('includeQuestionsFromCategories') ? categoryQuestion : questions,
                isPreliminary: values.isPreliminary === true ? 1 : 0,
                ...form.getFieldValue('includeQuestionsFromCategories') ? {questionsType: "withCategories"} : {},
            };

            form.resetFields();
            this.props.callback(template);
        });
    }
    saveFormRef = (form) => {
        this.form = form;
    }

    onFileLoad = (data) => {
        let fields = this.state.fields;
        const  form = this.form;
        if (data.title) {
            fields.templateTitle = {value: data.title}
        }

        if (data.questionsType && data.questionsType === "withCategories") {
            fields.questionsType = "withCategories";
            if (data.questions) {
                try{
                    let categories = [];
                    let categoryKeysProp = [];
                    fields.questions = data.questions.map((obj, i) => {
                        categories.push(i + 1);
                        let tempArray = [];
                        const questionList = obj.questions.map((question, index) => {
                            tempArray.push(index+1);
                            return{
                                questionId: index + 1,
                                question: question,
                                type: "single",
                                answers: ["Yes", "No", "N/A"]
                            }
                        })
                        categoryKeysProp.push(tempArray);
                        return {
                            category: obj.category,
                            questions: questionList
                        }
                    })
                    fields.categoriesProp = categories.length > 0 ? categories : [1];
                    fields.categoryKeysProp = categoryKeysProp.length > 0 ? categoryKeysProp : [1];
                }
                catch(e){
                    message.error("Can't upload JSON template.");
                    return false;
                }
            }
        }
        else {
            if (data.questions) {
                fields.questions = data.questions.map((question, index) => {
                    return {
                        questionId: index + 1,
                        question: question,
                        type: "single",
                        answers: ["Yes", "No", "N/A"]
                    }
                });
            }
        }

        this.setState({fields});
        form.resetFields();
    }

    render() {
        let fields = this.state.fields;

        return (
            <div>
                <TemplateForm {...fields}
                              ref={this.saveFormRef}
                              visible={this.props.visible}
                              modalTitle={this.state.modalTitle}
                              onCancel={this.handleCancel}
                              onCreate={this.handleCreateTask}
                              isEditDetails={this.state.isEditDetails}
                              isEditQuestions={this.state.isEditQuestions}
                              isCreate={this.state.isCreate}
                              onFileLoad={this.onFileLoad}
                />
            </div>
        );
    }
}

export default InspectionTemplateForm;
