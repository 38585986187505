import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile, isTablet } from '../utils/common-utils';


class ConnectBreadcrumb extends Component {

    getBreacrumbPath() {
        if (this.props.path && this.props.path.length > 0){
            let path = [{
                route: '/',
                label: 'Home'
            }];

            path = path.concat(this.props.path);
            let items = [];

            path.forEach((v, i) => {
                if (i===0) {
                    items.push((<Link key={Math.random()} className='epar--link' to={v.route}>{v.label}</Link>));
                } else if (i < (path.length - 1)){
                    items.push((<Link key={Math.random()} className='epar--link' to={v.route}>&nbsp;&nbsp;/&nbsp;&nbsp;{v.label}</Link>));
                }
            });

            items.push((<span key={Math.random()}>&nbsp;&nbsp;/&nbsp;&nbsp;{path[path.length - 1].label}</span>));

            return items;
        }
    }

    render(){        
        if (this.props.path && this.props.path.length > 0) {
            if(isMobile() && !isTablet()){
                return (
                    <div className='breadcrumb__header'>
                        <div className='div__title'><span className='title__view'>{this.props.title}</span></div>
                    </div>
                );
            }else{
                return (
                    <div className='breadcrumb__header'>
                        <div className='div__title'><span className='path__view'>{this.getBreacrumbPath()}</span></div>
                        <div className='div__title'><span className='title__view'>{this.props.title}</span></div>
                    </div>
                );
            }
        } 
        else {
            return null;
        }

    }
}

const mapStateToProps = (state, props) => {
    return {
        title: state.getIn(["breadcrumbState", "title"]),
        path: state.getIn(["breadcrumbState", "path"])
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return {

    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ConnectBreadcrumb);