import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Tabs, Modal, Select } from 'antd';
import { withRouter } from 'react-router-dom';

import { getTemplateOrgList, addTemplateToOrg, getTemplate } from '../actions/jobPlannerActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import ConnectLoading from '../../../components/ConnectLoading';
import { getOrgList } from '../../../actions/commonActions';

import { ListWidget } from '../../../components/ListWidget';
import { OrgListItem } from '../JobPlannerListItemBuilder';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

class TemplateView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            template: undefined,
            addedOrgList: [],
            orgModalVisible: false,
        };
    }

    componentDidMount = () => {
        this.loadTemplateOrgs();
        this.props.getOrgList();
    }

    loadTemplateOrgs = async () => {
        const addedOrgList = await getTemplateOrgList(this.props.templateId);
        const template = await getTemplate(this.props.templateId);
        this.setState({ addedOrgList, template });

        this.props.setBreadcrumb(template.title, [
            { route: '/job_planner', label: 'Job Planner Templates' },
            { route: '', label: template.title },
        ]);

    }

    getOrgsForListView = () => {
        let orgList = this.state.addedOrgList.map((orgId) => {
            let builder = new OrgListItem.Builder(this.props.orgList.find(org => org.org_id === orgId));
            return builder.build();
        });
        return orgList;
    }

    onAddToOrgs = async () => {
        message.loading('Adding template...', 0);
        await Promise.all(this.state.selectedOrgs.map(async org => {
            await addTemplateToOrg(this.props.templateId, org.key, this.props.userId);
        }));
        this.setState({ orgModalVisible: false });
        this.loadTemplateOrgs();
        message.destroy();
        message.success('Template added successfully');

    }

    renderOrgView = () => {
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div className="pure-g" >
                        <div className="pure-u-1">
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.setState({ orgModalVisible: true })}>Add to organisation(s)</Button>
                        </div>
                    </div>
                    <div>
                        <ListWidget data={this.getOrgsForListView()} loading={false} />
                    </div>
                </div>
            </div>
        );
    };

    renderOrgModal = () => {
        return (
            <Modal
                title="Add to Organisation(s)"
                visible={this.state.orgModalVisible}
                onOk={this.onAddToOrgs}
                onCancel={() => this.setState({ selectedOrgs: [], orgModalVisible: false })}
            >
                <Select mode="multiple" style={{ width: '100%' }} onChange={(value, selectedOrgs) => this.setState({ selectedOrgs })}>
                    {this.props.orgList.filter(org=>!this.state.addedOrgList.includes(org.org_id)).map(org => <Option key={org.org_id} value={`${org.name} - ${org.org_code}`}>{org.name}</Option>)}
                </Select>
            </Modal>
        )
    }


    render() {
        if (!this.state.template) {
            return <ConnectLoading />;
        }
        return (
            <div>
                {this.state.orgModalVisible && this.renderOrgModal()}
                <Tabs className='epar__details__view'>
                    <TabPane tab="Organisations" key="1">
                        {this.renderOrgView()}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
        orgList: state.getIn(['orgState', 'orgList']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateView));
