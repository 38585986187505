import { getApi, ConnectAdminApi, putApi, postApi, ConnectApi } from "../../../utils/api-utils";
import { message } from "antd";
import { CHEMICAL_ACTIONS } from '../actions/actionTypes';

export function loadChemicalList() {
    return async function (dispatch, getState) {
        try {
            const result = await getApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemicals');
            await dispatch({
                type: CHEMICAL_ACTIONS.CHEMICAL_LIST,
                payload: result
            });
        } catch (e) {
            console.log(e);
            message.error("Error in getting chemical list");
        }
    }
}


export async function getChemicalInfo(chemicalId) {
    try {
        const result = await getApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemical_info', { queryStringParameters: { chemicalId } });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function updateChemicalDetails(chemicalId, chemicalInfo) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemical_info', { chemicalId, chemicalInfo });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function addNewChemical(chemicalInfo) {
    try {
        const result = await postApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemical_info', { chemicalInfo });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function updateChemicalLocale(chemicalId, locale) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemical_locale', { chemicalId, locale });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function getChemicalFileList() {
    try {
        const result = await getApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemicals_files');
        return new Set(result.fileList);
    } catch (e) {
        console.log(e);
    }
}

export async function addNewChemicals(chemicalList) {
    try {
        const all = chemicalList.length;
        let done = 0;
        let i, j, chunk = 10;
        for (i = 0, j = chemicalList.length; i < j; i += chunk) {
            let temparray = chemicalList.slice(i, i + chunk);
            done += temparray.length;
            await postApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemicals', { chemicalList: temparray });
            message.success(`Added ${done} of ${all} chemicals`);
        }
        return done;
    } catch (e) {
        console.log(e);
    }
}

export async function updateChemicalSDSExpiry(chemicalId, locale, expireDate) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/epar_chemical_sds_expiry', { chemicalId, expireDate, locale });
        return result;
    } catch (e) {
        console.log(e);
    }
}


export async function addChemicalToOu(chemical, chemicalId, orgId) {
    const url = `/${orgId}/assign/${chemicalId}`;
    const result = await postApi(ConnectApi.CHEMICAL_API, url, chemical);
    if (!result.error) {
        return;
    } else {
        console.error(`Error adding chemical`, result.error);
        return undefined;
    }
}

export async function archiveChemical(chemicalId) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/archive', { chemicalId });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function unArchiveChemical(chemicalId) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/unarchive', { chemicalId });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function discontinueChemical(chemicalId) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/discontinue', { chemicalId });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function continueChemical(chemicalId) {
    try {
        const result = await putApi(ConnectAdminApi.CHEMICAL_API, '/unarchive', { chemicalId });
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function openS3DocumentByURL(apiUrl) {
    await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/pdf'
        },
    })
    .then( res => res.blob())
    .then( blob => {
      var url = new Blob([blob], {type: 'application/pdf'});
      var file = window.URL.createObjectURL(url);
      window.open(file);
    });
}

export async function getProductAssignedOuList (productId) {
    try {
        const url = `/ou_list/${productId}`;
        const result = await getApi(ConnectAdminApi.CHEMICAL_API,url);
        return result;
    } catch (e){
        console.log(e);
        message.error("Error in getting product assigned ou list");
    }
}