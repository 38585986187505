import React from 'react';
import { Form, Input, Select, Card, Tooltip } from 'antd';
import { FREQUENCIES, CATEGORIES} from '../../constants' 

const FormItem = Form.Item;
const Option = Select.Option;

const CourseDetailsForm = Form.create({
  onValuesChange(props, changedValues, allValues){
    props.onChange(allValues);
  }
})((props) => {
  const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  props.formRef(props.form);

  return (
    <Card title="Course Details">
      <Form layout="horizontal">
          <FormItem label="Course Title" {...formItemLayout} >
              {getFieldDecorator('title', {
                  rules: [ {required: true, message: 'Please input a valid course title'}],
                  initialValue: props.title
              }
              )(
                <Input placeholder='Enter short course title' />
              )}
          </FormItem>
          
          <FormItem label="Category" {...formItemLayout}>
            {getFieldDecorator('category', {
              rules: [{ required: true, message: 'Please select a category' }],
                  initialValue: props.category
            })(
                <Select placeholder="Select category">
                    {Object.entries(CATEGORIES).map(([key, val]) => {
                      return <Option key={key} value={key}>{val}</Option>
                    })}
                </Select>
            )}
          </FormItem>
          <FormItem label="Review frequency" {...formItemLayout}>          
            <Tooltip 
                title='The frequency is how often you want the course to be reviewed/completed by people you assign it to (eg: annually).'
                placement='top'>
              {getFieldDecorator('frequency', {
                rules: [{ required: true, message: 'Please select a frequency' }],
                initialValue: props.frequency
              })(
                  <Select placeholder="Select frequency" >
                      {Object.entries(FREQUENCIES).map(([key, val]) => {
                        return <Option key={key} value={key}>{val}</Option>
                      })}
                  </Select>
              )}              
            </Tooltip>
          </FormItem>

      </Form>
    </Card>
  );
});

export default CourseDetailsForm;