import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../actions/commonActions';
import AdminDashboard from '../views/dashboard/containers/AdminDashboard';


class HomePage extends Component {
  constructor(props) {
    super(props);
    props.setSelectedMenu(['dashboard']);
  }

  render() {
    return (
      <div data-testid='homepage'>
        <AdminDashboard />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
