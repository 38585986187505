import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Tabs, Card, Button, Upload, Icon, message, Modal } from 'antd';

import ConnectLoading from '../../../components/ConnectLoading';
import { setBreadcrumb } from '../../../actions/commonActions';
import { getEquipmentInfo, updateEquipmentLocale, addEquipmentToOu, openS3DocumentByURL, getEquipmentAssignedOuList } from '../actions/equipmentActions';
import DetailsTable from '../../../components/DetailsTable';
import OrganisationTreeView from '../../../components/OrganisationTreeView';

import { CONFIG } from '../../../config/app-config';
import EditEquipmentModal from './EditEquipmentModal';
import { uploadFile } from '../../../utils/common-utils';
import OuMultiSelector from '../../../components/OuMultiSelector';
import { EQUIPMENT_QR } from '../constants';

const TabPane = Tabs.TabPane;
const RECORD_STATUS_ACTIVE = "1";

class EquipmentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chemicalInfo: undefined,
            isEditEquipmentVisible: false,
            ouModalVisible: false,
            selectedOus:[],
            assignedOuList: []
        }
        this.loadEquipmentInfo();

    }

    loadEquipmentInfo = async () => {
        const orgUnitList = await getEquipmentAssignedOuList(this.props.equipmentId);
        if(orgUnitList) {
            this.setState({assignedOuList: orgUnitList});
        }
        const equipmentInfo = await getEquipmentInfo(this.props.equipmentId);
        equipmentInfo.localeSet = new Set(equipmentInfo.locale ? equipmentInfo.locale.split(",") : []);
        if (equipmentInfo) {
            this.setState({ equipmentInfo });
            const name = equipmentInfo.name;
            this.props.setBreadcrumb(name, [
                { route: '/equipment', label: 'Equipment' },
                { route: '', label: name }
            ]);
        }
    }

    isActiveEquipment = () =>{
        return this.state.equipmentInfo && this.state.equipmentInfo.record_status===RECORD_STATUS_ACTIVE;
    }

    equipmentDetailsView = () => {
        const equipmentInfo = this.state.equipmentInfo;
        let fields = [
            { key: "Model ", value: equipmentInfo.name },
            { key: "Manufacturer", value: equipmentInfo.manufacturer_name },
            { key: "Model Number", value: equipmentInfo.model_no },
            { key: "Serial Range", value: equipmentInfo.serial_no },
            { key: "Competency Timeframe", value: equipmentInfo.competency_timeframe },
            { key: "Country", value: equipmentInfo.country_code==='AUS'?'Australia':equipmentInfo.country_code==='USA'?'United States':'' },
            { key: "QR code", value: equipmentInfo.qr_code_status === EQUIPMENT_QR.ENABLED ? 'Enabled' : 'Disabled' },
        ];
        return (
            <div className="pure-g">                
                {this.renderOuModal()}  
                {this.state.isEditEquipmentVisible &&
                    <EditEquipmentModal
                        visible={this.state.isEditEquipmentVisible}
                        onCancel={() => this.setState({ isEditEquipmentVisible: false })}
                        onSave={() => {
                            this.loadEquipmentInfo();
                            this.setState({ isEditEquipmentVisible: false })
                        }}
                        equipmentInfo={equipmentInfo}
                    />
                }
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        {this.isActiveEquipment() && 
                            <div className="pure-u-1">
                                <Button icon="edit" className='epar__tasks__action--button' onClick={() => this.setState({ isEditEquipmentVisible: true })}>Edit Equipment Details</Button>
                                <Button icon="add" className='epar__tasks__action--button' onClick={() => this.setState({ ouModalVisible: true })}>Add to OUs</Button>
                            </div>
                        }
                    </div>
                    <div>
                        <div className="pure-u-1">
                            <DetailsTable dataSource={fields} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getViewOption = (locale) => {
        const equipmentInfo = this.state.equipmentInfo;
        const uploadProps = {
            accept: '.pdf',
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            fileList: [],
            beforeUpload: (file, fileList) => false,
            onChange: async ({ file, fileList }) => {
                const fileName = `${equipmentInfo.equipment_id}_${locale}.pdf`;
                if (fileList.length === 1) {
                    if (equipmentInfo.localeSet.has(locale)) {
                        message.loading('Updating manual please wait...', 0);
                        await uploadFile(file, fileName, CONFIG.equipmentFilesBucket);
                        message.destroy();
                        message.success('Manual updated successfully');
                    } else {
                        message.loading('Adding manual please wait...', 0);
                        const newLocale = equipmentInfo.locale ? `${equipmentInfo.locale},${locale}` : locale;
                        const result = await updateEquipmentLocale(equipmentInfo.equipment_id, newLocale);
                        if (result) {
                            await uploadFile(file, fileName, CONFIG.equipmentFilesBucket);
                            message.destroy();
                            message.success('New manual added successfully');
                        } else {
                            message.destroy();
                            message.error('Fail to add manual. Please try again');
                        }
                    }
                    this.loadEquipmentInfo();
                }
            }
        };
        if (equipmentInfo.localeSet.has(locale)) {
            return (
                <div>
                    <Button><a className='epar--link' onClick={()=>openS3DocumentByURL(`${CONFIG.s3HostUrl}/${CONFIG.equipmentFilesBucket}/${equipmentInfo.equipment_id}_${locale}.pdf`)} rel="noopener noreferrer" target="_blank">View</a></Button>
                    {this.isActiveEquipment() && 
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Update
                            </Button>
                        </Upload>
                    }
                </div>
            );
        } else {
            return this.isActiveEquipment() ?
                <Upload {...uploadProps}>
                    <Button>
                        <Icon type="upload" /> Add Manual
                    </Button>
                </Upload> : null            
        }

    }

    equipmentManualView = () => {
        let fields = [
            { key: "EN ", value: this.getViewOption('EN') },
            { key: "EN_AU ", value: this.getViewOption('EN_AU') },
            { key: "ES ", value: this.getViewOption('ES') },
        ];
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div>
                        <div className="pure-u-1">
                            <DetailsTable dataSource={fields} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onAddToOrg = async() => {
        message.loading("Adding equipment to selected ous...", 0);
        const newEquipment = {
            createdBy: this.props.userId,
            storageLocation: '',
            workAreaOfUse: '',
            competencyTimeFrame: this.state.equipmentInfo.competency_timeframe,
        };
        await Promise.all(this.state.selectedOus.map(async ou=> await addEquipmentToOu({...newEquipment, orgCode:ou.orgCode, ouId:ou.ouId}, this.props.equipmentId, ou.orgId)));
        this.setState({ selectedOus: [], ouModalVisible: false });        
        message.destroy();
        message.success("Equipment added successfully");
    }

    renderOuModal = () => {
        return (
            <Modal
                title="Add to Organisation Unit(s)"
                visible={this.state.ouModalVisible}
                onOk={this.onAddToOrg}
                onCancel={() => this.setState({ selectedOus: [], ouModalVisible: false })}
            >
                <OuMultiSelector 
                    onValue={selectedOus=>this.setState({ selectedOus })} 
                    selectedOus={this.state.selectedOus} 
                    countryCode={this.state.equipmentInfo.country_code}
                    />
            </Modal>
        )
    }

    render() {
        if (!this.state.equipmentInfo) {
            return <ConnectLoading />
        }
        return (
            <Card>
                <Tabs className='epar__details__view'>
                    <TabPane tab="Equipment Details" key="0">
                        {this.equipmentDetailsView()}
                    </TabPane>
                    <TabPane tab="Operator Manual" key="1">
                        {this.equipmentManualView()}
                    </TabPane>
                    <TabPane tab="Organisation Units" key="2">
                        <OrganisationTreeView assignedOuList={this.state.assignedOuList}/>
                    </TabPane>
                </Tabs>
            </Card>
        )

    }
}

function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EquipmentView);