import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Tabs, Card, Button, Icon, message, Tooltip, Row, Modal, Tag } from 'antd';
import moment from 'moment';

import ConnectLoading from '../../../components/ConnectLoading';
import { setBreadcrumb } from '../../../actions/commonActions';
import { getChemicalInfo, updateChemicalLocale, updateChemicalSDSExpiry, addChemicalToOu, openS3DocumentByURL, getProductAssignedOuList } from '../actions/chemicalActions';
import DetailsTable from '../../../components/DetailsTable';
import { CONFIG } from '../../../config/app-config';
import EditChemicalModal from './EditChemicalModal';
import { NewSDSModal } from './NewSDSModal';
import { uploadFile } from '../../../utils/common-utils';
import OuMultiSelector from '../../../components/OuMultiSelector';
import OrganisationTreeView from '../../../components/OrganisationTreeView';

const TabPane = Tabs.TabPane;
const dateFormat = 'YYYY-MM-DD';
const RECORD_STATUS_ACTIVE = "1";

class ChemicalView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chemicalInfo: undefined,
            isEditChemicalVisible: false,
            isAddSDSVisible: false,
            locale: undefined,
            isExpiryEdit: false,
            ouModalVisible: false,
            selectedOus: [],
            assignedOuList: []
        }
        this.loadChemicalInfo();

    }

    loadChemicalInfo = async () => {
        const orgUnitList = await getProductAssignedOuList(this.props.chemicalId);
        if(orgUnitList) {
            this.setState({assignedOuList: orgUnitList});
        }
        const { chemicalInfo, sdsExpiryInfo } = await getChemicalInfo(this.props.chemicalId);
        chemicalInfo.localeSet = new Set(chemicalInfo.locale ? chemicalInfo.locale.split(",") : []);
        if (chemicalInfo) {
            this.setState({ chemicalInfo, sdsExpiryInfo });
            const name = chemicalInfo.product_name;
            this.props.setBreadcrumb(name, [
                { route: '/chemicals', label: 'Chemicals' },
                { route: '', label: name }
            ]);
        }
    }

    isActiveChemical = () =>{
        return this.state.chemicalInfo && this.state.chemicalInfo.record_status===RECORD_STATUS_ACTIVE;
    }

    chemicalDetailsView = () => {
        const chemicalInfo = this.state.chemicalInfo;
        let fields = [
            { key: "Product Identifier", value: chemicalInfo.product_name },
            { key: "Manufacturer", value: chemicalInfo.manufacturer_name },
            { key: "Active Ingredient", value: chemicalInfo.active_ingredient },
            { key: "Active Group", value: chemicalInfo.group },
            { key: "Formulation", value: chemicalInfo.formulation },
            { key: "Country", value: chemicalInfo.country_code==='AUS'?'Australia':chemicalInfo.country_code==='USA'?'United States':'' },
        ];
        return (
            <div className="pure-g">
                {this.state.isEditChemicalVisible &&
                    <EditChemicalModal
                        visible={this.state.isEditChemicalVisible}
                        onCancel={() => this.setState({ isEditChemicalVisible: false })}
                        onSave={() => {
                            this.loadChemicalInfo();
                            this.setState({ isEditChemicalVisible: false })
                        }}
                        chemicalInfo={chemicalInfo}
                    />
                }                
                {this.renderOuModal()}
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        {this.isActiveChemical() &&
                            <div className="pure-u-1">
                                <Button icon="edit" className='epar__tasks__action--button' onClick={() => this.setState({ isEditChemicalVisible: true })}>Edit Chemical Details</Button>
                                <Button icon="add" className='epar__tasks__action--button' onClick={() => this.setState({ ouModalVisible: true })}>Add to OUs</Button>
                            </div>
                        }
                    </div>
                    <div>
                        <div className="pure-u-1">
                            <DetailsTable dataSource={fields} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getViewOption = (locale) => {
        const chemicalInfo = this.state.chemicalInfo;
        const fileName = `${chemicalInfo.chemical_id}_${locale}.pdf`;
        const hasLocale = chemicalInfo.localeSet.has(locale);
        const expiryInfo = this.state.sdsExpiryInfo.find(item => item.locale === locale);
        const expiryDate = expiryInfo ? expiryInfo.sds_expire_date : undefined;
        return (
            <div>
                <Row>
                    {hasLocale && <Button style={{marginRight:'5px'}}><a className='epar--link' onClick={()=>openS3DocumentByURL(`${CONFIG.s3HostUrl}/${CONFIG.chemicalFilesBucket}/${fileName}`)}  rel="noopener noreferrer" target="_blank">View</a></Button>}
                    {this.isActiveChemical() && 
                        <Button onClick={() => this.setState({ isAddSDSVisible: true, locale: locale, isExpiryEdit: false })}>
                            <Icon type="upload" /> {hasLocale ? 'Update' : 'Add Manual'}
                        </Button>    
                    }       
                    {hasLocale &&
                        <span style={{marginLeft:'5px'}}>
                            Expiry date : {expiryDate ? moment(expiryDate).format(dateFormat) : 'Not Set'}
                    <Tooltip title="Edit expiry date">{this.isActiveChemical() && <Button onClick={() => this.setState({ isAddSDSVisible: true, locale: locale, isExpiryEdit: true })} style={{ border: "none", boxShadow: "none" }} icon="edit" />}</Tooltip>
                        </span>
                    }
                    {expiryDate && moment(expiryDate).isBefore(new Date()) && <Tag style={{float: 'right'}} color='red'>SDS Expired</Tag>}     
                </Row>
            </div>
        );
    }

    sdsView = () => {
        let fields = [
            { key: "EN ", value: this.getViewOption('EN') },
            { key: "EN_AU ", value: this.getViewOption('EN_AU') },
            { key: "ES ", value: this.getViewOption('ES') }
        ];
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div>
                        <div className="pure-u-1">
                            <DetailsTable dataSource={fields} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onAddSDSFile = async (locale, file, expiryDate, isExpiryEdit) => {
        const chemicalInfo = this.state.chemicalInfo;
        if (isExpiryEdit) {
            message.loading('Updating expiry date please wait...', 0);
            await updateChemicalSDSExpiry(chemicalInfo.chemical_id, locale, expiryDate);
            message.destroy();
            message.success('Expiry date updated successfully');

        } else {
            const fileName = `${chemicalInfo.chemical_id}_${locale}.pdf`;
            if (chemicalInfo.localeSet.has(locale)) {
                message.loading('Updating SDS please wait...', 0);
                await uploadFile(file, fileName, CONFIG.chemicalFilesBucket);
                await updateChemicalSDSExpiry(chemicalInfo.chemical_id, locale, expiryDate);
                message.destroy();
                message.success('SDS updated successfully');
            } else {
                message.loading('Adding SDS please wait...', 0);
                const newLocale = chemicalInfo.locale ? `${chemicalInfo.locale},${locale}` : locale;
                const result = await updateChemicalLocale(chemicalInfo.chemical_id, newLocale);
                if (result) {
                    await uploadFile(file, fileName, CONFIG.chemicalFilesBucket);
                    await updateChemicalSDSExpiry(chemicalInfo.chemical_id, locale, expiryDate);
                    message.destroy();
                    message.success('New SDS added successfully');
                } else {
                    message.destroy();
                    message.error('Fail to add SDS. Please try again');
                }
            }
        }
        this.setState({ isAddSDSVisible: false });
        this.loadChemicalInfo();
    }

    onAddToOrg = async () => {
        message.loading("Adding chemical to selected ous...", 0);
        let chemical = {
            createdBy: this.props.userId,
            storageLocation: '',
            workAreaOfUse: '',
            formulation: this.state.chemicalInfo.formulation,
            activeIngredient: this.state.chemicalInfo.active_ingredient,
            activeGroup: this.state.chemicalInfo.group,
        };
        let selectedOuArr = [];
        let alreadyAssignedOuArr = [];
        this.state.selectedOus.map(ou => {
            let check = this.state.assignedOuList.find(item => item.org_unit_id === ou.ouId);
            if(check) {
                alreadyAssignedOuArr.push(check.ou_name);
            } else {
                selectedOuArr.push(ou);
            }
        })

        if(alreadyAssignedOuArr.length) {
            message.destroy();
            message.warning(`This Chemical has already added to the ${alreadyAssignedOuArr.map(item=> `${item}`)} organization unit(s)`)
            await new Promise(resolve => setTimeout(resolve, 3000))
        }
        if(selectedOuArr.length) {
            await Promise.all(selectedOuArr.map(async ou => await addChemicalToOu({ ...chemical, orgCode: ou.orgCode, ouId: ou.ouId }, this.props.chemicalId, ou.orgId)));
            this.setState({ selectedOus: [], ouModalVisible: false });
            message.destroy();
            message.success("Chemical added successfully");
        }
    }

    renderOuModal = () => {
        return (
            <Modal
                title="Add to Organisation Unit(s)"
                visible={this.state.ouModalVisible}
                onOk={this.onAddToOrg}
                onCancel={() => this.setState({ selectedOus: [], ouModalVisible: false })}
            >
                <OuMultiSelector
                    onValue={selectedOus => this.setState({ selectedOus })}
                    selectedOus={this.state.selectedOus}
                    countryCode={this.state.chemicalInfo.country_code}
                    assignedOuList={this.state.assignedOuList}
                />
            </Modal>
        )
    }

    render() {
        if (!this.state.chemicalInfo) {
            return <ConnectLoading />
        }
        return (
            <Card>
                {this.state.isAddSDSVisible &&
                    <NewSDSModal
                        visible={this.state.isAddSDSVisible}
                        locale={this.state.locale}
                        dateFormat={dateFormat}
                        onCancel={() => this.setState({ isAddSDSVisible: false })}
                        onOk={this.onAddSDSFile}
                        isExpiryEdit={this.state.isExpiryEdit}
                    />
                }
                <Tabs className='epar__details__view'>
                    <TabPane tab="Chemical Details" key="0">
                        {this.chemicalDetailsView()}
                    </TabPane>
                    <TabPane tab="Safety Data Sheets" key="1">
                        {this.sdsView()}
                    </TabPane>
                    <TabPane tab="Organisation Units" key="2">
                        <OrganisationTreeView assignedOuList={this.state.assignedOuList}/>
                    </TabPane>
                </Tabs>
            </Card>
        )

    }
}

function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChemicalView);