import React, { Component } from 'react';
import { Modal, message, DatePicker, Upload, Icon, Button, Form } from 'antd';
import moment from 'moment';

export class NewSDSModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            expiryDate: moment().add(5, "years").format(this.props.dateFormat)
        }
    }

    render() {
        const uploadProps = {
            accept: '.pdf',
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            fileList: this.state.fileList,
            beforeUpload: (file, fileList) => false,
            onChange: async ({ file, fileList }) => {
                this.setState({ fileList: [file] });
            },
            onRemove: () => this.setState({ fileList: [] })
        };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        return (
            <Modal
                title={this.props.isExpiryEdit ? `Edit Expiry Date - ${this.props.locale}` : `Update SDS file - ${this.props.locale}`}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={async () => {
                    if (this.state.fileList.length === 0 && !this.props.isExpiryEdit) {
                        message.warn('Please select a SDS file.');
                        return;
                    }
                    if (!this.state.expiryDate || this.state.expiryDate === null) {
                        message.warn('Please select an expiry date.');
                        return;
                    }
                    this.props.onOk(this.props.locale, this.state.fileList[0], this.state.expiryDate, this.props.isExpiryEdit);
                }}>
                <Form>
                    {!this.props.isExpiryEdit &&
                        <Form.Item
                            {...formItemLayout}
                            label="Select SDS File"
                        >
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Select SDS File
                            </Button>
                            </Upload>
                        </Form.Item>
                    }
                    <Form.Item
                        {...formItemLayout}
                        label="Expiry Date"
                    >
                        <DatePicker
                            value={moment(this.state.expiryDate, this.props.dateFormat)}
                            format={this.props.dateFormat}
                            onChange={(expiryDateMoment, expiryDate) => this.setState({ expiryDate })}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}