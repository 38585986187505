import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Icon, Col, Upload, Row, Button, Divider, Radio, Switch, Modal, List, Select } from 'antd';
import OrgOUMultiSelector from '../../../components/OrgOUMultiSelector';
import { getOrgNationalMapDetails, removeNationalMapSite, saveNationalMapSite, updateOrgCustomFeatures } from '../actions/orgActions';
import { CONNECT_CUSTOM_FEATURES_STATUS, NATIONAL_MAP_INSTANCES, NATIONAL_MAP_MAX_IMAGE_SIZE, SITE_TYPE } from '../constants';
import ConnectLoading from '../../../components/ConnectLoading';
import NationalMapDetailView from './NationalMapDetailView';
import Map from '../../../components/Map';
import { compressImage } from '../../../utils/common-utils';

const FormItem = Form.Item;
const { Option } = Select;
const NATIONAL_MAP_CUSTOM_FEATURES_KEY = 'NATIONAL_MAP';
const DEFAULT_INSTANCE_TYPE = 'GOLF';

class NationalMapSiteDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false,
            instanceType: DEFAULT_INSTANCE_TYPE,
            isLoading: true,
            isNationalMapEnabled: false,
            isShowOuSelector: false,
            showDrawMapAreaModal: false,
            nationalMapSiteType: undefined,
            profileImage: undefined,
            bannerImage: undefined,
            climateChampionImage: undefined,
            nationalMapSites: [],
            selectedSite: undefined,
            selectedOus: [],
            ouIds: [],
            polygonFeatures: [],
            geometry: undefined
        }
    }

    componentDidMount = async () => {
        await this.loadNationalMapDetails();
    }

    loadNationalMapDetails = async (isSaveSite = false) => {
        const { orgCustomFeatures } = this.props.orgInfo;
        const isNationalMapEnabled = orgCustomFeatures.length > 0 && orgCustomFeatures.includes(NATIONAL_MAP_CUSTOM_FEATURES_KEY);
        if (isNationalMapEnabled || isSaveSite) {
            const nationalMapSites = await getOrgNationalMapDetails(this.props.orgId);
            if (nationalMapSites.length > 0) {
                const nationalMapSite = nationalMapSites[0];
                const { siteType, instanceType } = nationalMapSite;
                const selectedSite = siteType === SITE_TYPE.ORG_LEVEL ? nationalMapSite : undefined;
                this.setState({
                    nationalMapSiteType: siteType,
                    instanceType,
                    selectedSite,
                    isNationalMapEnabled: isSaveSite || isNationalMapEnabled,
                    nationalMapSites,
                    ouIds: []
                });
            }
            else {
                this.setState({
                    nationalMapSiteType: undefined,
                    selectedSite: undefined,
                    isNationalMapEnabled: false,
                    nationalMapSites: []
                });
            }
        }
        this.setState({ isLoading: false });
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.setState({
            nationalMapSiteType: undefined,
            bannerImage: undefined,
            profileImage: undefined,
            climateChampionImage: undefined,
            savingChanges: false,
            isShowOuSelector: false,
            instanceType: DEFAULT_INSTANCE_TYPE,
            selectedOus: [],
            ouIds: [],
            geometry: undefined
        });
    }

    handleSubmit = () => {
        const { form, orgInfo, orgId } = this.props;
        this.setState({ savingChanges: true });
        form.validateFields(async (err, values) => {
            if (err) {
                this.setState({ savingChanges: false });
                return;
            }
            const { siteName, shortDescription, siteDescription, website, contactNumber, climateChampionName, climateChampionDescription, traditionalOwners } = values;
            const { profileImage, bannerImage, climateChampionImage, nationalMapSiteType, ouIds } = this.state;
            const climateChampions = [];

            if (climateChampionName) {
                climateChampions.push({ climateChampionName, climateChampionDescription })
            }
            
            const nationalMapSite = {
                properties: {
                    siteName,
                    shortDescription,
                    siteDescription,
                    website,
                    contactNumber,
                    traditionalOwners
                },
                team: { climateChampions },
                ouIds,
                instanceType: this.state.instanceType,
                geometry: this.state.geometry,
                siteType: nationalMapSiteType,
                orgCode: orgInfo.org_code
            }
            const customFeatureInfo = {
                status: CONNECT_CUSTOM_FEATURES_STATUS.ENABLE,
                feature: NATIONAL_MAP_CUSTOM_FEATURES_KEY,
            }
            await saveNationalMapSite(orgId, nationalMapSite, { profileImage, bannerImage, climateChampionImage });
            await updateOrgCustomFeatures(orgId, customFeatureInfo);
            this.handleCancel();
            await this.loadNationalMapDetails(true);
        });

    }

    beforeUploadProfileImage = async (imageFile) => {
        imageFile = await compressImage(imageFile, NATIONAL_MAP_MAX_IMAGE_SIZE);
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            imageFile.url = reader.result;
            this.setState({ profileImage: imageFile });
        }
    }

    beforeUploadBannerImage = async (imageFile) => {
        imageFile = await compressImage(imageFile, NATIONAL_MAP_MAX_IMAGE_SIZE);
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            imageFile.url = reader.result;
            this.setState({ bannerImage: imageFile });
        }
    }

    beforeUploadClimateChampionImage = async (imageFile) => {
        imageFile = await compressImage(imageFile, NATIONAL_MAP_MAX_IMAGE_SIZE);
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
            imageFile.url = reader.result;
            this.setState({ climateChampionImage: imageFile });
        }
    }

    handleSelectOus = (ous) => {
        this.setState({ selectedOus: ous })
        const ouIds = [];
        ous.map(ou => ouIds.push(ou.ouId))
        this.setState({ ouIds });
    }

    handleDelete = async (siteId, instanceType) => {
        const orgId = this.props.orgId;
        const current = this;
        Modal.confirm({
            title: 'Confirm',
            okText: 'Yes',
            cancelText: 'No',
            content: (
                <div>
                    <p>{`Are you sure you want to delete this National Map site?`}</p>
                </div>
            ),
            async onOk() {
                await removeNationalMapSite(orgId, siteId, instanceType);
                if (current.state.nationalMapSites.length <= 1) {
                    const customFeatureInfo = {
                        status: CONNECT_CUSTOM_FEATURES_STATUS.DISABLE,
                        feature: NATIONAL_MAP_CUSTOM_FEATURES_KEY,
                    }
                    await updateOrgCustomFeatures(orgId, customFeatureInfo);
                }
                await current.loadNationalMapDetails();
            },
            async onCancel() {
                console.log('Canceled');
            },
        });
    }

    onEnableDisableSite = (status) => {
        const orgId = this.props.orgId;
        const current = this;
        Modal.confirm({
            title: 'Confirm',
            okText: 'Yes',
            cancelText: 'No',
            content: (
                <div>
                    <p>{`Are you sure you want to ${status ? 'enable' : 'disable'} National Map?`}</p>
                    {
                        !status &&
                        <p><b>This action will remove all the National Map sites under this organisation</b></p>
                    }
                </div>
            ),
            async onOk() {
                if (status) {
                    current.setState({
                        isNationalMapEnabled: !current.state.isNationalMapEnabled,
                        nationalMapSiteType: undefined
                    });
                }
                else {
                    if (current.state.nationalMapSites.length > 0) {
                        const customFeatureInfo = {
                            status: CONNECT_CUSTOM_FEATURES_STATUS.DISABLE,
                            feature: NATIONAL_MAP_CUSTOM_FEATURES_KEY,
                        }
                        await Promise.all(current.state.nationalMapSites.map(async site => {
                            await removeNationalMapSite(orgId, site.siteId, site.instanceType);
                        }));
                        await updateOrgCustomFeatures(orgId, customFeatureInfo);
                    }
                    current.setState({
                        isNationalMapEnabled: false,
                        nationalMapSiteType: undefined
                    });
                    await current.loadNationalMapDetails();
                }
            },
            async onCancel() {
                console.log('Canceled');
            },
        });
    }

    onMapAreaDraw = (data) => {
        const polygonFeatures = data.features;
        this.setState({ polygonFeatures });
    }

    onMapAreaDelete = (data) => {
        const polygonFeatures = data.features;
        this.setState({ polygonFeatures });
    }

    handleCancelDrawMapArea = () => {
        this.setState({ showDrawMapAreaModal: false });
    }
    
    handleDrawMapArea = () => {
        if(this.state.polygonFeatures.length) {
            let geometry = this.state.polygonFeatures[0].geometry;

            // change polygon format to display in NM side
            if (geometry.type === 'Polygon') {            
                const multiPolygon = {
                    type: "MultiPolygon",
                    coordinates: [geometry.coordinates]
                };
                this.setState({ geometry: multiPolygon });
            } else {
                this.setState({ geometry });
            }
        }
        
        
        this.handleCancelDrawMapArea();
    }

    orgOuSelector = () => {
        return (
            <div>
                {(this.state.isShowOuSelector && this.state.nationalMapSiteType === SITE_TYPE.OU_LEVEL) &&
                    <Row className="epar_national_map_row">
                        <p className="epar_national_map_label">Organization Unit :</p>
                        <Col sm={24} md={12}>
                            <OrgOUMultiSelector
                                onValue={selectedOus => this.handleSelectOus(selectedOus)}
                                orgId={this.props.orgId}
                                selectedOus={this.state.selectedOus}
                                isNationalMapForm={true}
                            />
                        </Col>
                    </Row>
                }
            </div>
        );
    }

    orgGroupListView = () => {
                return (
            <div>
                {
                    (this.state.nationalMapSiteType === SITE_TYPE.OU_LEVEL && this.state.nationalMapSites.length > 0) &&
                    <Col className="epar_national_map_row" sm={24} md={12}>
                        <p className="epar_national_map_label">Organization Unit Groups :</p>
                        <List
                            size="small"
                            style={{ padding: 0 }}
                            bordered
                            dataSource={this.state.nationalMapSites}
                            renderItem={item => (
                                <List.Item className='epar_national_map_ou-group' onClick={() => this.setState({ selectedSite: item })}>
                                    <div>{item.properties.siteName}</div>
                                    <div>
                                        <Icon className='epar_national_map_delete-icon'
                                            type="delete"
                                            onClick={() => this.handleDelete(item.siteId, item.instanceType)} />
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Col>
                }
            </div>
        );
    }

    addNationalMapSiteForm = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const profileImage = this.state.profileImage && this.state.profileImage.url;
        const bannerImage = this.state.bannerImage && this.state.bannerImage.url;
        const climateChampionImage = this.state.climateChampionImage && this.state.climateChampionImage.url;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div>
                {

                    (this.state.ouIds.length > 0 ||
                        (this.state.nationalMapSiteType === SITE_TYPE.ORG_LEVEL && this.state.nationalMapSites.length === 0)) &&
                    <Form layout="vertical">
                        <Divider />
                        <div className='epar_national_map'>
                            <Col sm={24} md={12} style={{ paddingInline: 10 }}>
                                <p className='epar_national_map_form_category'>General Details</p>
                                <FormItem label="National Map Site Name">
                                    {getFieldDecorator('siteName', {
                                        rules: [
                                            { required: true, message: 'Please enter site name!' },
                                            { max: 100, message: 'Site name must be less than 100 characters.' },
                                        ],
                                    })(
                                        <Input placeholder="Enter site name" />
                                    )}
                                </FormItem>
                                <FormItem label="Short Description">
                                    {getFieldDecorator('shortDescription', {
                                        rules: [
                                            { required: true, message: 'Please enter short description!' },
                                            { max: 200, message: 'Short description must be less than 200 characters.' },
                                        ],
                                    })(
                                        <Input.TextArea placeholder="Enter short description" />
                                    )}
                                </FormItem>
                                <FormItem label="Description">
                                    {getFieldDecorator('siteDescription', {
                                        rules: [
                                            { required: true, message: 'Please enter site description!' },
                                            { min: 100, message: 'Site description must be minimum 100 characters.' },
                                            { max: 1500, message: 'Site description must be less than 1500 characters.' },
                                        ],
                                    })(
                                        <Input.TextArea placeholder="Enter site description" />
                                    )}
                                </FormItem>
                                <FormItem label="Traditional Custodians">
                                    {getFieldDecorator('traditionalOwners', {
                                        rules: [
                                            { required: true, message: 'Please enter traditional custodians!' },
                                            { max: 50, message: 'Traditional Custodians must be less than 50 characters.' }
                                        ],
                                    })(
                                        <Input placeholder="Enter traditional custodians name" />
                                    )}
                                </FormItem>
                                <FormItem label="Profile Image">
                                    {getFieldDecorator('profileImage', {
                                        rules: [{ required: true, message: 'Please enter profile image!' }],
                                    })(
                                        <Upload
                                            name="avatar"
                                            accept="image/png, image/jpeg"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.beforeUploadProfileImage(file)}
                                        >
                                            {profileImage ? <img src={profileImage} width="100" alt="profileImage" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>
                                <FormItem label="Banner Image">
                                    {getFieldDecorator('bannerImage', {
                                        rules: [{ required: true, message: 'Please enter banner image!' }],
                                    })(
                                        <Upload
                                            name="avatar"
                                            accept="image/png, image/jpeg"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.beforeUploadBannerImage(file)}
                                        >
                                            {bannerImage ? <img src={bannerImage} width="100" alt="bannerImage" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>

                            <Col sm={24} md={12} style={{ paddingInline: 10 }}>
                                <p className='epar_national_map_form_category'>Climate Champion Details</p>
                                <FormItem label="Climate Champion's Name">
                                    {getFieldDecorator('climateChampionName', {
                                        rules: [
                                            { max: 50, message: 'Climate Champion Name must be less than 50 characters.' },
                                        ]
                                    } 
                                    )(
                                        <Input placeholder="Enter climate champion's name" />
                                    )}
                                </FormItem>
                                <FormItem label="Description">
                                    {getFieldDecorator('climateChampionDescription', {
                                        rules: [
                                            { max: 1500, message: 'Description must be less than 1500 characters.' },
                                        ]
                                    })(
                                        <Input.TextArea placeholder="Enter description" />
                                    )}
                                </FormItem>
                                <FormItem label="Profile Image">
                                    {getFieldDecorator('climateChampionImage')(
                                        <Upload
                                            name="climateChampionImage"
                                            accept="image/png, image/jpeg"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => this.beforeUploadClimateChampionImage(file)}
                                        >
                                            {climateChampionImage ? <img src={climateChampionImage} width="100" alt="climateChampionImage" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>

                                <p className='epar_national_map_form_category'>Contact Details</p>
                                <FormItem label="Website">
                                    {getFieldDecorator('website', {
                                        rules: [
                                            { 
                                                pattern: new RegExp(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g), 
                                                message: 'Please enter valid website URL' 
                                            }
                                        ]
                                    })(
                                        <Input placeholder="Enter website" />
                                    )}
                                </FormItem>
                                <FormItem label="Phone Number">
                                    {getFieldDecorator('contactNumber', {
                                        rules: [
                                            { pattern: new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/), message: 'Please enter valid Phone Number' }
                                        ]
                                    })(
                                        <Input placeholder="Enter phone number" />
                                    )}
                                </FormItem>

                                <Row><div>Mark Area</div>
                                    <Button onClick={() => this.setState({ showDrawMapAreaModal: true })}>Open Map</Button></Row>
                            </Col>
                        </div>
                        <Divider />
                        <Row justify="end" type="flex">
                            <Button type="default" onClick={this.handleCancel} disabled={this.state.savingChanges}>Cancel</Button>
                            <Button type="primary" onClick={this.handleSubmit} disabled={this.state.savingChanges} className="epar_national_map_submit-button">Save</Button>
                        </Row>
                    </Form>
                }
            </div>
        );
    }

    selectedOrg = () => {
        return (
            <div>
                {
                    this.state.nationalMapSiteType === SITE_TYPE.ORG_LEVEL &&
                    <Row>
                        <Col sm={24} md={12}>
                            <p className="epar_national_map_label">Organization :</p>
                            <Input value={this.props.orgInfo.name} disabled />
                        </Col>
                    </Row>
                }
            </div>
        );
    }

    selectedInstance = () => {
        return (
            <div>
                {
                    this.state.isNationalMapEnabled &&
                    <Row className="epar_national_map_row" type="flex">
                        <p className="epar_national_map_label">Instance :</p>
                        <Select disabled={this.state.nationalMapSites.length > 0} defaultValue={this.state.instanceType} style={{ width: 120 }} onChange={(instanceType) => this.setState({ instanceType })}>
                            {NATIONAL_MAP_INSTANCES.map(instance => (
                                <Option value={instance.key}>{instance.name}</Option>
                            ))}
                        </Select>
                    </Row>
                }
            </div>
        );
    }

    siteTypeSelector = () => {
        return (
            <div>
                {
                    this.state.isNationalMapEnabled &&
                    <Row>
                        <Col sm={24} md={16}>
                            <Row className="epar_national_map_row" type="flex">
                                <p className="epar_national_map_label">Enable By :</p>
                                <Radio.Group onChange={(event) => this.setState({ nationalMapSiteType: event.target.value })}>
                                    <Radio.Button
                                        className="epar_national_map_radio-button"
                                        value={SITE_TYPE.ORG_LEVEL}
                                        checked={this.state.nationalMapSiteType === SITE_TYPE.ORG_LEVEL}
                                        disabled={(this.state.nationalMapSiteType === SITE_TYPE.OU_LEVEL && this.state.nationalMapSites.length > 0)}
                                    >
                                        Organization
                                    </Radio.Button>
                                    <Radio.Button
                                        className="epar_national_map_radio-button"
                                        value={SITE_TYPE.OU_LEVEL}
                                        checked={this.state.nationalMapSiteType === SITE_TYPE.OU_LEVEL}
                                        disabled={(this.state.nationalMapSiteType === SITE_TYPE.ORG_LEVEL && this.state.nationalMapSites.length > 0)}
                                    >
                                        Organization Unit
                                    </Radio.Button>
                                </Radio.Group>
                            </Row>
                        </Col>
                        {
                            this.state.nationalMapSiteType === SITE_TYPE.OU_LEVEL &&
                            <Col sm={24} md={8}>
                                <Row className="epar_national_map_row" type="flex" align='bottom' justify='end'>
                                    <Button icon='plus' onClick={() => this.setState({ isShowOuSelector: true })}>Create Group</Button>
                                </Row>
                            </Col>
                        }
                    </Row>
                }
            </div>
        );
    }

    enableNationalMap = () => {
        return (
            <Row className="epar_national_map_row" type="flex">
                <p className="epar_national_map_label">Enable National map :</p>
                <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    checked={this.state.isNationalMapEnabled}
                    onChange={this.onEnableDisableSite}
                />
            </Row>
        );
    }

    siteDetailView = () => {
        return (
            <div>
                {this.state.selectedSite &&
                    <NationalMapDetailView site={this.state.selectedSite} loadNationalMapDetails={this.loadNationalMapDetails}/>}
            </div>
        );
    }

    drawMapArea = () => {
        return (
            <div>
                <Modal
                    title='Draw Map Area'
                    visible={this.state.showDrawMapAreaModal}
                    width={'80vw'}
                    onOk={() => this.handleDrawMapArea()}
                    onCancel={() => this.handleCancelDrawMapArea()}
                >
                    <Map 
                        createMode={true} 
                        zoom={10} 
                        onCreate={this.onMapAreaDraw} 
                        onDelete={this.onMapAreaDelete}
                    />
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ?
                        <ConnectLoading /> :
                        <div className="epar_national_map">
                            {this.enableNationalMap()}
                            {this.siteTypeSelector()}
                            {this.selectedInstance()}
                            {this.orgOuSelector()}
                            {this.selectedOrg()}
                            {this.addNationalMapSiteForm()}
                            {this.drawMapArea()}
                            {this.orgGroupListView()}
                            {this.siteDetailView()}
                        </div>
                }
            </div>

        );
    }
}

function mapStateToProps(state, props) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NationalMapSiteDetailsForm));