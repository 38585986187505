import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import AudubonDocumentListView from './audubon/AudubonDocumentListView';
import AudubonInspectionListView from './audubon/AudubonInspectionListView';
import AudubonDocDetailView from './audubon/AudubonDocDetailView';
import AudubonInspectionDetailView from './audubon/AudubonInspectionDetailView';

class CertificationHome extends Component {
    constructor(props) {
        super(props);
        this.setSelectedMenuItem('audubon_documents');
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'audubon_documents':
                this.setSelectedMenuItem('audubon_documents');
                return <AudubonDocumentListView />
            case 'audubon_document_view':
                this.setSelectedMenuItem('audubon_documents');
                return <AudubonDocDetailView docId={route.match.params.param} />
            case 'audubon_inspections':
                this.setSelectedMenuItem('audubon_inspections');
                return <AudubonInspectionListView />
            case 'audubon_inspection_view':
                this.setSelectedMenuItem('audubon_inspections');
                return <AudubonInspectionDetailView templateId={route.match.params.param} />
            default:
                return <AudubonDocumentListView />;
        }
    }

    setSelectedMenuItem = (menuItem) =>{
        this.props.setSelectedMenu([menuItem]);
    }

    render() {
        return (
            <Switch>
                <Route path={'/audubon/:page/:param'} render={this.getView} />
                <Route path={'/audubon/:page'} render={this.getView} />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CertificationHome));