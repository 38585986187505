import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Modal } from 'antd';
import { Redirect, withRouter } from 'react-router-dom';

import { loadCourseList, archiveCourse } from '../actions/courseActions';
import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { CourseListItem } from '../CourseListItemBuilder';
import { setBreadcrumb } from '../../../actions/commonActions';
const confirm = Modal.confirm;

class CourseListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            selectedCourses: new Set(),
            courseId: null
        };
        this.loadCourseList();

        this.props.setBreadcrumb("Courses", [
            { route: '', label: 'Courses' }
        ]);
    }

    loadCourseList = async () => {
        this.props.loadCourseList();
    }

    getCoursesForListView = () => {
        let courseList = this.filterCourses().map((course) => {
            let builder = new CourseListItem.Builder(course, this.onCourseSelection, this.state.selectedCourses);
            return builder.build();
        });
        return courseList;
    }

    filterCourses = () => {
        return SearchUtils.filter(this.props.courseList ? this.props.courseList : [], "courseid", this.state.searchMatches);
    }

    filterSearchOptions() {
        const courses = this.filterCourses();
        const optionsMap = { title: "Courses", children: [] };
        courses.forEach((course) => {
            optionsMap.children.push({ title: course.title, id: course.courseid });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.courseIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (courseId) => {
        this.setState({ courseId: courseId });
    }

    onCourseSelection = (courseId, checked) => {
        const selectedCourses = this.state.selectedCourses
        checked ? selectedCourses.add(courseId) : selectedCourses.delete(courseId);
        this.setState({ selectedCourses });
    }

    archiveCourses = async () => {
        message.loading('Archiving courses', 0);
        const courseItr = this.state.selectedCourses.values();
        for (let courseId of courseItr) {
            await archiveCourse(courseId);
        }
        message.destroy();
        message.success('Courses archived successfully');
        this.setState({ selectedCourses: new Set() });
        this.loadCourseList();
    }

    onArchive = () => {
        confirm({
            title: 'Do you want to archive selected courses?',
            onOk: () => {
                this.archiveCourses();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    goToSelectedCourse() {
        if (this.state.courseId) {
            return (<Redirect to={`/courses/course/${this.state.courseId}`} />);
        }
    }

    render() {
        return (
            <div>
                {this.goToSelectedCourse()}
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <Button className='epar__tasks__action--button'
                            onClick={() => this.props.history.push('/courses/new_course')}>
                            Add New Course
                        </Button>
                        <Button
                            className='epar__tasks__action--button'
                            onClick={this.onArchive}
                            disabled={this.state.selectedCourses.size === 0}>
                            Archive
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getCoursesForListView()} loading={this.props.courseList === undefined} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        courseList: state.getIn(['courseState', 'courseList']),
        courseIndex: state.getIn(["courseState", "courseIndex"]),
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadCourseList: bindActionCreators(loadCourseList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseListView));
