import { postApi, ConnectApi, getApi } from "../../../utils/api-utils";
import { message } from 'antd';

export async function createReportTemplate(dashboardRef: string, createdBy: String, title:string, description:string, tags, removeFields) {
    try {
        const result = await postApi(ConnectApi.REPORT_API, `/report/`, {
            dashboardRef: dashboardRef,
            createdBy: createdBy,
            title: title,
            description: description,
            tags : tags,
            removeFields: removeFields
        });
        if (result.error) {
            throw result.error;
        }
        message.success('Report template created successfully.');
        return result.data;
    } catch (e) {
        console.log(e);
        message.error('Error in creating report template. Please retry.');
    }
}

export async function getReport(dashboardRef: string) {
    try {
        const result = await getApi(ConnectApi.REPORT_API, `/report/`+dashboardRef);
        if (result.error) {
            throw result.error;
        }
        return result;
    } catch (e) {
        console.log(e);
        message.error('Error in reading report template. Please retry.');
    }
}

export async function getTags() {
    try {
        const result = await getApi(ConnectApi.REPORT_API, `/report/tags`);
        if (result.error) {
            throw result.error;
        }
        return result;
    } catch (e) {
        console.log(e);
        message.error('Error in getting tags. Please retry.');
    }
}