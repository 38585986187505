// @flow
import { Map } from 'immutable';
import { LOGIN_ACTIONS } from './../actions/actionTypes';
import { message } from 'antd';


const initialState: any = Map({
  username: '',
  name: '',
  userId: '',
  isAuthenticated: false,
  requireNewPassword: false,
  groups: undefined,
  isLoading: true,
  orgId: undefined
});

export default function loginUserState(state: any = initialState, action: any) {
  switch (action.type) {
    case LOGIN_ACTIONS.SUCCESS:
    const idData = action.payload.credentialsData.idToken.payload;
    const groups = (idData['cognito:groups']) ? idData['cognito:groups'].map(i => i.toLowerCase()) : [];
    if(!groups.includes('eparadmin')){
      message.error('You are not authorized to use this app');
      return state.merge({
        'isLoading': false
      });
    }

    return state.merge({
      'isLoading': false, // Required to switch off loading
      'userId': idData.sub,
      'name': idData.name,
      'username': idData["cognito:username"],
      'isAuthenticated': true,
      'groups': groups,
      'orgId': idData["custom:orgId"]
    });
    case LOGIN_ACTIONS.NO_SESSION:
      return state.merge({
        'isLoading': false
      });
    case LOGIN_ACTIONS.NEW_PASSWORD_REQUIRED:
      const newState = state.merge(action.payload);
      return newState.merge({
        'requireNewPassword': true
      })
    case LOGIN_ACTIONS.NEW_PASSWORD_SET:
      return state.set('requireNewPassword', false);
    default:
      return state
  }
}
