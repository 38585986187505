// @flow

import React, { Component } from 'react';
import { Layout, Form, Icon, Input, Button, message } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authenticate } from '../actions/loginActions';

const { Header } = Layout;
const FormItem = Form.Item;

class NewPasswordFormView extends Component {

  constructor(props){
    super(props);
    this.state = {
      loginInProgress: false
    }
  }

  handleNewPassword = (e) =>{
    e.preventDefault();
    this.props.form.validateFields(async(err, values) => {
      if (!err) {
        message.loading('Please wait', 0);
        await this.setState({loginInProgress:true});
        const errMsg = await this.props.authenticate(this.props.username,values.currentPassword, values.newPassword);  
        message.destroy();
        if (errMsg) {
          message.error(errMsg, 5);
          await this.setState({loginInProgress:false});
        }
      }
    });
  }


  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (

      <Layout className='epar__login__new-password-form'>
        <Header className='epar__login__form-header'>
          <div className='pure-g epar__login__form-header'>
            <div className='pure-u-1-1'>
              <div className='pure-g'>
                <div className='pure-u-1'>
                  <img src='/assets/logo.png' className="epar__login__form--logo" alt=''/>
                </div>
              </div>

              <div className='pure-g'>
                <div className='pure-u-1 epar__content--center'>
                  <h4>Set New Password</h4>
                </div>
              </div>
            </div>
          </div>        
        </Header>


        <Form onSubmit={this.handleNewPassword} className="epar__login__form">
          <FormItem label='Current Password'>
            {getFieldDecorator('currentPassword', {
              rules: [{ required: true, message: 'Please input your current password!' }],
            })(
              <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" 
                placeholder="Enter your current password" value={this.props.password}/>
            )}
          </FormItem>

          <FormItem label='New Password'>
            {getFieldDecorator('newPassword', {
              rules: [{ required: true, message: 'Please input your Password!' }],
            })(
              <div>
              <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" 
                placeholder="Enter your new password" />

                <p>Please note: For security reasons, your password must;</p>
                <ul>
                  <li>be a minimum of 8 characters</li>
                  <li>contain at least one number</li>
                  <li>contain at least one lowercase letter</li>
                  <li>contain at least one capital letter</li>
                </ul>
              </div>
            )}
          </FormItem>

          <FormItem label='Confirm New Password'>
            {getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: 'Please confirm your Password!' },
                { required: true, message: 'Password and confirm password do not match', validator: (rule, value, callback) => {
                  const { getFieldValue } = this.props.form
                  if (value && value !== getFieldValue('newPassword')){
                    callback(new Error('Passwords do not match'));
                  }

                  callback();
                }}
              ],
            })(
              <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" 
                placeholder="Enter your new password again" />
            )}
          </FormItem>

          <FormItem className="epar__content--center">
            <Button type="primary" htmlType="submit" className="login-form-button"
                  disabled= {this.state.loginInProgress}>
              Log in
            </Button>
          </FormItem>
        </Form>
      </Layout>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    username: state.getIn(['loginUserState', 'username']),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticate: bindActionCreators(authenticate, dispatch)
  }
}

const newPasswordFormView = Form.create()(NewPasswordFormView);
export default connect(mapStateToProps, mapDispatchToProps)(newPasswordFormView);
