import React, { Component } from 'react';
import { Row, message, Button, Modal } from 'antd';
import TrainingVideoFormView from './TrainingVideoForm';
import { TrainingVideoCard } from './TrainingVideoCard';
import { VideoPlayer } from './VideoPlayer';
import { getSignedUrl, updateCourseDetails } from '../actions/courseActions';
import ConnectLoading from '../../../components/ConnectLoading';

const axios = require('axios').default;
const confirm = Modal.confirm;
const UPLOAD_TITLE = 'Upload Training Video';
const UPDATE_TITLE = 'Update Training Video';

class TrainingVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoUrl: null,
            trainingVideoAvailable: true,
            uploadModal: false,
            playerModal: false,
            isEditTrainingVideo: false
        }
    }

    setUploadModalState = async(flag, isEdit = false) => {
        if(isEdit) {
            message.loading('Loading training video...', 0);
            await this.getMediaUrl( 'getObject');
            message.destroy();
        }
        this.setState({uploadModal: flag, isEditTrainingVideo: isEdit});
    }

    setPlayerModalState = async(flag) => {
        if(flag) {
            message.loading('Loading training video...', 0);
            await this.getMediaUrl('getObject');
            message.destroy();
        }
        this.setState({playerModal: flag});
    }

    getMediaUrl = async (actionType) => {
        const trainingVideoData = this.props.courseDetails.training_video;
        const key = `${this.props.courseId}/${this.props.courseDetails.trainingVideoVersionId}/${trainingVideoData.fileName}`;
        const videoUrl = await getSignedUrl(key, trainingVideoData.fileType, actionType , 1800,  this.props.courseDetails.trainingVideoVersionId);
        this.setState({videoUrl});
    }

    videoSource = axios.CancelToken.source();

    handleCancelUpload = () => {
        this.videoSource.cancel("sourceComments - Request cancel by user");
        this.setState({uploadModal: false});
    };

    videoUploadView = () => {
        const { courseId, courseDetails} = this.props;
        const trainingVideoData = courseDetails.training_video ? courseDetails.training_video : null;
        return (
            <TrainingVideoFormView 
                videoSource = {this.videoSource}
                videoUrl = {this.state.videoUrl}
                setUploadModalState = {this.setUploadModalState}
                trainingVideoData = {trainingVideoData}
                isEdit = {this.state.isEditTrainingVideo}
                courseId = {courseId}
                load = {this.props.load}
                courseDetails = {this.props.courseDetails}
            />
        );
    }

    showUploadModal = () => {
        return (
            <Modal
                title= {this.state.isEditTrainingVideo ? UPDATE_TITLE : UPLOAD_TITLE}
                visible={this.state.uploadModal}
                onOk={()=>this.setState({uploadModal: false})}
                onCancel={()=>this.handleCancelUpload()}
                maskClosable={false}
                footer={null}
                width = {'600px'}
            >
                {this.videoUploadView()}
            </Modal>
        )
    }

    updateConfirm = () => {
        const _this = this;
        confirm({
          title: UPDATE_TITLE,
          content: 'Are you sure you want to update the current video?',
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            _this.setUploadModalState(true, true);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }

    deleteTrainingVideo = async() => {
        try {
            message.loading('Removing training video...', 0);
            const { courseId, courseDetails, load } = this.props;
            let newCourseData = courseDetails;
            newCourseData.training_video = null;
            await updateCourseDetails(courseId, newCourseData);
            message.success("Training video deleted successfully");
            await load();
            message.destroy();
        }
        catch(err) {
            message.destroy();
            console.log(err);
        }
    }
    
    deleteConfirm = () => {
        const _this = this;

        confirm({
          title: 'Delete training video',
          content: 'Are you sure you want to delete the training video?',
          okText: 'Yes',
          cancelText: 'No',
          onOk () {
            _this.deleteTrainingVideo();
          },
          onCancel() {
            // Do nothing
          },
        });
    }
    
    render () {
        if(!this.props.courseDetails) {
            return <ConnectLoading />
        }
        
        return(        
            <div className="pure-g">
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        <div className="pure-u-1">
                            {this.props.courseDetails.training_video  && this.props.courseDetails.training_video.fileName ?
                            <div>
                                <Button data-test="upload-new-version" onClick={()=>this.updateConfirm()} icon="upload" className='trn-action-button'>Update</Button>
                                <Button data-test="upload-new-version" onClick={()=>this.deleteConfirm()} icon="delete" className='trn-action-button'>Delete</Button>
                            </div>
                            :   <Button data-test="upload-new-version" onClick={()=>this.setUploadModalState(true)} icon="upload" className='trn-action-button'>Upload</Button>}
                            
                        </div>
                    </div>
                    <div>
                        <Row >
                            <div className='intnnt-upld'>
                                {this.state.uploadModal && this.showUploadModal()}
                                {this.state.playerModal && 
                                    <VideoPlayer setPlayerModalState = {this.setPlayerModalState} videoUrl = {this.state.videoUrl} />}
                                {this.props.courseDetails.training_video  && this.props.courseDetails.training_video.fileName &&
                                    <TrainingVideoCard 
                                        setPlayerModalState = {this.setPlayerModalState} 
                                        courseDetails = {this.props.courseDetails}
                                    />  
                                }
                            </div>
                        </Row>
                    </div>
                </div>
            </div> 
        );
    }
}

export default TrainingVideo;