import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom';
import { Button } from 'antd';

import { getOrgList } from '../../../actions/commonActions';
import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { OrgListItem } from '../OrgListItemBuilder';
import { setBreadcrumb } from '../../../actions/commonActions';
import NewOrgModal from './NewOrgModal';

class OrgListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            addOrgVisible: false,
            searchSelectedOrgId: null
        };
        this.loadOrgList();

        this.props.setBreadcrumb("Organisations", [
            { route: '', label: 'Organisations' }
        ]);
    }

    loadOrgList = async (force=false) => {
        this.props.getOrgList(force);
    }

    getOrgsForListView = () => {
        let orgList = this.filterOrgs().map((org) => {
            let builder = new OrgListItem.Builder(org);
            return builder.build();
        });
        return orgList;
    }

    filterOrgs = () => {
        return SearchUtils.filter(this.props.orgList ? this.props.orgList : [], "org_id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const orgs = this.filterOrgs();
        const optionsMap = { title: "Organisations", children: [] };
        orgs.forEach((org) => {
            optionsMap.children.push({ title: org.name, id: org.org_id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.orgIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (orgId) => {
        this.setState({ searchSelectedOrgId: orgId });
    }

    goToSelectedOrganisation = () => {
        if (this.state.searchSelectedOrgId) {
            return (<Redirect to={`/organisations/org/${this.state.searchSelectedOrgId}`} />);
        }
    }


    render() {
        return (
            <div>
                {this.goToSelectedOrganisation()}
                {this.state.addOrgVisible &&
                    <NewOrgModal
                        visible={this.state.addOrgVisible}
                        onCancel={() => this.setState({ addOrgVisible: false })}
                        onSave={() => {
                            this.loadOrgList(true);
                            this.setState({ addOrgVisible: false });
                        }}
                    />
                }
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <Button
                            className='epar__tasks__action--button'
                            onClick={() => this.setState({ addOrgVisible: true })}>
                            Add New Organisation
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getOrgsForListView()} loading={this.props.orgList === undefined} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['orgState', 'orgList']),
        orgIndex: state.getIn(['orgState', 'orgIndex']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgList: bindActionCreators(getOrgList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgListView));
