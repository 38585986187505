import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, Card, message, Select } from 'antd';
import { Link, Redirect } from 'react-router-dom';

import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';
import { setBreadcrumb } from '../../../actions/commonActions';
import NewDocumentEntryForm from '../components/NewDocumentEntryForm';
import { addDocument, loadDocuments, openS3DocumentByURL } from '../action/CertificationAction';
import { AUDUBON_CERTIFICATIONS, AUDUBON_CERTIFICATION_TYPES } from '../Constants';
import { CONFIG } from '../../../config/app-config';
import ConnectTable from '../../../components/ConnectTable';
import moment from 'moment';

const { Option } = Select;
class AudubonDocumentListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            addDocumentVisible: false,
            searchSelectedDocId: null,
            certificationType: null
        };

        this.props.setBreadcrumb("Audubon Documents", [{ route: '', label: 'Audubon Documents' }]);
    }

    componentDidMount = async() => {
        await this.props.loadDocuments();
    }

    columns = [
        {
            title: 'Title',
            dataIndex: 'doc_title',
            key: 'doc_title',
            render: (text, record) => {
                return <Link className='epar--link' to={`/audubon/audubon_document_view/${record.doc_id}`}>{text}</Link>;
            }
        },
        {
            title: 'Certification Type',
            dataIndex: 'doc_type',
            key: 'doc_type',
            render: (text) => {
                return AUDUBON_CERTIFICATION_TYPES[text];
            }
        },
        {
            title: 'Added',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => {
                return (<div> {new moment(new Date(text)).format('YYYY-MM-DD')}</div>)
            }
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <a className='epar--link' onClick={() => openS3DocumentByURL(`${CONFIG.s3HostUrl}/${CONFIG.commonDocumentsBucket}/${record.doc_id}.pdf`)}>{"View"}</a>;
            }
        }
    ];

    filterSearchOptions() {
        const docs = this.filterDocuments();
        const optionsMap = { "title": "Documents", children: [] };
        docs.forEach((doc) => {
            optionsMap.children.push({ title: doc.doc_title, id: doc.doc_id });
        });

        return [optionsMap]; 
    }

    goToSelectedDocument() {
        if (this.state.searchSelectedDocId) {
            return (<Redirect to={`/audubon/audubon_document_view/${this.state.searchSelectedDocId}`} />);
        }
    }

    filterDocuments() {
        let docs = [];
        const refs = this.state.searchMatches;
        if (!refs) {
            // initial setting
            docs = this.props.documentList ? this.props.documentList : [];
        } else {
            // hit by onSearch
            refs.map((ref) => {
                const doc = this.props.documentList.find((doc) => { return doc.doc_id === ref.ref; });
                if (doc) {
                    docs.push(doc);
                }
                return undefined;
            });
        }
        const filteredDocs = this.state.certificationType ? docs.filter(doc => doc.doc_type === this.state.certificationType) : docs;
        return filteredDocs.sort((a, b) => a.doc_title.localeCompare(b.doc_title));
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.documentIndex, phrase); 
        this.setState({ searchMatches: matches });
    }

    onSearchSelect(docId) {
        this.setState({ searchSelectedDocId: docId });
    }

    onCancel = () => {
        this.setState({addDocumentVisible : false});
    }

    onComplete = (document) => {
        document.created_by = this.props.userId;
        this.setState({addDocumentVisible : false});
        message.loading('Adding audubon document, please wait... ', 0);
        this.props.addDocument(document);
    }

    handleCertificateTypeSelect = (certification) => {
        this.setState({certificationType: certification})
    } 

    render() {
        const certificationTypes = AUDUBON_CERTIFICATIONS.map(i => <Option value={i.id}>{i.name}</Option>);
        return (
            <div>
                {this.goToSelectedDocument()}
                {<NewDocumentEntryForm
                    visible={this.state.addDocumentVisible}
                    onCancel={this.onCancel}
                    onComplete={this.onComplete}
                    mode="create"
                /> 
                }
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options= {this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <Select
                          defaultValue={this.props.selectedCertification}
                          showSearch
                          style={{ width: 200, marginLeft: 10 }}
                          placeholder="Certification Type"
                          optionFilterProp="children"
                          onChange={this.handleCertificateTypeSelect} 
                        > 
                          {certificationTypes}
                        </Select>
                    </div>
                    <div style = {{width: '33%'}}>
                        <Button
                            className='epar__tasks__action--button'
                            onClick={() => this.setState({ addDocumentVisible: true })}>
                            Add New Document
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <Card>
                            <ConnectTable dataSource={this.filterDocuments()} columns={this.columns} className="epar__tasks--table" />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        documentList: state.getIn(["certificationState", "documentList"]),
        documentIndex: state.getIn(["certificationState", "documentIndex"]),
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadDocuments: bindActionCreators(loadDocuments, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        addDocument: bindActionCreators(addDocument, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudubonDocumentListView);
