import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Icon, message, Modal, Row, Col, Tag, Select } from 'antd';
import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';

import { ListWidget } from '../../../components/ListWidget';
import { UserListItem } from '../UsersListItemBuilder';
import { getOrgList, setBreadcrumb } from '../../../actions/commonActions';
import { getUsers, getUser, addUserToGroup, removeUserFromGroup, sendWelcomeEmail, resetPassword, activateUser, verifyEmail,
    addQRLoginUser, checkQRLoginUser, resetQRLoginUser } from '../actions/userActions';
import { USER_GROUPS } from '../constants';
import QRCodeGenerator from './QRcode';
import jwt from 'jsonwebtoken';
import {CONFIG} from '../../../config/app-config';

const { v4: uuidv4 } = require('uuid');

const { Option } = Select;
class UserListView extends Component {

    qrCodeGeneratorRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: false,
            tags: [],
            selectedUser: {},
            selecedRole: [],
            selectedOrg: null,
            searchMatches: null,
            roles:null,
            visible:false,
            emailVerified: null,
            user:'',
            isKioskContractor: false,
            QRFormVisible: false,
            QRUsernameValue: '',
            QRPasswordValue:'',
            QRDownloadVisile:true,
            QRCreateVisible:true,
            QRToeknId: ''
        };

        this.props.setBreadcrumb("Users", [
            { route: '', label: 'Users' }
        ]);
    }

    componentDidMount = () => {
        this.props.getOrgList();
    }

    getUsersForListView = () => {
        let userList = this.state.users.map((user) => {
            let builder = new UserListItem.Builder(user, this.onUserOptions);
            return builder.build();
        });
        return userList;
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const { userName, email, firstName, lastName } = values;
            const { selectedOrg } = this.state;
            if (!userName && !email && !firstName && !lastName && !selectedOrg) {
                message.error("Please enter at least one search criteria")
                return;
            }
            if (selectedOrg) {
                values.selectedOrg = selectedOrg;
            }
            await this.setState({ loading: true });
            const users = await getUsers(values);
            await this.setState({ loading: false, users });
        });
    }
    
    createQR = async () => {
        if (this.state.isKioskContractor) {
            message.warning('This user is a kiosk Contractor user. Unable to use Login QR');
        } else {
            const { user } = this.state;
            const response = await checkQRLoginUser(user.user_id);

            if (response.message) {
                this.setState({
                    QRToeknId: user.user_id,
                    QRCreateVisible: false,
                    QRDownloadVisile: true,
                    QRUsernameValue: response.userName
                });
            }
            else {
                this.setState({
                    QRCreateVisible: true,
                    QRDownloadVisile: false,
                    QRUsernameValue: ''
                });
            }
            this.setState({ QRFormVisible: true,visible: false });
        }
    }

    onCreateQR = async (e) => {
        const { user } = this.state;
        e.preventDefault();
        try {
            this.props.form.validateFields(async(err, values) =>  {
                if (err) {
                  return ;
                }
                const secretKey = uuidv4();
                const tokenPayload = {
                    username: this.state.QRUsernameValue ? this.state.QRUsernameValue : user.username,
                    password: this.state.QRPasswordValue,
                };
                const token = jwt.sign(tokenPayload,secretKey);
                const response = await addQRLoginUser(user.org_id, token, user.user_id);

                if (response.status === 'ok') {
                    this.setState({ QRToeknId: response.tokenId });
                    this.setState({ QRDownloadVisile: false,QRCreateVisible: false });
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    handleQRDownload = () => {
        if (this.qrCodeGeneratorRef.current) {
            this.qrCodeGeneratorRef.current.handleDownload();
        }
    }

    handleQRReset = async () => {
        const { user } = this.state;
        const response = await resetQRLoginUser(user.user_id);

        if (response) {
            this.setState({
                QRCreateVisible: true,
                QRDownloadVisile: false,
                QRUsernameValue: user.username
            });
        }
    }

    onReset = () => {
        this.props.form.resetFields();
        this.setState({ users: [], selectedOrg: null });
    }

    onActivateUser = async (user) => {
        activateUser(user.user_id, user.username, user.org_id);
        setTimeout(this.onUserOptions, 2000, user);
    }

    addUserIntoGroup = async () => {
        const {selecedRole, user} = this.state;
        if(selecedRole.length>0){
            await addUserToGroup(this.state.userData.Username, selecedRole);
            setTimeout(this.onUserOptions, 2000, user);
        }
    }

    removeUserFromGroup = async (group) => {
        message.loading('Removing user from the group...', 0);
        await removeUserFromGroup(this.state.userData.Username, group);
        message.destroy();
        message.success("User removed from the group.");
        this.onUserOptions(this.state.user);
    }

    onResetPassword = async (user, forceChangePassword) => {
        Modal.confirm({
            title: 'Do you want to reset user password?',
            content: 'This will reset user password to "Connect123" and user will be asked to change the password on first login. Do you want to continue?',
            onOk() {
                resetPassword(user.username, forceChangePassword)
            },
            onCancel() { },
        });
    }

    onResendEmail = async (user) => {
        sendWelcomeEmail(user.email, user.first_name, user.last_name, user.username, user.org_id);
    }

    onEmailVerify = async (user) => {
        verifyEmail(user.username);
        setTimeout(this.onUserOptions, 2000, user);
    }

    onUserOptions = async (user) => {
        // Function set user profile details into state
        const userDetails = await getUser(user.username);
        const {userData} = userDetails;
        const groupData = userDetails.groupData.Groups;
        this.setState({ userData, tags:[], user, isKioskContractor: false });
        const { UserAttributes } = userData;
        let emailVerified = UserAttributes.find(attr => attr.Name === "email_verified").Value;
        emailVerified = emailVerified === 'True' || emailVerified === 'true';
        let isReportAdmin = groupData.find( groupData => groupData['GroupName'] === USER_GROUPS.REPORT_ADMIN );
        let isOrgReportAdmin = groupData.find( groupData => groupData['GroupName'] === USER_GROUPS.ORG_REPORT_ADMIN );
        let isAudubonAdmin = groupData.find( groupData => groupData['GroupName'] === USER_GROUPS.AUDUBON_ADMIN );
        let isKioskUser = groupData.find( groupData => groupData['GroupName'] === USER_GROUPS.KIOSK_USER );
        let isKioskContractor = groupData.find( groupData => groupData['GroupName'] === USER_GROUPS.KIOSK_CONTRACTOR );
        let isAldoAdmin = groupData.find( groupData => groupData['GroupName'] === USER_GROUPS.ALDO_ADMIN );
        let roles = [USER_GROUPS.REPORT_ADMIN, USER_GROUPS.ORG_REPORT_ADMIN, USER_GROUPS.AUDUBON_ADMIN, USER_GROUPS.KIOSK_USER, USER_GROUPS.KIOSK_CONTRACTOR, USER_GROUPS.ALDO_ADMIN];
        this.setState({ userData, emailVerified, roles });
        let userTags = [];
        if(isReportAdmin){
            roles = roles.filter(tag => tag !== USER_GROUPS.REPORT_ADMIN)
            userTags = [...userTags, isReportAdmin.GroupName];
        }
        if(isOrgReportAdmin){
            roles = roles.filter(tag => tag !== USER_GROUPS.ORG_REPORT_ADMIN)
            userTags = [...userTags, isOrgReportAdmin.GroupName];
        }
        if(isAudubonAdmin){
            roles = roles.filter(tag => tag !== USER_GROUPS.AUDUBON_ADMIN)
            userTags = [...userTags, isAudubonAdmin.GroupName];
        }
        if(isKioskUser){
            roles = roles.filter(tag => tag !== USER_GROUPS.KIOSK_USER)
            userTags = [...userTags, isKioskUser.GroupName];
        }
        if(isKioskContractor){
            roles = roles.filter(tag => tag !== USER_GROUPS.KIOSK_CONTRACTOR)
            userTags = [...userTags, isKioskContractor.GroupName];
            this.setState({ isKioskContractor: true });
        }
        if(isAldoAdmin){
            roles = roles.filter(tag => tag !== USER_GROUPS.ALDO_ADMIN)
            userTags = [...userTags, isAldoAdmin.GroupName];
        }
        this.setState({visible: true, tags: userTags, roles});
    }

    handleOk = e => {
        this.setState({
          visible: false,
        });
    };

    handleOkQRForm = e => {
        this.setState({
          QRFormVisible: false,
            visible: true,
        });
    };

    handleQRUsernameChange = (e) => {
        this.setState({ QRUsernameValue: e.target.value });
    };

    handleQRPasswordChange = (e) => {
        this.setState({ QRPasswordValue: e.target.value });
    };

    QRModal = () => {
        const QRValue = `${CONFIG.connectWebUrl}/qr-login?tokenId=${this.state.QRToeknId}`;
        const { user } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Modal
                    title={this.state.QRCreateVisible?"Create Login QR Code":"Login QR Code"}
                    visible={this.state.QRFormVisible}
                    onOk={this.handleOkQRForm }
                    onCancel={this.handleOkQRForm }
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={this.state.QRCreateVisible ?
                        <Button type="primary" htmlType="submit" form='qrForm' >
                            Create QR Code
                        </Button>
                        :
                        [
                            <Button key="ok1" type="primary" onClick={this.handleQRReset}>
                                Reset QR Code
                            </Button>,
                            <Button key="ok2" type="primary" onClick={this.handleQRDownload}>
                                Download QR Code
                            </Button>,
                        ]

                    }
                >
                    <Form id="qrForm" layout="inline" onSubmit={this.onCreateQR}>
                        <Form.Item label={"User Name"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {getFieldDecorator('userNameQR',{
                                initialValue: this.state.QRUsernameValue ? this.state.QRUsernameValue : user.username,
                                rules: [{ required: true,message: 'Please input username!' }],
                            })(
                                <Input disabled readOnly placeholder="Username" onChange={this.handleQRUsernameChange} style={{ width: '250px' }} />
                            )}
                        </Form.Item>
                        {this.state.QRCreateVisible && <Form.Item label={"Password"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {getFieldDecorator('password',{
                                rules: [{ required: true,message: 'Please input password!' }],
                            })(
                                <Input.Password placeholder="Enter the password..." onChange={this.handleQRPasswordChange} style={{ width: '250px' }} />
                            )}
                        </Form.Item>
                        }
                        {!this.state.QRCreateVisible &&
                            <div style={{ display: 'flex',justifyContent: 'center' }}>
                                <QRCodeGenerator ref={this.qrCodeGeneratorRef} downloadVisible={this.state.QRDownloadVisile} value={QRValue} />
                            </div>
                        }
                    </Form>
                </Modal>
            </div>
        )
    }

    detailsModal = () =>{
        const { tags ,user, userData, emailVerified, roles} = this.state;
        const { Enabled, UserStatus } = userData;
        const tagChild = tags.map(this.mapRoleLabels);
        const children = [];
        roles.forEach(role => {
            children.push(<Option key={role}>{role}</Option>);
        })
        return (
        <div>
            <Modal
                title={user.first_name+' '+user.last_name}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleOk}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div>
                    <Row style={{ margin: '10px' }}><Col span={7}><b>Enabled: </b></Col>
                        {
                            Enabled ? <Tag color="green">True</Tag>
                                : <span><Tag color="red">False</Tag><Button style={{ height: '22px' }} onClick={() => this.onActivateUser(user)}>Activate</Button></span>
                        }
                    </Row>
                    <Row style={{ margin: '10px' }}><Col span={7}><b>Account status: </b></Col>
                        {
                            UserStatus === 'CONFIRMED' ? <Tag color="green">Confirmed</Tag>
                                : UserStatus === 'FORCE_CHANGE_PASSWORD' ? <Tag color="orange">Pending</Tag>
                                    : <Tag color="red">UserStatus</Tag>
                        }
                    </Row>
                    <Row style={{ margin: '10px' }}><Col span={7}><b>Email verified: </b></Col>
                        {
                            !emailVerified ? <Tag color="green">True</Tag>
                                : <span><Tag color="red">False</Tag><Button style={{ height: '22px' }} onClick={() => this.onEmailVerify(user)}>Verify</Button></span>
                        }
                    </Row>
                    {UserStatus === 'FORCE_CHANGE_PASSWORD' && <Row><Button icon="email" onClick={() => this.onResendEmail(user)}>Resend Welcome Email</Button></Row>}
                    <Row style={{ margin: '10px' }}><Col span={7}><b>Roles :  </b></Col>
                    <Select
                        mode="multiple"
                        style={{ width: '50%' }}
                        placeholder="Please select roles"
                        onChange={this.handleChange}
                        allowClear={true}
                    >
                    {children}
                    </Select>
                        <Button style={{ marginLeft: '10px'}} onClick={()=>this.addUserIntoGroup()} type="primary" shape="circle" icon="plus" />
                    </Row>
                    <Row style={{ margin: '10px' }}>
                        {this.state.tags.length > 0 ? tagChild : 'None'}
                    </Row>
                    {UserStatus === 'CONFIRMED' && emailVerified && <Row><Button onClick={() => this.onResetPassword(user)}>Reset Password</Button></Row>}
                    {UserStatus === 'FORCE_CHANGE_PASSWORD' && <Row><Button onClick={() => this.onResetPassword(user, true)}>Reset Password</Button></Row>}
                        <Row style={{ marginTop: '10px' }}>
                            <Button onClick={() => this.createQR()} style={{ width: 126 }}>View QR Code</Button>
                        </Row>
                </div>
            </Modal>
        </div>
        );
    }
      
    mapRoleLabels = tag => {
        const tagElem = (
          <Tag color="#2db7f5"
            closable
            onClose={e => {
              e.preventDefault();
              this.removeUserFromGroup(tag);
            }}
          >
            {tag}
          </Tag>
        );
        return (
          <span key={tag} style={{ display: 'inline-block' }}>
            {tagElem}
          </span>
        );
    };    

    handleChange = (value) => {
        this.setState({ selecedRole: value })
    }

    filterOrgs = () => {
        return SearchUtils.filter(this.props.orgList ? this.props.orgList : [], "org_id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const orgs = this.filterOrgs();
        const optionsMap = { title: "Organisations", children: [] };
        orgs.forEach((org) => {
            optionsMap.children.push({ title: org.name, id: org.org_id });
        });

        return [optionsMap];
    }

    onOrgSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.orgIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (orgId) => {
        this.setState({ selectedOrg: orgId })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="pure-g">
                    {this.state.visible ? this.detailsModal() : ''}
                    {this.state.QRFormVisible ? this.QRModal() : ''}
                    <Form layout="inline" onSubmit={this.onSearch}>
                        <Form.Item>
                            {getFieldDecorator('userName',
                            )(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Username"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('email',
                            )(
                                <Input
                                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="email"
                                    placeholder="Email"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('firstName',
                            )(
                                <Input
                                    prefix="F"
                                    placeholder="First Name"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('lastName',
                            )(
                                <Input
                                    prefix="L"
                                    placeholder="Last Name"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('selectedOrg',
                            )(
                                <Search
                                    searchText={"Select Organisation"}
                                    handleSearch={(value) => this.onOrgSearch(value)}
                                    handleSelect={(value) => this.onSearchSelect(value)}
                                    options={this.filterSearchOptions()}
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.onSearch} htmlType="submit">
                                Search
                                </Button>
                            <Button style={{ marginLeft: '10px' }} type="primary" onClick={this.onReset}>
                                Reset
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() => this.props.history.push('/users/bulk_upload')}>
                                Upload Users
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getUsersForListView()} loading={this.state.loading} />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['orgState', 'orgList']),
        orgIndex: state.getIn(['orgState', 'orgIndex']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserListView)));
