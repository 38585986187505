import React, { Component } from 'react';
import { Modal, Form, Input, Icon, Button, Upload, Select, Switch } from 'antd';
import moment from 'moment';
import { AUDUBON_CERTIFICATIONS } from '../Constants';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 13 },
    wrapperCol: { span: 11 },
};

class NewDocumentEntryForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pdfFile : null
        }
    }

    handleCancel = () => {
        this.setState({pdfFile : null});
        this.props.onCancel();
    };

    handleOk = () => {
        const { form, onComplete, mode } = this.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            let document = {};
            if(mode === 'create'){
                document.pdfFile = this.state.pdfFile;
                document.created_at = moment().toISOString();
            }
            document.doc_title = values.title;
            document.doc_type = values.certification_type;
            document.is_preliminary = values.isPreliminary === true ? 1 : 0;
            onComplete(document);
            form.resetFields();
            this.resetState();
        });
    }

    getLocalePdfUploaderSettings = () => {
        return {
            action: '/upload',
            accept: '.pdf',
            beforeUpload: (file) => {
                let { pdfFile } = this.state;
                pdfFile = [file];
                this.setState({ pdfFile });
                return false;
            }
        }
    }

    newDocumentFields = (getFieldDecorator) => {
        return(
            <FormItem
                {...formItemLayout}
                label={'Upload PDF'}
            >{
                getFieldDecorator(`pdf`, {
                    rules: [{ required: true }],
                })(
                    <Upload {...this.getLocalePdfUploaderSettings()}>
                        <Button  type="primary">
                            <Icon type="upload" /> Click to Upload
                        </Button>
                    </Upload>
                )
            }   
            </FormItem>
        )
    }

    render() {
        const { form, mode } = this.props
        const { getFieldDecorator } = form;
        const certificationTypes = AUDUBON_CERTIFICATIONS.map(i => <Option value={i.id}>{i.name}</Option>);
        
        return (
            <div>
                <Modal
                    title={mode === 'create' ? "Add New Document" : "Edit Document"}
                    visible={this.props.visible}
                    cancelText= {'Cancel'}
                    okText={"Save"}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                >
                    <Form layout='horizontal'>
                        <FormItem
                            {...formItemLayout}
                            label={"Document Title"}
                        >
                            {
                                getFieldDecorator('title', {
                                    rules: [
                                        {
                                            message: 'Document title cannot be empty'
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter the document title'
                                        }
                                    ]
                                })(
                                    <Input placeholder={"Enter title of the document"} />
                                )
                            }

                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Certification Type"}
                        >
                            {
                                getFieldDecorator('certification_type', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select the certification type'
                                        }
                                    ]
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Select certification type"
                                        optionFilterProp="children"
                                    > 
                                        {certificationTypes}
                                    </Select>
                                )
                            }

                        </FormItem>             
                        <FormItem {...formItemLayout} label={"Is Preliminary Document"}>
                            {
                                getFieldDecorator('isPreliminary', {initialValue: false})(
                                    <Switch checkedChildren="True" unCheckedChildren="False"
                                            defaultChecked={false}/>
                                )
                            }
                        </FormItem>
                        {mode === 'create' && this.newDocumentFields(getFieldDecorator)}
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(NewDocumentEntryForm)