import React from 'react';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';

export const VideoPlayer = (props) => {
    const {setPlayerModalState, videoUrl } = props;
    return (
        <Modal
            maskClosable={false}
            centered
            closable
            visible = {true}
            footer={null}
            onCancel={() => setPlayerModalState(false)}
            width={720}
            wrapClassName={"tr-video-player-modal"}
        >
            <div className="ind-video-player"> 
                <ReactPlayer playing light={'/assets/training-background.png'} width={718} controls url={videoUrl} />
            </div>
        </Modal>
    )
};