import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row } from 'antd';

export class OrgListItem {
    static get Builder() {
        class Builder {
            constructor(org) {
                this.org = org;
            }
            build() {
                const org = this.org;
                const isActive = org.status===1;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(org.name, `/organisations/org/${org.org_id}`)
                    .setDescription(
                        <div>
                            <Row>{`Org Code: ${org.org_code}`}</Row>
                            <Row>{`Country: ${org.country_code}`}</Row>
                            <Row>{`TimeZone: ${org.timezone ? org.timezone : 'Not Set'}`}</Row>
                        </div>
                    ).setRightTag(isActive? 'Active':'Inactive', isActive? 'green':'red')
                return builder.build();
            }
        }
        return Builder;
    }
}
