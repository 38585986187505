import React, { Component } from 'react';

import { Layout } from 'antd';
const { Content } = Layout;

export class AppContent extends Component {
  render(){
    return (
        <Content className='epar__content-wrapper'>
            <Layout style={{ minHeight: '90vh' }}>
                <Content className='epar__content-container'>
                    {this.props.children}
                </Content>
            </Layout>
        </Content>
    )
  }
}
