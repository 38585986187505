import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Avatar } from 'antd';

export class ListItem {
    static get Builder() {
       class Builder {
            constructor() {
                this.title = undefined;
                this.desc = undefined;
                this.avatar = undefined;
                this.tag = undefined;
            }
            setTitle(title) {
                this.title = title;
                return this;
            }          
            setTitleWithLink(title, path) {
                this.title = <Link className='epar--link' to={path}>{title}</Link>;
                return this;
            }
            setDescription(description) {
                this.desc = description;
                return this;
            }
            setLeftIcon(leftIcon) {
                this.avatar = leftIcon;
                return this;
            }
            setLeftAvatar(icon, bgColor) {
                this.avatar = <Avatar
                                className='epar__list-widget__avatar'
                                style={{backgroundColor: bgColor}}
                                shape="square" 
                                icon={icon} 
                                size="large"/>;
                return this;
            }
            setRightIcon(rightIcon) {
                this.tag = rightIcon;
                return this;
            }
            setRightTag(text, color, path=undefined) {
                this.tag = path ? <div><Link to={path} ><Tag color={color}>{text}</Tag></Link></div>:<div><Tag color={color}>{text}</Tag></div>;
                return this;
            }
            build() {
                let item = {
                    title: this.title,
                }
                if(this.desc) item.desc=this.desc;
                if(this.avatar) item.avatar=this.avatar;
                if(this.tag) item.tag=this.tag;
                return item;
            }
        }
        return Builder;
    }
 }