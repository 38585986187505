import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Checkbox } from 'antd';
import { CATEGORIES, FREQUENCIES } from './constants';

export class CourseListItem {
    static get Builder() {
        class Builder {
            constructor(course, onCourseSelection, selectedCourses) {
                this.course = course;
                this.selectedCourses = selectedCourses;
                this.onCourseSelection = onCourseSelection;
            }
            build() {
                const course = this.course;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(course.title, `/courses/course/${course.courseid}`)
                    .setDescription(
                        <div>
                            <Row>{`Category: ${CATEGORIES[course.category]}`}</Row>
                            <Row>{`Frequency: ${FREQUENCIES[course.frequency]}`}</Row>
                        </div>
                    )
                    .setLeftIcon(
                        <Checkbox 
                            onChange={e => this.onCourseSelection(course.courseid, e.target.checked)}
                            checked = { this.selectedCourses.has(course.courseid) }
                        />);
                return builder.build();
            }
        }
        return Builder;
    }
}

export class QuizListItem {
    static get Builder() {
        class Builder {
            constructor(question, index) {
                this.question = question;
                this.questionIndex = index;
            }
            build() {
                const question = this.question;
                let builder = new ListItem.Builder();
                let title = <span><b>Question {this.questionIndex+1} - </b>{question.question}</span>;
                builder = builder
                            .setTitle(title)
                            .setDescription(
                                <div>
                                    <Row>Quiz Responses</Row>
                                    {question.options.map(option=><Row> - {option}</Row>)}
                                </div>
                            );
                return builder.build();
            }
       }
       return Builder;
    }
}
