import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Card, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { Link, Redirect } from 'react-router-dom';

import ConnectTable from '../../../components/ConnectTable';
import { loadInspectionList, createInspectionTemplate } from '../action/CertificationAction';
import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';
import { setBreadcrumb } from '../../../actions/commonActions';
import InspectionTemplateForm from '../components/InspectionTemplateForm';
import { AUDUBON_CERTIFICATIONS, AUDUBON_CERTIFICATION_TYPES } from '../Constants';

const { Option } = Select;
class AudubonInspectionListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            addInspectionVisible: false,
            selectedTemplates: new Set(),
            searchSelectedTemplate: null,
            certificationType: null
        };

        this.loadInspectionList();

        this.props.setBreadcrumb("Audubon Certifications", [
            { route: 'audubon_inspections', label: 'Audubon Certifications' } 
        ]);
    }

    loadInspectionList = async () =>{
        await this.props.loadInspectionList();
    }

    componentDidUpdate = async() => {
        console.log(this.props.inspectionList);
    }

    columns = [
        {
            title: 'Template Name',
            dataIndex: 'name',
            key: 'namel',
            render: (text, record) => {
                return <Link className='epar--link' to={`/audubon/audubon_inspection_view/${record.template_id}`}>{text}</Link>;
            }
        },
        {
            title: 'Certification Type',
            dataIndex: 'template_type',
            key: 'template_type',
            render: (text) => {
                return AUDUBON_CERTIFICATION_TYPES[text];
            }
        }
    ];

    filterInspections = () => {
        const inspections = SearchUtils.filter(this.props.inspectionList ? this.props.inspectionList : [], "template_id", this.state.searchMatches);
        let inspectionList = this.state.certificationType ? inspections.filter(temp => temp.template_type === this.state.certificationType) : inspections;
        return inspectionList.sort((a, b) => a.name.localeCompare(b.name));;
    }

    filterSearchOptions() {
        const inspections = this.filterInspections();
        const optionsMap = { title: "Templates", children: [] };
        inspections.forEach((i) => {
            optionsMap.children.push({ title: i.name, id: i.template_id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.inspectionIndex, phrase); 
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (templateId) => {
        this.setState({ searchSelectedTemplate: templateId });
    }

    onTemplateSelection = () => {
        if (this.state.searchSelectedTemplate) {
            return (<Redirect to={`/audubon/audubon_inspection_view/${this.state.searchSelectedTemplate}`} />);
        }
    }

    handleCertificateTypeSelect = (certification) => {
        this.setState({certificationType: certification})
    } 

    render() {
        const certificationTypes = AUDUBON_CERTIFICATIONS.map(i => <Option value={i.id}>{i.name}</Option>);
        return (
            <div>
                {this.onTemplateSelection()}
                {this.state.addInspectionVisible &&
                    <InspectionTemplateForm
                        visible={this.state.addInspectionVisible}
                        mode="create"
                        callback={async (data) => {
                            this.setState({ addInspectionVisible: false });
                            if (data) {
                                message.loading('Creating template...', 0);
                                await createInspectionTemplate(data, this.props.userId); 
                                message.destroy();
                                message.success('Template created successfully');
                                this.loadInspectionList();
                            }
                        }}
                    />
                }
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <Select
                          defaultValue={this.props.selectedCertification}
                          showSearch
                          style={{ width: 200, marginLeft: 10 }}
                          placeholder="Certification Type"
                          optionFilterProp="children"
                          onChange={this.handleCertificateTypeSelect} 
                        > 
                          {certificationTypes}
                        </Select>
                    </div>
                    <div style = {{width: '33%'}}>
                        <Button
                            className='epar__tasks__action--button'
                            onClick={() => this.setState({ addInspectionVisible: true })}>
                            Add New Template
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <Card>
                            <ConnectTable dataSource={this.filterInspections()} columns={this.columns} className="epar__tasks--table" />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        inspectionList: state.getIn(["certificationState", "inspectionList"]),
        inspectionIndex: state.getIn(["certificationState", "inspectionIndex"]),
        userId: state.getIn(["loginUserState", "userId"]),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadInspectionList: bindActionCreators(loadInspectionList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudubonInspectionListView));