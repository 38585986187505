import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserBulkUpload from './containers/UserBulkUpload';

import { setSelectedMenu } from '../../actions/commonActions';
import UserListView from './containers/UserListView';

class UsersHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['users']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'bulk_upload':
                return <UserBulkUpload/>;
            default:
                return <UserListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/users/:page'} render={this.getView} />
                <Route path={'/users'} render={this.getView} />
                <Redirect to='/users' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersHome));