import React from 'react';
import { Modal } from 'antd';
import CourseQuestions from './newCourse/CourseQuestions';

export const QuizEditModal = (props) => {
    return (
        <Modal
            visible={props.visible}
            title='Edit Questions'
            okText="Save"
            cancelText= 'Cancel'
            onCancel={props.handleCancel}
            onOk={props.handleSave}
            closable={false}
            destroyOnClose={true}
            width='80%'
        >
            <CourseQuestions
                activeQuestion = {props.activeQuestion}
                activeQuestionChange = {props.onActiveQuestionChange}
                add = {props.onQuestionAdd}
                remove = {props.onQuestionRemove}
                questions = {props.questions}
                onQuestionChange= {props.onQuestionChange}
                onAddAnswer = {props.onAddAnswer}
                onRemoveAnswer = {props.onRemoveAnswer}
            />       
        </Modal>
    );

};