import React from 'react';
import { Calendar, Badge, Popover, Button } from 'antd';
import moment from 'moment';

const _count = (value, tips, callback) => {
  const dayItems = tips.filter(i => value.format('YYYY-MM-DD') === moment(i.publish_date).local().format(`YYYY-MM-DD`).toString());
  return dayItems.map(i => {
    return <Button onClick={() => callback(i)}>{i.title}</Button>
  });
}

const _dateCellRender = (value, tips, callback) => {
  const items = _count(value, tips, callback);
  const count = items.length;
  if (count > 0){
    return (
      <div style={{paddingLeft:'10px'}}>
        <Popover content={items} title="EHS Tips">
          <Badge count={count}>
            <span className="head-example" />
          </Badge>
        </Popover>
      </div>
    );
  } 
  else {
    return null;
  }
}

const _getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
}

const _monthCellRender = (value) => {
  const num = _getMonthData(value);
  return num ? (
    <div className="notes-month">
      <section>{num}</section>
      <span>Backlog number</span>
    </div>
  ) : null;
}

export default function(props){

  const handleCallback = (tip) => {
    console.log("as");
    props.showDetailsModal(tip);
  }

 return (
    <div>
      <Calendar dateCellRender={(date) => _dateCellRender(date, props.tips, handleCallback)} monthCellRender={_monthCellRender} className='ehs-calendar' />
    </div>
  )
}