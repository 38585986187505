import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { setBreadcrumb, setAudubonOrgUnits } from '../../../actions/commonActions';
import OrgOUMultiSelector from '../../../components/OrgOUMultiSelector';

class SelectCertificationOrgUnits extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOus: [],
            selectAll: false
        }
      }
    
    onOk = async() => {
        const ouIds = this.state.selectedOus.map(ou=>{return ou.ouId});
        await setAudubonOrgUnits(this.props.orgId, ouIds);
        this.props.onSave();
    }
    
    onChangeOuSelect = (state) => {
      if(state.target.checked) {
        let ouArray = this.props.ouList.map(ou=>{
          return(
            { orgId: ou.org_id, 
            ouId: ou.org_unit_id}
          )
        })
        this.setState({selectedOus: ouArray, selectAll: true});
      }
      else {
        this.setState({selectedOus: [], selectAll: false});
      }
    } 

    render(){
      const label = `${this.state.selectAll ? 'Deselect all' : 'Select all'}`;

      return (
          <Modal
              title={"Select organisation units"}
              visible={true}
              okText={"Save"}
              cancelText={"Cancel"}
              destroyOnClose={true}
              onCancel={this.props.onCancel}
              onOk={this.onOk}
          >
               <OrgOUMultiSelector 
                  onValue={selectedOus=>this.setState({ selectedOus })} 
                  selectedOus={this.state.selectedOus}
                  orgId = {this.props.orgId}
              />
              
              <p style={{ marginTop: '20px' }}>
                {this.props.ouList && this.props.ouList.length > 0 &&
                  <Checkbox
                    checked={this.state.selectAll}
                    onChange={this.onChangeOuSelect}
                  >
                  {label}
                  </Checkbox>
                }
              </p>
          </Modal>
      );
    }
}

const mapStateToProps = (state, props) => {
    return {
      ouList: state.getIn(['orgState', 'ouList'])
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return {
      setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCertificationOrgUnits);