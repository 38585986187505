import React from "react";
import { Table } from 'antd';
import { isMobile } from '../utils/common-utils';

export default function ConnectTable(props){
  let columns = props.columns;
  const mobileView = isMobile();
  if (!mobileView){
    if (columns.length  > 0){ // Has atleast 1 column
      columns[0].fixed = 'left';
    }
  }

  return (
    <Table dataSource={props.dataSource} columns={columns} className={props.className} scroll={{ x: 1000 }} />
  )
}