export const COMMON_DOC_TYPES = {
    AUDUBON : 'AUDUBON'
}

export const AUDUBON_CERTIFICATIONS = [
    {
        id: 'ASCP_FOR_GOLF',
        name: 'ASCP for Golf'
    },
    {
        id: 'CERTIFIED_SUPERINTENDENT',
        name: 'Certified Superintendent'
    },
    {
        id: 'CLASSIC_SANCTUARY',
        name: 'Classic Sanctuary'
    },
    {
        id: 'SIGNATURE_SANCTUARY',
        name: 'Signature Sanctuary'
    },
    {
        id: 'GREEN_HOSPITALITY',
        name: 'Green Hospitality'
    },
    {
        id: 'GREEN_LODGING',
        name: 'Green Lodging'
    },
    {
        id: 'SUSTAINABLE_COMMUNITIES',
        name: 'Sustainable Communities'
    }
];

export const AUDUBON_CERTIFICATION_TYPES = {
    ASCP_FOR_GOLF: 'ASCP for Golf',
    CERTIFIED_SUPERINTENDENT: 'Certified Superintendent',
    CLASSIC_SANCTUARY: 'Classic Sanctuary',
    SIGNATURE_SANCTUARY: 'Signature Sanctuary',
    GREEN_HOSPITALITY: 'Green Hospitality',
    GREEN_LODGING: 'Green Lodging',
    SUSTAINABLE_COMMUNITIES: 'Sustainable Communities',
}