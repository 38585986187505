import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tree, TreeSelect } from 'antd';
import { bindActionCreators } from 'redux';
import { loadOUList } from '../actions/commonActions';
import { sortOuList } from '../utils/common-utils';

const TreeNode = Tree.TreeNode;

class OuMultiSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ouList: [],
      ouOrgMap: new Map()
    };
  }

  componentDidMount = async () => {
    await this.props.loadOUList();
    const ouOrgMap = new Map();
    const ous = this.props.ouList.map(ou => {
      ouOrgMap.set(ou.org_unit_id, {ouId:ou.org_unit_id, orgId:ou.org_id, orgCode:ou.org_code});
      return {
        title: ou.ou_name,
        value: ou.org_unit_id,
        key: ou.org_unit_id,
        children: [],
        parent: ou.parent_org_unit_id,
        countryCode: ou.country_code
      }
    });
    this.setState({ouOrgMap});

    ous.forEach(ou => {
      if (ou.parent) {
        const parentOu = ous.find(item => item.key === ou.parent);
        if (parentOu) {
          parentOu.children.push(ou);
        }
      }
    });
    const ouList = ous.filter(item => !item.parent);
    this.setState({ ouList });
  }

  renderTreeNodes = (data) => {
    const items = data.slice();
    return sortOuList(items).map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} value={item.value} disabled={this.props.countryCode && this.props.countryCode===item.countryCode ? false:true}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });
  }

  onChange = (value) => {
    this.props.onValue(value.map(ouId=>this.state.ouOrgMap.get(ouId)));
  };

  getSelectedOus = () => {
    return this.state.value;
  }


  render() {
    return (
      <TreeSelect
        showSearch
        style={{ width: 300 }}
        placeholder="Please select"
        treeNodeFilterProp='title'
        multiple
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        onChange={this.onChange}
        value={this.props.selectedOus.map(ou=>ou.ouId)}
      >

        {this.renderTreeNodes(this.state.ouList)}

      </TreeSelect>)
  }
}

function mapStateToProps(state, props) {
  return {
    ouList: state.getIn(['orgState', 'ouList'])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadOUList: bindActionCreators(loadOUList, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OuMultiSelector);
