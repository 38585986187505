import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import EHSTIpsListView from './containers/EHSTIpsListView';

class EHSTips extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['ehs-tips']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            default:
                return <EHSTIpsListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/ehs-tips'} render={this.getView} />
                <Redirect to='/ehs-tips' />
            </Switch>
        )
    }
}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EHSTips));