import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import CourseListView from './containers/CourseListView';
import NewCourseView from './containers/newCourse/NewCourseView';
import CourseView from './containers/CourseView';

class CourseHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['courses']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'new_course':
                return <NewCourseView />
            case 'course':
                return <CourseView courseId={route.match.params.param} />
            default:
                return <CourseListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/courses/:page/:param'} render={this.getView} />
                <Route path={'/courses/:page'} render={this.getView} />
                <Route path={'/courses'} render={this.getView} />
                <Redirect to='/courses' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseHome));