import React from 'react';
import { Tabs, Button, Form, Select, Input, Icon } from 'antd';

const TabPane = Tabs.TabPane;

const FormItem = Form.Item;
const Option = Select.Option;

const QuestionForm = Form.create({
  onFieldsChange(props, changedFields) {
    props.onChange(props.index, changedFields);
  },
  mapPropsToFields(props) {
    return {
      question: Form.createFormField({
        ...props.question
      }),
      answer: Form.createFormField({
        ...props.answer
      })
    };
  }
})((props) => {
  const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
      <Form layout="horizontal">
          <FormItem label="Question" {...formItemLayout} >
              {getFieldDecorator('question', {
                  rules: [ {required: true, message: 'Please input a valid question'}],
              }
              )(
                <Input placeholder='Enter question' />
              )}
          </FormItem>

          {props.options.map((option, index)=>
            <FormItem label={`Response ${index+1}`} {...formItemLayout} >
                {getFieldDecorator(`option[${index}]`, {
                    rules: [ {required: true, message: 'Please input a valid response'}],
                    initialValue: option
                }
                )(
                  <Input placeholder='Enter response' addonAfter={<Icon type="delete" onClick={() => props.removeAnswer(props.index, index)}/>}/>
                )}
            </FormItem>            
          )}       
          <FormItem {...tailFormItemLayout}>
            <Button onClick={e=>props.addAnswer(props.index)}>
              Add Another Response
            </Button>
            <span style={{marginLeft:20}} >Tip: Add multiple responses to make the quiz more challenging</span>
          </FormItem>  

          <FormItem label="Correct Response" {...formItemLayout}>
              {getFieldDecorator('answer', {
                  rules: [{ required: true, message: 'Please select the correct response' }],
              }
              )(
                <Select placeholder="Select correct response">
                  {props.options.map( answer=> {
                    return <Option key={answer} value={answer}>{answer}</Option>
                  })}
                </Select>
          )}
          </FormItem> 
      </Form>
  );
});

const CourseQuestions = (props) =>{  
  const onEdit = (targetKey, action) => {
    props[action](targetKey);
  }

  return (
    <div>
      <p>The multiple choice quiz you create here is what users will have to complete after interacting with the course content.</p>
      <Tabs          
          onChange={props.activeQuestionChange}
          activeKey={props.activeQuestion}
          type="editable-card"
          onEdit={onEdit}
        >
          {props.questions.map((question, index) => 
                  <TabPane tab={`Question ${index+1}`} key={index}>
                    <QuestionForm 
                      question= {{value: question.question}}
                      answer= {{value: question.answer}}
                      options= {question.options}
                      onChange= {props.onQuestionChange}
                      index= {index}
                      addAnswer= {props.onAddAnswer}
                      removeAnswer= {props.onRemoveAnswer}
                    />
                  </TabPane>
            )}
      </Tabs>
    </div>
  );
};

export default CourseQuestions;