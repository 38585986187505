import React, { Component } from 'react';
import { List, Card, Button } from 'antd';
import ConnectLoading from './ConnectLoading';

const INITIAL_DATA_COUNT = 10;

export class ListWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            dataToRender: props.data.length <= INITIAL_DATA_COUNT ? props.data : props.data.slice(0, INITIAL_DATA_COUNT),
            initLoading: props.loading,
            loading: false,
            hasMore: props.data.length > INITIAL_DATA_COUNT
        }
    }
    componentWillReceiveProps = async(props) =>{
        await this.setState({
            data: props.data,
            dataToRender: props.data.length <= INITIAL_DATA_COUNT ? props.data : props.data.slice(0,INITIAL_DATA_COUNT),                
            hasMore: props.data.length > INITIAL_DATA_COUNT,
            initLoading: props.loading
        });        
    }

    onLoadMore = () => {        
        let dataToRender = this.state.dataToRender;
        let data = this.state.data;

        this.setState({
          loading: true,
        });

        if (data.length === dataToRender.length) {
            this.setState({
              hasMore: false,
              loading: false,
            });
            return;
        }
  
        dataToRender = data.length < dataToRender.length + INITIAL_DATA_COUNT ? data : data.slice(0, dataToRender.length+INITIAL_DATA_COUNT);
          
        this.setState({
            dataToRender,
            loading: false,
        });
        
        window.dispatchEvent(new Event('resize'));
        
    }
    

    render(){       
        if (this.state.initLoading) {
            return (
                    <ConnectLoading />
            );
        }

        const { initLoading, loading, hasMore } = this.state;
        const loadMore = !initLoading && !loading && hasMore ? (
                <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px' }}>
                    <Button onClick={this.onLoadMore}>load more</Button>
                </div>
            ) : null;
      
        return(            
            <Card className='epar__list-widget__card'>
                    <List
                        className='epar__list-widget'
                        itemLayout="horizontal"
                        loading={initLoading}
                        loadMore={loadMore}
                        dataSource={this.state.dataToRender}
                        renderItem={item => {
                            return(
                                <List.Item>
                                    <List.Item.Meta 
                                        avatar={item.avatar?item.avatar:undefined}
                                        title={item.title}
                                        description={item.desc}
                                    /> 
                                    {item.tag?item.tag:undefined}                   
                                    
                                </List.Item>
                            )}
                        }
                    />
            </Card>
        );
            
    }
}