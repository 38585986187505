import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import ChemicalListView from './containers/ChemicalListView';
import ChemicalView from './containers/ChemicalView';
import ChemicalBulkUpload from './containers/ChemicalBulkUpload';


class ChemicalHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['chemicals']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'chemical':
                return <ChemicalView chemicalId={route.match.params.param}/>
            case 'bulk_upload':
                return <ChemicalBulkUpload/>;
            default:
                return <ChemicalListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/chemicals/:page/:param'} render={this.getView} />
                <Route path={'/chemicals/:page'} render={this.getView} />
                <Route path={'/chemicals'} render={this.getView} />
                <Redirect to='/chemicals' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemicalHome));