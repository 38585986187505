import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import EquipmentListView from './containers/EquipmentListView';
import EquipmentView from './containers/EquipmentView';

class EquipmentHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['equipment']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'equipment_info':
                return <EquipmentView equipmentId={route.match.params.param}/>
            default:
                return <EquipmentListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/equipment/:page/:param'} render={this.getView} />
                <Route path={'/equipment'} render={this.getView} />
                <Redirect to='/equipment' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentHome));