
export const INSPECTION_FREQUENCIES = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly (every 3 months)',
    BI_ANNUAL: 'Bi-annual (every 6 months)',
    ANNUAL: 'Annual (every 12 months)',
    CUSTOM_SCHEDULE: 'Custom Schedule',
    ON_DEMAND: 'On Demand'
  }
  
  export const INSPECTION_CATEGORIES = {
    EHS: 'EHS',
    ENVIRONMENT: 'Environment',
    GENERAL: 'General',
    OPERATIONAL: 'Operational',
    SAFETY: 'Safety'
  }
  