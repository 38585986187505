import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, Upload, Icon, Table, Card, message, Select, Row, Col} from 'antd';
import { withRouter } from 'react-router-dom';
import Papa from 'papaparse';

import { checkExistUsers, sendUserUploadRequests } from '../actions/userActions';
import { setBreadcrumb, getOrgList, loadOrgOUList, getAlluserGroups } from '../../../actions/commonActions';

const Option = Select.Option;

const COLUMNS = {
    FIRST_NAME: 0,
    LAST_NAME: 1,
    EMAIL: 2,
    USERNAME: 3,
    IS_OU_ADMIN: 4,
    ORG_UNIT: 5,
    LANGUAGE: 6,
    JOB_ROLE: 7
}

const languages = ['EN', 'ES', 'EN_AU'];
const ouAdminStatus = ['true', 'false'];

class UserBulkUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            isUploadEnable: false,
            uploading: false,
            selectedOrg: null,
            isFieldValidationSuccess: true,
            isCsvUploadBtndisable: true,
            nonExistJobRoles: []
        };
        this.props.setBreadcrumb("Upload Users", [
            { route: '/users', label: 'Users' },
            { route: '', label: 'Upload Users' }
        ]);
    }

    componentDidMount = () => {
        this.props.getOrgList();
    }

    onSubmit = async () => {
        this.setState({ isCsvUploadBtndisable: true });
        await sendUserUploadRequests(this.state.selectedOrg, this.state.users);
        this.setState({ users: [], isUploadEnable: false, isCsvUploadBtndisable: true, selectedOrg: null });
    }

    setOrganisation = async(orgId) => {
        message.info('Wait until the selected organization details are loaded...')
        await this.props.loadOrgOUList(orgId);
        const groupList = await getAlluserGroups(orgId); // get all user groups according to the selected organisation
        this.setState({selectedOrg : orgId, isCsvUploadBtndisable: false, allUserGroupList: groupList})
        message.destroy();
    }


    userView = () => {
        const columns = [
            { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
            { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
            { title: 'Email', dataIndex: 'email', key: 'email' },
            { title: 'Username', dataIndex: 'username', key: 'username' },
            { title: 'Is OU Admin', dataIndex: 'isOUAdmin', key: 'isOUAdmin', render: text => JSON.stringify(text) },
            { title: 'Org Unit', dataIndex: 'organisationUnit', key: 'organisationUnit' },
            { title: 'Language', dataIndex: 'language', key: 'language' },
            { title: 'Job Role', dataIndex: 'groups', key: 'groups', render: roles => <>{roles.map(role=> <div>{role}</div>)}</> }
        ];

        const getDefaultLanguage = async(ouName) => {
            const check = this.props.ouList.find(ou => (ou.ou_name).toLowerCase() === ouName.toLowerCase())
            const language = check.country_code === 'AUS' ? 'EN_AU': 'EN';
            return language;
        }

        const checkDuplicateUsernames = async(array) => {
            const usernames = new Set();
            for (const user of array) {
                if (usernames.has(user[3].toLowerCase())) {
                    return true; // Found a duplicate username
                }
                usernames.add(user[3].toLowerCase());
            }
            return false; // No duplicate usernames found
        }

        const validateFields = async(fieldValues) => {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/; // email validation regex
            const checkOU = this.props.ouList.find(ou => ou.ou_name === fieldValues[COLUMNS.ORG_UNIT])
            // check whether required details are filled
            if(!fieldValues[COLUMNS.FIRST_NAME] || !fieldValues[COLUMNS.LAST_NAME] || !fieldValues[COLUMNS.EMAIL] || !fieldValues[COLUMNS.USERNAME] || !fieldValues[COLUMNS.ORG_UNIT] || !fieldValues[COLUMNS.IS_OU_ADMIN]) {
                message.warning('There are empty reqired feilds please check.');
                return;
            } else if (fieldValues[COLUMNS.ORG_UNIT] && !checkOU) { // check whether given org unit is include in the selected org
                message.destroy();
                message.warning('Please enter valid OUs according to selected organisation.');
                return;
            } else if(fieldValues[COLUMNS.LANGUAGE] && !languages.includes(fieldValues[COLUMNS.LANGUAGE])) { 
                message.destroy();
                message.warning('Please enter valid language');
                return;
            } else if (!(emailRegex.test(fieldValues[COLUMNS.EMAIL]))) { // check whether email is valid
                message.destroy();
                message.warning('Please enter valid email address');
                return;
            } else if (!ouAdminStatus.includes(fieldValues[COLUMNS.IS_OU_ADMIN].toLowerCase())) { // check whether is_ou_admin value is valid
                message.destroy();
                message.warning(`Please enter a valid user authorization level for the 'isOUAdmin' field`);
                return;
            } else if (fieldValues[COLUMNS.JOB_ROLE]) {
                // Check whether entered job role is exist in selected organization
                fieldValues[COLUMNS.JOB_ROLE].split(",").map(role => {
                    const isJobRoleAvailable = this.state.allUserGroupList.length > 0 && this.state.allUserGroupList.some(item => (item.name).toLowerCase() === role.toLowerCase());
                    if(!isJobRoleAvailable) {
                        this.state.nonExistJobRoles.push(role.trim());
                    }
                })
                return true;
            } else {
                return true;
            }  
        }

        const uploadProps = {
            accept: '.csv',
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            fileList: [],
            beforeUpload: (file, fileList) => false,
            onChange: async ({ file, fileList }) => {
                if (fileList.length === 1) {
                    Papa.parse(file, {
                        skipEmptyLines: 'greedy',
                        complete: async(results) => {
                            try {
                                const data = results.data.slice(1);
                                if(data.length > 0) {
                                    const result = await checkExistUsers(data);
                                    const hasDuplicateUsernames = await checkDuplicateUsernames(data);
                                    if(hasDuplicateUsernames) { // check whether there are duplicate usernames in csv
                                        message.warning('Duplicate usernames detected. Please check again.');
                                        return;
                                    }
                                    if(result.existUserNameList.length > 0) {  // check whether usernames already exist
                                        message.warning(`${result.existUserNameList.map(a=>a)} user name(s) already exist `)
                                        return;
                                    }
                                }
                                let users = [];
                                for(let i=0; i< data.length; i++) {
                                    const row = data[i];
                                    const validateStatus = await validateFields(row);
                                    if(!validateStatus) {
                                        this.setState({isFieldValidationSuccess : false})
                                        break;
                                    } else {
                                        this.setState({isFieldValidationSuccess : true})
                                        const defaultLanguage = await getDefaultLanguage(row[COLUMNS.ORG_UNIT]);
                                        let user = {
                                            firstName: row[COLUMNS.FIRST_NAME],
                                            lastName: row[COLUMNS.LAST_NAME],
                                            email: row[COLUMNS.EMAIL],
                                            organisationUnit: row[COLUMNS.ORG_UNIT],
                                            username: row[COLUMNS.USERNAME],
                                            language: row[COLUMNS.LANGUAGE]
                                        };

                                        const nonExistRoles = this.state.nonExistJobRoles;
                                        const groups = row[COLUMNS.JOB_ROLE].split(",").map(function(item) {
                                            if(nonExistRoles.includes(item.trim())){
                                                return null;
                                            } else {
                                                return item.trim();
                                            }
                                        });
                                        if(!user.language) {
                                            user.defaultLanguage = defaultLanguage;
                                        }
                                        user.isOUAdmin = JSON.parse(row[COLUMNS.IS_OU_ADMIN].toLowerCase()); 
                                        user.groups = groups;
                                        users.push(user);
                                    }
                                };
                                if ((this.state.nonExistJobRoles).length > 0) {
                                    message.warning(`${this.state.nonExistJobRoles.map(a=> a)} group(s) doesn't exist in the selected organisation.`)
                                    this.setState({nonExistJobRoles: []})
                                }
                                if(this.state.isFieldValidationSuccess) {
                                    this.setState({ users, isUploadEnable: true });
                                }
                                console.log("All done!");
                            }
                            catch(error) {
                                message.error("Error uploading users. Please check the uploaded file!")
                            }
                        }
                    });

                }
            }
        };
        return (
            <div>
                <div className="pure-u-1">
                        <Row>
                            <Col span={20}> 
                                <Select 
                                    style={{ width: '300px' }}  
                                    showSearch 
                                    optionFilterProp="children"
                                    value={this.state.selectedOrg ? this.state.selectedOrg : undefined }
                                    placeholder="Select Organization" 
                                    onChange={(selectedOrg) => this.setOrganisation(selectedOrg)}
                                >
                                    {this.props.orgList && this.props.orgList.map(org => <Option key={org.org_id} value={org.org_id}>{org.name}</Option>)}
                                </Select> 
                            </Col>
                        </Row>
                        <div className="pure-g" style={{marginTop: 10, marginLeft: -10 }}>
                            <Upload {...uploadProps}>
                                <Button disabled={this.state.isCsvUploadBtndisable} className='epar__tasks__action--button'>
                                    <Icon type="upload" /> Upload CSV
                            </Button>
                            </Upload> 
                            <Button
                                className='epar__tasks__action--button'
                                onClick={this.onSubmit}
                                disabled={this.state.uploading || this.state.users.length === 0 || !this.state.isUploadEnable || !this.state.selectedOrg}
                            > 
                                Send Upload Requests
                            </Button>
                            <Button className='epar__tasks__action--button'>
                                <a href='/assets/users.csv'>Download CSV Template</a>
                            </Button>
                        </div>

                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <Card>
                            <Table columns={columns} dataSource={this.state.users} />
                        </Card>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Card>
                {this.userView()}
            </Card>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['orgState', 'orgList']),
        ouList: state.getIn(['orgState', 'ouList'])
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch),
        loadOrgOUList: bindActionCreators(loadOrgOUList, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserBulkUpload));