import React from 'react';
import { Icon, Card } from 'antd';

export const TrainingVideoCard = (props) =>{
    const {setPlayerModalState, courseDetails } = props;
    return(                 
        <Card style={{ maxWidth: 400, marginBottom: '20px' }} bodyStyle={{ padding: 0 }}>
            <div className="trainvd-custom-image" onClick={()=>setPlayerModalState(true)}>
                <Icon type="play-circle" className='tn-play-icon' style={{color: 'rgb(245, 245, 245)'}} />
                <img width="100%" src="/assets/training-background.png" alt='Course training video'/>
            </div>
            
            <div className="custom-card" style = {{paddingLeft: '10px'}}>
                <h5>Title : {courseDetails.training_video.title}</h5>
                {courseDetails.training_video.description && <h5>Description : {courseDetails.training_video.description}</h5>}
            </div>
        </Card>
    );
};