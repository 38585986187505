import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Button, message } from 'antd';
import { getOrgList } from '../../../actions/commonActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import { getJWTToken } from '../../login/actions/loginActions';
import { getReport } from '../actions/reportActions';
import NewReportModal from './NewReportModal';

var url;
class ReportManagementView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNewReportModelVisible: false,
            dashboardId: null,
            reportDetails:null,
            jwtToken: null,
            hiddenFields:null
        }
        this.authenticate();
        this.props.setBreadcrumb("Reports", [
            { route: '', label: 'Reports' }
        ]);
    }

    postTokenToFrame = async (token) => {
        const frame = document.getElementById('reports_viewer_frame');
        frame.contentWindow.postMessage({event_id : 'token_ready', token : token }, '*');
    }

    authenticate = async () => {
        const token = await getJWTToken();
        this.setState({ jwtToken: token });
        const self = this;
        window.addEventListener('message', function (event) {
            if (event.data.event_id === 'kibana_ready') {
               self.postTokenToFrame(token);
            }
            if (event.data.event_id === 'set_indeces_fields') {
                self.setState({hiddenFields:event.data.data});
             } 
        }); 
    }

    saveDashboard = async () => {
        if (url) {
            var urlParam = new URL(url).hash.substr(1);
            if (urlParam.includes('dashboard') && urlParam.includes('_a')) {
                urlParam = urlParam.substr(urlParam.lastIndexOf('/'));
                const dashboardId = urlParam.substring(1, urlParam.lastIndexOf('?'));
                if (dashboardId) {
                    const report=await getReport(dashboardId);
                    this.setState({ isNewReportModelVisible: true, dashboardId: dashboardId, reportDetails:report })
                }else{
                    message.warn('Save is available only in dashboard edit view');
                }
            }else{
                message.warn('Save is available only in dashboard edit view');
            }
        }else{
            message.warn('Kibana Loading...!!');
        }
    }

    newDashboardModal = () => {
        return (
            this.state.isNewReportModelVisible &&
                <NewReportModal
                visible={this.state.isNewReportModelVisible}
                onCancel={() => this.setState({ isNewReportModelVisible: false })}
                onSave={() => {
                    this.setState({ isNewReportModelVisible: false })
                }}
                dashboardId={this.state.dashboardId}
                reportDetails={this.state.reportDetails}
                hiddenFields={this.state.hiddenFields}
            />
        )
    }

    render() {
        const { jwtToken } = this.state;
        const { reportEndPoint, stage} = this.props;
        if(!reportEndPoint || !jwtToken) return null;

        const reportUrl = `${reportEndPoint}/app/kibana#dashboards/`;
        return (
            <div style={{ float: 'right' }}>
                <iframe title="Kibana" src={reportUrl} id="reports_viewer_frame" style={{ border: 'none', width: '100%', height: '100%', position: 'absolute', display: 'block', top: '0', left: '0' }} allowFullScreen>  </iframe>
                {<Button style={{ marginRight: '70px', marginTop: '9px' }} onClick={this.saveDashboard}>Save Dashboard</Button>}
                {this.newDashboardModal()}
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        reportEndPoint: state.getIn(['appConfigState', 'reportEndPoint']),
        stage: state.getIn(['appConfigState', 'apiStage']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgList: bindActionCreators(getOrgList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportManagementView));

window.addEventListener('message', function (event) {
    if (event.data.event_id === 'current_url') {
        console.log(event.data.url);
        url = event.data.url;
    }
});