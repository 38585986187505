import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import InspectionListView from './containers/InspectionListView';
import InspectionTemplateView from './containers/InspectionTemplateView';

class InspectionHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['inspections']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'template':
                return <InspectionTemplateView templateId={route.match.params.param}/>
            default:
                return <InspectionListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/inspections/:page/:param'} render={this.getView} />
                <Route path={'/inspections'} render={this.getView} />
                <Redirect to='/inspections' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionHome));