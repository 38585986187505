import React, { Component, Fragment } from 'react';
import { Button, Menu, Layout, Dropdown, Row, Col, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from '../utils/common-utils';
import { toggleSidePanel } from '../actions/commonActions';
import ConnectBreadcrumb from './ConnectBreadcrumb';


const { Header } = Layout;

class AppHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      collapsed: true,
    }
  }

  toggle = () => {
    this.props.toggleSidePanel(!this.props.collapsed);
  }

  handleUserSettingsMenuClick = (e) => {
    if (e.key === 'logout') {
      this.props.logout();
    }
    else if (e.key === 'user_settings') {

    }
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  renderUserSettingsMenu = () => {
    const menu = (
      <Menu className='epar__header__user-action__menu' onClick={this.handleUserSettingsMenuClick}>
        <Menu.Item key="logout">Logout</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomRight"
        visible={this.state.visible}>
        <a className="ant-dropdown-link" href="# ">
          <Button size="small" className='epar__action--button epar__header__action-button epar__header__user-settings--button'>
            <Icon type="user" theme="outlined" />
            <span style={{ marginLeft: '5px' }}>{this.props.loginUser}</span>
          </Button>
        </a>
      </Dropdown>
    );
  }

  renderIfNotMobile = () => {
    if (!isMobile()) {
      return (
        <Fragment>
          {this.renderUserSettingsMenu()}
        </Fragment>
      )
    }
  }

  getHeader = () => {
    if (isMobile()) {
      return (
        <Header className='epar__mobile__header epar__header '>
          <Button className='epar__drawer-icon' icon="menu-unfold" onClick={this.toggle} />
          <Row type="flex" justify="center" style={{ width: "calc(100% - 80px)" }}>
            <Col xs={24}>
              <div className="logo epar__logo">
                <a href="# ">
                  <img src='/assets/epar-connect-icon.png' alt="" />
                </a>
                <div className='epar__logo__label'>&nbsp;&nbsp;epar Contractor&nbsp;&nbsp;</div>
              </div>
              {this.props.children}
            </Col>
          </Row>
        </Header>
      )
    }
    else {
      return (
        <Header className='epar__header'>
          <Row type="flex">
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
              <div className='epar__header__tools'>
                {this.renderIfNotMobile()}
              </div>
              <div>
                {this.props.children}
              </div>
            </Col>
          </Row>
        </Header>
      )
    }
  }

  render() {
    return (
      <div>
        {this.getHeader()}
        <ConnectBreadcrumb />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    loginUser: state.getIn(['loginUserState', 'name'])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidePanel: bindActionCreators(toggleSidePanel, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);