import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Tabs, Modal, Select, Upload } from 'antd';
import { withRouter } from 'react-router-dom';

import { getCourse, updateCourseDetails, getCourseContentUrl, updateCourseContent, addNewCourseToOrg, copyCourseContentToOrg, updateDocVersion, getAssignedOrgList } from '../actions/courseActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import ConnectLoading from '../../../components/ConnectLoading';
import DetailsTable from '../../../components/DetailsTable';
import { getOrgList } from '../../../actions/commonActions';
import TrainingVideo from '../trainingVideo/TrainingVideo';
import { CATEGORIES, FREQUENCIES } from '../constants';
import { QuizListItem } from '../CourseListItemBuilder';
import { ListWidget } from '../../../components/ListWidget';
import { QuizEditModal } from './QuizEditModal';
import CourseDetailsForm from './newCourse/CourseDetailsForm';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

class CourseView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            course: undefined,
            editDetailsFormVisible: false,
            orgModalVisible: false,
            selectedOrgs: [],
            versionControlFileUrl: undefined,

            editQuizVisible: false,
            questions: [],
            activeQuestion: '0',

            courseDetails: {
                title: undefined,
                category: undefined,
                frequency: undefined,
            },
        };
    }

    componentDidMount = async() => {
        await this.loadCourse();
        await this.props.getOrgList();
        await this.filterAssignedOrgs();
    }

    filterAssignedOrgs = async() => {
        const assignedOrgList = await getAssignedOrgList(this.props.courseId);
        this.props.orgList.map(org => {
            const isInclude = assignedOrgList.find(item => item.org_id === org.org_id);
            if(isInclude) {
                org.isDisabled = true;
            } else {
                org.isDisabled = false;
            }
        })
    }

    loadCourse = async () => {
        let course = await getCourse(this.props.courseId);
        const versionControlFileUrl = await getCourseContentUrl(this.props.courseId);
        course.courseContentAvailable = versionControlFileUrl.isFileExist ? true : false;
        this.setState({versionControlFileUrl: versionControlFileUrl.url});
        course.parentCourseId = this.props.courseId;
        this.setState({ course, questions: JSON.parse(course.quiz).questions, courseDetails: { title: course.title, category: course.category, frequency: course.frequency } });

        this.props.setBreadcrumb(course.title, [
            { route: '/courses', label: 'Courses' },
            { route: '', label: course.title },
        ]);

    }

    getQuestionsForListView = () => {
        let questionList = JSON.parse(this.state.course.quiz).questions.map((question, index) => {
            let builder = new QuizListItem.Builder(question, index);
            return builder.build();
        });
        return questionList;
    }

    handleEditDetails = () => {
        this.setState({
            editDetailsFormVisible: true
        });
    };

    updateTemplate = async (template) => {
        message.loading('Updating course details...', 0);
        // await editTemplate(template, this.props.userId, this.props.templateId);
        message.destroy();
        message.success('Course details updated successfully');
        this.loadCourse();
    }

    onAddToOrgs = async () => {
        message.loading('Adding course...', 0);
        await Promise.all(this.state.selectedOrgs.map(async org=>
            {
                const orgCourseId = await addNewCourseToOrg(this.state.course, org.key, this.props.userId);
                if(this.state.course.courseContentAvailable) {
                    await copyCourseContentToOrg(this.props.courseId, orgCourseId, org.key);
                    await updateDocVersion(orgCourseId, 1, org.key);
                }
            }));
        this.setState({ orgModalVisible: false });
        await this.filterAssignedOrgs();
        message.destroy();
        message.success('Course added successfully');

    }
    onQuestionAdd = async () => {
        let questions = this.state.questions;
        await questions.push(
            {
                question: undefined,
                options: [''],
                answer: undefined
            });
        await this.setState({ questions: questions, activeQuestion: `${questions.length - 1}` });
    };

    onQuestionRemove = async (index) => {
        let indexInt = parseInt(index, 10);
        let questions = this.state.questions;
        await questions.splice(indexInt, 1);
        this.setState({ questions });
    };

    onQuestionChange = (index, changedFields) => {
        let questions = this.state.questions;
        let question = questions[index];
        if (changedFields.option) {
            let option = changedFields.option;
            question.options[option.length - 1] = option[option.length - 1].value
        } else if (changedFields.question) {
            question.question = changedFields.question.value;
        } else if (changedFields.answer) {
            question.answer = changedFields.answer.value;
        }
        questions[index] = question;
        this.setState({ questions });
    };

    onAddAnswer = (questionIndex) => {
        let questions = this.state.questions;
        let question = questions[questionIndex];
        question.options.push('');
        questions[questionIndex] = question;
        this.setState({ questions });
    }

    onRemoveAnswer = (questionIndex, answerIndex) => {
        let questions = this.state.questions;
        let question = questions[questionIndex];
        question.options.splice(answerIndex, 1);
        questions[questionIndex] = question;
        this.setState({ questions });
    }

    onQuizSave = async () => {
        if (this.state.questions.length === 0) {
            message.warning('Please add question(s).');
            return;
        } else {
            for (let index = 0; index < this.state.questions.length; index++) {
                let question = this.state.questions[index];
                let warning = undefined;
                if (!question.question) {
                    warning = 'Please enter the question';
                } else if (question.options.length === 0) {
                    warning = 'Please add responses';
                } else if (question.options.indexOf("") > -1) {
                    let i = question.options.indexOf("");
                    warning = `Please enter response ${i + 1} or delete the field`;
                } else if (!question.answer) {
                    warning = 'Please select the correct response';
                } else if (question.options.indexOf(question.answer) === -1) {
                    warning = 'Selected correct response is not in the responses list. Please select correct response again';
                }
                if (warning) {
                    message.warning(warning);
                    this.setState({ activeQuestion: `${index}` });
                    return;
                }
            };
        }
        message.loading('Course is updating. Please wait..', 0);
        const course = this.state.course;
        const quiz = {
            questions: this.state.questions
        };
        course.quiz = JSON.stringify(quiz);
        await updateCourseDetails(this.props.courseId, course);
        message.destroy();
        this.setState({ editQuizVisible: false });
        this.loadCourse();
        message.success('Course is successfully updated.', 5);
    }

    onCourseDetailsChange = (changedFields) => {
        this.setState({
            courseDetails: { ...this.state.courseDetails, ...changedFields }
        });
    };

    onCourseDetailsSave = async () => {
        const { title, category, frequency } = this.state.courseDetails;
        if (!title || !category || !frequency) {
            this.courseDetailsForm.validateFields();
            return;
        }
        message.loading('Course is updating. Please wait..', 0);
        const course = this.state.course;
        course.title = title;
        course.category = category;
        course.frequency = frequency;
        await updateCourseDetails(this.props.courseId, course);
        message.destroy();
        this.setState({ editDetailsVisible: false });
        this.loadCourse();
        message.success('Course is successfully updated.', 5);
    }

    onViewContent = async () => {
        message.loading('Downloading. Please wait..', 0);
        const url = this.state.versionControlFileUrl;
        window.open(url);
        message.destroy();
    }

    renderDetails = () => {
        const title = this.state.course.title;
        const category = CATEGORIES[this.state.course.category];
        const frequency = FREQUENCIES[this.state.course.frequency];

        const fields = [
            { key: "Inspection Title", value: title },
            { key: "Category", value: category },
            { key: "Frequency", value: frequency }
        ];
        return (
            <div className="pure-g">
                <Modal
                    visible={this.state.editDetailsVisible}
                    onOk={this.onCourseDetailsSave}
                    onCancel={() => this.setState(
                        {
                            editDetailsVisible: false,
                            courseDetails: { title: this.state.course.title, category: this.state.course.category, frequency: this.state.course.frequency }
                        })}
                >
                    <CourseDetailsForm
                        {...this.state.courseDetails}
                        onChange={this.onCourseDetailsChange}
                        formRef={(form) => this.courseDetailsForm = form}
                    />
                </Modal>
                <div className="pure-u-1">
                    <div className="pure-g" >
                        <div className="pure-u-1">
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.setState({ editDetailsVisible: true })}>Edit Details</Button>
                            <Button
                                className="epar__tasks__action--button"
                                type='primary' onClick={() => this.setState({ orgModalVisible: true })}>Add to organisation(s)</Button>
                        </div>
                    </div>
                    <div>
                        <DetailsTable dataSource={fields} />
                    </div>
                </div>
            </div>
        );
    };

    renderQuizView = () => {
        return (
            <div className="pure-g">
                <QuizEditModal
                    visible={this.state.editQuizVisible}
                    activeQuestion={this.state.activeQuestion}
                    onActiveQuestionChange={activeQuestion => this.setState({ activeQuestion })}
                    onQuestionAdd={this.onQuestionAdd}
                    onQuestionRemove={this.onQuestionRemove}
                    questions={this.state.questions}
                    onQuestionChange={this.onQuestionChange}
                    onAddAnswer={this.onAddAnswer}
                    onRemoveAnswer={this.onRemoveAnswer}

                    handleCancel={e => this.setState({ questions: JSON.parse(this.state.course.quiz).questions, editQuizVisible: false })}
                    handleSave={this.onQuizSave}
                />
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        <div className="pure-u-1">
                            <Button onClick={() => this.setState({ editQuizVisible: true })} icon="edit" style={{ float: 'right' }}>Edit Quiz</Button>
                        </div>
                    </div>
                    <div>
                        <div className="pure-u-1">
                            <ListWidget data={this.getQuestionsForListView()} loading={false} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOrgModal = () => {
        return (
            <Modal
                title="Add to Organisation(s)"
                visible={this.state.orgModalVisible}
                onOk={this.onAddToOrgs}
                onCancel={() => this.setState({ selectedOrgs: [], orgModalVisible: false })}
            >
                <Select mode="multiple" style={{ width: '100%' }} onChange={(value, selectedOrgs) => this.setState({ selectedOrgs })}>
                    {this.props.orgList.map(org => <Option key={org.org_id} value={`${org.name} - ${org.org_code}`} disabled={org.isDisabled}>{org.name}</Option>)}
                </Select>
            </Modal>
        )
    }

    renderContentView = () => {
        const uploadProps = {
            accept: '.pdf',
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            fileList: [],
            beforeUpload: (file, fileList) => false,
            onChange: async ({ file, fileList }) => {
                if (fileList.length === 1) {
                    message.loading('Updating content please wait...', 0);
                    await updateCourseContent(this.props.courseId, file);
                    let courseDetails = this.state.course;
                    if(!courseDetails.courseContentAvailable) {
                        courseDetails.courseVersionAvailable = true;
                        courseDetails.courseContentAvailable = true;
                        await updateCourseDetails(this.props.courseId, courseDetails);
                    }
                    message.destroy();
                    message.success('Content updated successfully');
                }
            }
        };
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        <div className="pure-u-1">
                            {this.state.course.courseContentAvailable && (
                                <Button onClick={this.onViewContent} icon="eye" style={{ marginRight: 15 }}>View</Button>
                            )}
                            <Upload {...uploadProps}>
                                <Button icon="upload">Upload New Version</Button>
                            </Upload>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    render() {
        if (!this.state.course) {
            return <ConnectLoading />;
        }
        return (
            <div>
                {this.state.orgModalVisible && this.renderOrgModal()}
                <Tabs className='epar__details__view'>
                    <TabPane tab="Details" key="1">
                        {this.renderDetails()}
                    </TabPane>
                    <TabPane tab="Version Control" key="2">
                        {this.renderContentView()}
                    </TabPane>
                    <TabPane tab="Course Quiz" key="3">
                        {this.renderQuizView()}
                    </TabPane>
                    <TabPane tab="Training Video" key="4">
                        <TrainingVideo 
                            courseDetails = {this.state.course}
                            courseId = {this.props.courseId}
                            load={this.loadCourse}
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        userId: state.getIn(["loginUserState", "userId"]),
        orgList: state.getIn(['orgState', 'orgList']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseView));
