import { Map } from 'immutable';
import { COURSE_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildUserIndex(equipmentList) {
    return SearchUtils.buildIndex(equipmentList, 'courseid', ['title', 'org_name']);
};

const initialState = Map({
    courseList: undefined,
    courseIndex: buildUserIndex([])
});

export default function courseState(state = initialState, action) {
    switch (action.type) {
        case COURSE_ACTIONS.COURSE_LIST:
            return state.set('courseList', action.payload).set('courseIndex',
            buildUserIndex(action.payload));
        default:
            return state;
    }
}  