import { getApi, ConnectAdminApi, postApi, ConnectApi, putApi } from "../../../utils/api-utils";
import { message } from "antd";
import { COURSE_ACTIONS } from '../actions/actionTypes';
const axios = require('axios').default;

export function loadCourseList() {
  return async function (dispatch, getState) {
    try {
      const result = await getApi(ConnectAdminApi.COURSE_API, '/course_list');
      await dispatch({
        type: COURSE_ACTIONS.COURSE_LIST,
        payload: result.courseList
      });
    } catch (e) {
      console.log(e);
      message.error("Error in getting course list");
    }
  }
}

export async function addNewCourse(courseDetails, file) {
  const result = await postApi(ConnectAdminApi.COURSE_API, '/course', courseDetails);
  if (!result.error) {
    const { courseId, contentUrl } = result;
    if(file) {
      const response = await fetch(contentUrl, {
        method: 'PUT',
        body: file,
        headers: {
          "Content-Type": file.type,
        }
      });
      console.log('response:', response);
    }
    return courseId;
  } else {
    console.error(`Error creating course`, result.error);
    return undefined;
  }
}

export async function updateCourseDetails(courseId, courseDetails) {
  const result = await putApi(ConnectAdminApi.COURSE_API, `/course/${courseId}`, courseDetails);
  if (!result.error) {
    console.log('result:', result);
  } else {
    console.error(`Error creating course`, result.error);
    return undefined;
  }
}

export async function addNewCourseToOrg(courseDetails, orgId, createdBy) {
  courseDetails.createdBy = createdBy;
  const path = `/${orgId}/courses/new_course`;
  const result = await postApi(ConnectApi.COURSE_API, path, courseDetails);
  if (!result.error) {
    const orgCourseId = result.data;
    return orgCourseId;
  } else {
    console.error(`Error creating course`, result.error);
    return undefined;
  }
}

export async function copyCourseContentToOrg(courseId, orgCourseId, orgId) {
  const result = await postApi(ConnectAdminApi.COURSE_API, '/org_course_content', { courseId, orgId, orgCourseId });
  if (!result.error) {    
    console.log('result:', result);
    return courseId;
  } else {
    console.error(`Error creating course`, result.error);
    return undefined;
  }
}

export async function updateDocVersion(orgCourseId, version, orgId) {
  const path = `/${orgId}/courses/update_doc_version`;
  const result = await putApi(ConnectApi.COURSE_API, path, { courseId: orgCourseId, version: version });
  if (!result.error) {
    const courseId = result.data;
    return courseId;
  } else {
    console.error(`Error updating doc version`, result.error);
    return undefined;
  }
}

export async function getCourse(courseId) {
  try {
    const url = `/course`;
    const result = await getApi(ConnectAdminApi.COURSE_API, url, { queryStringParameters: { courseId } });
    const response = await fetch(result.url);
    const json = await response.json();
    return json;
  } catch (e) {
    console.log(e);
    message.error("Error in getting course");
  }
}

export async function getCourseContentUrl(courseId) {
  try {
    const url = `/course_content`;
    const result = await getApi(ConnectAdminApi.COURSE_API, url, { queryStringParameters: { courseId } });
    return result;
  } catch (e) {
    console.log(e);
    message.error("Error in getting course content");
  }
}

export async function updateCourseContent(courseId, file) {
  try {
    const url = `/course_content`;
    const result = await getApi(ConnectAdminApi.COURSE_API, url, { queryStringParameters: { courseId, isPutUrl: true } });
    const response = await fetch(result.url, {
      method: 'PUT',
      body: file,
      headers: {
        "Content-Type": file.type,
      }
    });
    console.log('response:', response);
  } catch (e) {
    console.log(e);
    message.error("Error in updating course content");
  }
}

export async function archiveCourse(courseId) {
  try {
    const url = `/archive_course/${courseId}`;
    await putApi(ConnectAdminApi.COURSE_API, url);
  } catch (e) {
    console.log(e);
    message.error("Error in archiving course");
  }
}

export async function getAssignedOrgList(courseId) {
  try {
    const url = `/course_assigned_org_list/${courseId}`;
    const result = await getApi(ConnectAdminApi.COURSE_API, url);
    return result;
  } catch(e) {
    console.log(e);
  }
}

export const getSignedUrl = async (key, contentType, method, expires, versionId) => {
  try {
    const params = {
      key: key,
      contentType: contentType,
      method: method,
      expires: expires,
      versionId: versionId
  }
    const result = await getApi(ConnectAdminApi.COURSE_API, '/training-video/signedurl', { queryStringParameters: params });
    return result.data;
  }
  catch(error) {
    console.error(error);
    message.error("Error in getting training video")
  }
}

export async function uploadFile(url, file, contentType, uploadProgress, uploadCancelToken) {
  return await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': contentType
      },
      onUploadProgress: uploadProgress,
      cancelToken: uploadCancelToken
    }
  ).catch(function (error) {
    message.error("Error Uploading File");
  });
}