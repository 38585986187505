import { ListItem } from '../../components/ListItemBuilder';

export class TemplateListItem {
    static get Builder() {
        class Builder {
            constructor(template) {
                this.template = template;
            }
            build() {
                const template = this.template;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(template.title, `/job_planner/template/${template.task_template_id}`)
                return builder.build();
            }
        }
        return Builder;
    }
}

export class OrgListItem {
    static get Builder() {
        class Builder {
            constructor(org) {
                this.org = org;
            }
            build() {
                const org = this.org;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitle(org.name)
                return builder.build();
            }
        }
        return Builder;
    }
}
