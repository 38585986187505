//@flow
import { Map } from 'immutable';
import { ORG_ACTIONS } from './../actions/actionTypes';
import * as SearchUtils from '../utils/search-utils';


function buildOrgIndex(orgs) {
  return SearchUtils.buildIndex(orgs, "org_id", ["name","country_code", "org_code"]);
};

const initialState = Map({
  orgList: undefined,
  orgIndex: buildOrgIndex([]),
});

export default function orgState(state = initialState, action) {
  switch (action.type) {
    case ORG_ACTIONS.ORG_LIST:
      return state.set("orgList", action.payload).set('orgIndex', buildOrgIndex(action.payload));
    case ORG_ACTIONS.OU_LIST:
      return state.set("ouList", action.payload);
    default:
      return state;
  }
}